<template>
    <div class="login-bg" :style="'height:'+ bgHeight+'px'">
        <el-container>
            <el-header style="padding: 0" height="100%">
                <vheader class="hidden-sm-and-down"></vheader>
                <ivheadera class="hidden-md-and-up"></ivheadera>
            </el-header>
            <el-main style="padding: 0">
              <div class="bg-txt hidden-sm-and-down">
                <div class="en-txt">
                  HELLO! SHENZHEN
                </div>
                <div class="cn-txt">
                  从这里,爱上深圳
                </div>
              </div>
              <div class="login-box hidden-sm-and-down">
                <div class="card">
                  <div class="header">
                    <div class="txt">
                      <div class="en">LOGIN</div>
                      <div v-show="curSel == 0" class="cn">账号登录</div>
                      <div v-show="curSel == 1" class="cn">手机登录</div>
                      <div v-show="curSel == 2" class="cn">微信登录</div>
                    </div>
                    <div class="switch-btn">
                      <ul>
                        <li v-show="curSel != 0" @click="selBtn(0)">
                          <i class="el-icon-lock"></i>
                          <span>密码登录</span>
                        </li>
                        <li v-show="curSel != 1" @click="selBtn(1)">
                          <i class="el-icon-mobile-phone"></i>
                          <span>手机登录</span>
                        </li>
<!--                        <li v-show="curSel != 2" @click="selBtn(2)">-->
<!--                          <img src="../../assets/images/Login/wechat.png" alt="">-->
<!--                          <span>微信登录</span>-->
<!--                        </li>-->
                      </ul>
                    </div>
                  </div>
                  <div class="content">
                    <div v-show="curSel==0" class="account">
                      <el-form :model="loginForm" ref="accountForm" :rules="accountRules" label-width="70px" label-position="left">
                        <el-form-item label="账号" prop="account">
                          <el-input v-model="loginForm.account" placeholder="邮箱/手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="密码"  prop="password">
                          <el-input type="password" v-model="loginForm.password" @keyup.enter.native="enterKey"></el-input>
                        </el-form-item>
                        <p class="forget"><router-link to='/Forget'>忘记密码？</router-link></p>
                        <el-form-item class="account-btn">
                          <el-button class="register-vip" @click="registerVip">注册会员</el-button>
                          <el-button class="sign-in" @click="accountSubmit(1)">马上登录</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div v-show="curSel==1" class="phone">
                      <el-form :model="phoneForm" ref="phoneForm" :rules="phoneRules" label-position="left"  label-width="100px">
                        <el-form-item label="手机号码" prop="phoneNum" class="getPhone">
<!--                          <span>+</span>-->
                          <el-input class="area-num" v-model.number="phoneForm.area">
                            <template slot="prepend">+</template>
                          </el-input>
                          <el-input class="phone-num"  v-model="phoneForm.phoneNum" type="number"></el-input>
                        </el-form-item>
                        <el-form-item class="getCode" label="验证码" prop="code">
                          <el-input type="number" v-model="phoneForm.code" oninput="if(value.length>6)value=value.slice(0,6)" @keyup.enter.native="enterKey2"></el-input>
                          <el-button @click="getCode" :disabled="phoneForm.disabled">{{phoneForm.show}}</el-button>
                        </el-form-item>
                        <el-form-item class="photoCode" label="安全验证" :rules="[{required: true}]">
                          <drag-verify
                            :width="330"
                            :height="44"
                            handlerIcon='el-icon-d-arrow-right'
                            successIcon='el-icon-check'
                            text="请将滑块拖动到右侧"
                            success-text="验证成功"
                            background="#ddd"
                            progress-bar-bg="#2ACBF8"
                            text-size="0.3rem"
                            :circle="false"
                            ref="verify"
                            @passcallback="passcall">
                          </drag-verify>
                        </el-form-item>
                        <el-form-item class="phone-btn">
                          <el-button class="sign-in" @click="phoneSubmit(1)">马上登录</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                    <div v-show="curSel==2" class="wx-code">
                      <el-col :span="12">
                        <img width="100%" src="../../assets/images/Login/code.png" alt="">
                      </el-col>
                      <el-col :span="12">
                        <div class="txt">
                          <p>请使用微信扫描二维码登录</p>
                          <span>cityPlus城市+</span>
                        </div>
                      </el-col>
                    </div>
                  </div>
                </div>
                <p class="copy-txt">
                  粤ICP备17121829号 - 1
                </p>
              </div>
              <div class="m-loginBox hidden-md-and-up">
                <div class="m-header">
                  <img width="100%" src="../../assets/images/G/m-header-bg.jpg" alt="">
                  <div class="txt">
                    <p class="en">HELLO! SHENZHEN</p>
                    <p class="cn">从这里,爱上深圳</p>
                  </div>
                </div>
                <div class="login-content">
                  <div class="title">
                    <img width="36%" src="../../assets/images/Login/m-title.png" alt="">
                  </div>
                  <div class="form-box1" v-show="formShow===1">
                    <el-form :model="loginForm" ref="accountForm1" :rules="accountRules" >
                      <el-form-item label="账号" prop="account">
                        <el-input v-model="loginForm.account" ></el-input>
                      </el-form-item>
                      <el-form-item label="密码"  prop="password">
                        <el-input type="password" v-model="loginForm.password"></el-input>
                      </el-form-item>
                      <p class="forget"><router-link to='/Forget'>忘记密码？</router-link></p>
                      <el-form-item class="account-btn">
                        <el-button class="sign-in" @click="accountSubmit(2)">马上登录</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="form-box2" v-show="formShow===2">
                    <el-form :model="phoneForm" ref="phoneForm1" :rules="phoneRules">
                      <el-form-item label="手机号码" prop="phoneNum" class="getPhone">
                        <el-input class="area-num" v-model.number="phoneForm.area">
                          <template slot="prepend">+</template>
                        </el-input>
                        <el-input class="phone-num"  v-model="phoneForm.phoneNum" type="number"></el-input>
                      </el-form-item>
                      <el-form-item class="getCode" label="验证码" prop="code">
                        <el-input type="number" v-model="phoneForm.code" oninput="if(value.length>6)value=value.slice(0,6)"></el-input>
                        <el-button @click="getCode" :disabled="phoneForm.disabled">{{phoneForm.show}}</el-button>
                      </el-form-item>
                      <el-form-item class="photoCode" label="安全验证" :rules="[{required: true}]">
                        <drag-verify
                          :width="mWidth"
                          :height="44"
                          handlerIcon='el-icon-d-arrow-right'
                          successIcon='el-icon-check'
                          text="请将滑块拖动到右侧"
                          success-text="验证成功"
                          background="#ddd"
                          progress-bar-bg="#2ACBF8"
                          text-size="0.3rem"
                          :circle="false"
                          ref="verify1"
                          @passcallback="passcall">
                        </drag-verify>
                      </el-form-item>
                      <el-form-item class="phone-btn">
                        <el-button class="sign-in" @click="phoneSubmit(2)">马上登录</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="btn">
                    <div class="button" @click="registerVip">注册会员</div>
                    <div class="button" v-show="formShow === 1" @click="formShow = 2"><i class="el-icon-mobile"></i>手机登录</div>
                    <div class="button" v-show="formShow === 2" @click="formShow = 1"><i class="el-icon-lock"></i>账号登录</div>
                  </div>
                </div>
              </div>
            </el-main>
<!--            <el-footer>-->
<!--                <vfooter class="hidden-sm-and-down"></vfooter>-->
<!--                <ivfooter class="hidden-md-and-up"></ivfooter>-->
<!--            </el-footer>-->
        </el-container>
    </div>
</template>
<script>
import dragVerify from 'vue-drag-verify'
import { NaccountLogin, phoneLogin, phoneCode, savePhone, sendNew, getUserinfo } from '../../api'
import qs from 'qs'

export default {
  data () {
    return {
      mWidth: 300,
      bgHeight: '',
      curSel: 0,
      codeWidth: '',
      formShow: 1,
      loginForm: {
        account: '',
        password: ''
      },
      phoneForm: {
        area: '86',
        phoneNum: '',
        code: '',
        show: '获取验证码',
        getCode: '',
        disabled: false,
        timer: null,
        count: '',
        test: false,
        sjPassword: ''
      },
      accountRules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 24, message: '密码长度小于6位', trigger: 'blur' }
        ]
      },
      phoneRules: {
        phoneNum: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      startX: '',
      startY: ''
    }
  },
  methods: {
    enterKey () {
      console.log('enter')
      this.accountSubmit(1)
    },
    enterKey2 () {
      this.phoneSubmit(1)
    },
    selBtn (e) {
      this.curSel = e
    },
    passcall () {
      this.phoneForm.test = true
      console.log(this.phoneForm.test)
      console.log(this.$refs.verify)
    },
    registerVip () {
      this.$router.push('/Register')
    },
    accountSubmit (e) {
      if (e === 1) {
        this.$refs.accountForm.validate((valid) => {
          if (valid) {
            if (this.loginForm.account.indexOf('@') === -1) {
              console.log('账号')
              let data = {
                PHONE: this.loginForm.account
              }
              NaccountLogin(qs.stringify(data)).then(res => {
                console.log(res)
                if (res.status === 200) {
                  if (res.data.length === 0) {
                    this.$message({
                      showClose: true,
                      message: '输入的账号或密码不正确',
                      type: 'error',
                      center: true
                    })
                  } else {
                    if (this.contrastMd5(this.loginForm.password, res.data[0].PASSWORD)) {
                      this.$confirm('登录成功，点击进入个人主页', '提示', {
                        confirmButtonText: '确定',
                        type: 'success',
                        center: true,
                        showCancelButton: false,
                        showClose: false,
                        closeOnPressEscape: false
                      }).then(() => {
                        getUserinfo(this.loginForm.account).then(res => {
                          console.log(res.data)
                          let userInfo = {
                            'userId': res.data[0].SPONSOR_ID,
                            'userName': res.data[0].USERNAME
                          }
                          userInfo = JSON.stringify(userInfo)
                          localStorage.setItem('userInfo', userInfo)
                          this.$router.push('/user')
                        })
                      })
                    } else {
                      this.$message({
                        showClose: true,
                        message: '输入的账号或密码不正确',
                        type: 'error',
                        center: true
                      })
                    }
                  }
                } else {
                  this.$message({
                    showClose: true,
                    message: '请求失败，请检查网络或联系管理员',
                    type: 'error',
                    center: true
                  })
                }
              }).catch(error => {
                console.log(error)
                this.$message({
                  showClose: true,
                  message: '请求失败，请检查网络或联系管理员',
                  type: 'error',
                  center: true
                })
              })
            } else {
              console.log('@')
              let data = {
                EMAIL: this.loginForm.account
              }
              NaccountLogin(qs.stringify(data)).then(res => {
                if (res.status === 200) {
                  if (res.data.length === 0) {
                    this.$message({
                      showClose: true,
                      message: '输入的账号或密码不正确',
                      type: 'error',
                      center: true
                    })
                  } else {
                    if (this.contrastMd5(this.loginForm.password, res.data[0].PASSWORD)) {
                      this.$confirm('登录成功，点击进入个人主页', '提示', {
                        confirmButtonText: '确定',
                        type: 'success',
                        center: true,
                        showCancelButton: false,
                        showClose: false,
                        closeOnPressEscape: false
                      }).then(() => {
                        getUserinfo(this.loginForm.account).then(res => {
                          console.log(res.data)
                          let userInfo = {
                            'userId': res.data[0].SPONSOR_ID,
                            'userName': res.data[0].USERNAME
                          }
                          userInfo = JSON.stringify(userInfo)
                          localStorage.setItem('userInfo', userInfo)
                          // console.log(localStorage.getItem('userInfo'))
                          // this.$router.push('/user')
                          // this.$store.commit('saveId', res.data[0].SPONSOR_ID)
                          // this.$store.commit('saveName', res.data[0].USERNAME)
                          this.$router.push('/user')
                        })
                      })
                    } else {
                      this.$message({
                        showClose: true,
                        message: '输入的账号或密码不正确',
                        type: 'error',
                        center: true
                      })
                    }
                  }
                } else {
                  this.$message({
                    showClose: true,
                    message: '请求失败，请检查网络或联系管理员',
                    type: 'error',
                    center: true
                  })
                }
              }).catch(error => {
                console.log(error)
                this.$message({
                  showClose: true,
                  message: '请求失败，请检查网络或联系管理员',
                  type: 'error',
                  center: true
                })
              })
            }
            // accountLogin(this.loginForm.account).then(res => {
            //   console.log(res.data.length === 0)
            //   if (res.data.length === 0) {
            //     this.$message({
            //       showClose: true,
            //       message: '输入的账号或密码不正确',
            //       type: 'error',
            //       center: true
            //     })
            //   } else {
            //     if (this.contrastMd5(this.loginForm.password, res.data[0].PASSWORD)) {
            //       this.$alert('', '登录成功', {
            //         confirmButtonText: '确定',
            //         type: 'success ',
            //         center: true
            //       })
            //       getUserinfo(this.loginForm.account).then(res => {
            //         console.log(res.data)
            //         let userInfo = {
            //           'userId': res.data[0].SPONSOR_ID,
            //           'userName': res.data[0].USERNAME
            //         }
            //         userInfo = JSON.stringify(userInfo)
            //         localStorage.setItem('userInfo', userInfo)
            //         console.log(localStorage.getItem('userInfo'))
            //         this.$router.push('/user')
            //         // this.$store.commit('saveId', res.data[0].SPONSOR_ID)
            //         // this.$store.commit('saveName', res.data[0].USERNAME)
            //       })
            //     } else {
            //       this.$message({
            //         showClose: true,
            //         message: '输入的账号或密码不正确',
            //         type: 'error',
            //         center: true
            //       })
            //     }
            //   }
            // })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.$refs.accountForm1.validate((valid) => {
          if (valid) {
            if (this.loginForm.account.indexOf('@') === -1) {
              console.log('账号')
              let data = {
                PHONE: this.loginForm.account
              }
              NaccountLogin(qs.stringify(data)).then(res => {
                console.log(res)
                if (res.status === 200) {
                  if (res.data.length === 0) {
                    this.$message({
                      showClose: true,
                      message: '输入的账号或密码不正确',
                      type: 'error',
                      center: true
                    })
                  } else {
                    if (this.contrastMd5(this.loginForm.password, res.data[0].PASSWORD)) {
                      this.$confirm('登录成功，点击进入个人主页', '提示', {
                        confirmButtonText: '确定',
                        type: 'success',
                        center: true,
                        showCancelButton: false,
                        showClose: false,
                        closeOnPressEscape: false
                      }).then(() => {
                        getUserinfo(this.loginForm.account).then(res => {
                          console.log(res.data)
                          let userInfo = {
                            'userId': res.data[0].SPONSOR_ID,
                            'userName': res.data[0].USERNAME
                          }
                          userInfo = JSON.stringify(userInfo)
                          localStorage.setItem('userInfo', userInfo)
                          this.$router.push('/user')
                        })
                      })
                    } else {
                      this.$message({
                        showClose: true,
                        message: '输入的账号或密码不正确',
                        type: 'error',
                        center: true
                      })
                    }
                  }
                } else {
                  this.$message({
                    showClose: true,
                    message: '请求失败，请检查网络或联系管理员',
                    type: 'error',
                    center: true
                  })
                }
              }).catch(error => {
                console.log(error)
                this.$message({
                  showClose: true,
                  message: '请求失败，请检查网络或联系管理员',
                  type: 'error',
                  center: true
                })
              })
            } else {
              console.log('@')
              let data = {
                EMAIL: this.loginForm.account
              }
              NaccountLogin(qs.stringify(data)).then(res => {
                if (res.status === 200) {
                  if (res.data.length === 0) {
                    this.$message({
                      showClose: true,
                      message: '输入的账号或密码不正确',
                      type: 'error',
                      center: true
                    })
                  } else {
                    if (this.contrastMd5(this.loginForm.password, res.data[0].PASSWORD)) {
                      this.$confirm('登录成功，点击进入个人主页', '提示', {
                        confirmButtonText: '确定',
                        type: 'success',
                        center: true,
                        showCancelButton: false,
                        showClose: false,
                        closeOnPressEscape: false
                      }).then(() => {
                        getUserinfo(this.loginForm.account).then(res => {
                          console.log(res.data)
                          let userInfo = {
                            'userId': res.data[0].SPONSOR_ID,
                            'userName': res.data[0].USERNAME
                          }
                          userInfo = JSON.stringify(userInfo)
                          localStorage.setItem('userInfo', userInfo)
                          // console.log(localStorage.getItem('userInfo'))
                          // this.$router.push('/user')
                          // this.$store.commit('saveId', res.data[0].SPONSOR_ID)
                          // this.$store.commit('saveName', res.data[0].USERNAME)
                          this.$router.push('/user')
                        })
                      })
                    } else {
                      this.$message({
                        showClose: true,
                        message: '输入的账号或密码不正确',
                        type: 'error',
                        center: true
                      })
                    }
                  }
                } else {
                  this.$message({
                    showClose: true,
                    message: '请求失败，请检查网络或联系管理员',
                    type: 'error',
                    center: true
                  })
                }
              }).catch(error => {
                console.log(error)
                this.$message({
                  showClose: true,
                  message: '请求失败，请检查网络或联系管理员',
                  type: 'error',
                  center: true
                })
              })
            }
            // accountLogin(this.loginForm.account).then(res => {
            //   console.log(res.data.length === 0)
            //   if (res.data.length === 0) {
            //     this.$message({
            //       showClose: true,
            //       message: '输入的账号或密码不正确',
            //       type: 'error',
            //       center: true
            //     })
            //   } else {
            //     if (this.contrastMd5(this.loginForm.password, res.data[0].PASSWORD)) {
            //       this.$alert('', '登录成功', {
            //         confirmButtonText: '确定',
            //         type: 'success ',
            //         center: true
            //       })
            //       getUserinfo(this.loginForm.account).then(res => {
            //         console.log(res.data)
            //         let userInfo = {
            //           'userId': res.data[0].SPONSOR_ID,
            //           'userName': res.data[0].USERNAME
            //         }
            //         userInfo = JSON.stringify(userInfo)
            //         localStorage.setItem('userInfo', userInfo)
            //         console.log(localStorage.getItem('userInfo'))
            //         this.$router.push('/user')
            //         // this.$store.commit('saveId', res.data[0].SPONSOR_ID)
            //         // this.$store.commit('saveName', res.data[0].USERNAME)
            //       })
            //     } else {
            //       this.$message({
            //         showClose: true,
            //         message: '输入的账号或密码不正确',
            //         type: 'error',
            //         center: true
            //       })
            //     }
            //   }
            // })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    // 随机生成6位数字字母组合密码
    randomCoding () {
      // 创建26个字母数组
      // var arr = ['a', 'b', 'b', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'e', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
      // var letterValue = ''
      var numValue = ''
      var n = 6 // 这个值可以改变的，对应的生成多少个字母，根据自己需求所改
      for (var i = 0; i < n; i++) {
        // letterValue += arr[Math.floor(Math.random() * 26)]
        numValue += Math.floor(Math.random() * 10)
      }
      return numValue
    },
    test () {
      console.log(this.randomCoding())
    },
    contrastMd5 (num, code) {
      let newNum = this.$md5(num)
      if (String(newNum) === code) {
        return true
      } else {
        return false
      }
    },
    getCode () {
      this.phoneForm.show = '获取验证码'
      const TIME_COUNT = 60
      if (this.phoneForm.phoneNum === '') {
        this.$message({
          showClose: true,
          message: '请先输入手机号码',
          type: 'error',
          center: true
        })
      } else {
        if (!this.phoneForm.timer) {
          this.phoneForm.count = TIME_COUNT
          this.phoneForm.show = '获取验证码'
          this.phoneForm.timer = setInterval(() => {
            if (this.phoneForm.count > 0 && this.phoneForm.count <= TIME_COUNT) {
              this.phoneForm.count--
              this.phoneForm.show = this.phoneForm.count + 's'
              this.phoneForm.disabled = true
            } else {
              this.phoneForm.show = '获取验证码'
              clearInterval(this.phoneForm.timer)
              this.phoneForm.timer = null
              this.phoneForm.disabled = false
            }
          }, 1000)
        }
        if (this.phoneForm.area === '86') {
          console.log(86)
          phoneCode(this.phoneForm.phoneNum).then(res => {
            console.log(res.data)
            if (res.data === false) {
              this.$message({
                showClose: true,
                message: '手机格式错误或今日发送次数上限',
                type: 'error',
                center: true
              })
              this.phoneForm.show = '获取验证码'
              clearInterval(this.phoneForm.timer)
              this.phoneForm.timer = null
              this.phoneForm.disabled = false
            } else {
              this.$message({
                showClose: true,
                message: '短信验证码已发送',
                type: 'success',
                center: true
              })
              this.phoneForm.getCode = res.data
            }
          }).catch(error => {
            console.log(error)
            this.$message({
              showClose: true,
              message: '请求失败，请检查网络或联系管理员',
              type: 'error',
              center: true
            })
          })
        } else {
          console.log('非86')
          let newNum = this.phoneForm.area + this.phoneForm.phoneNum
          phoneCode(newNum).then(res => {
            console.log(res.data)
            if (res.data === false) {
              this.$message({
                showClose: true,
                message: '手机格式错误或今日发送次数上限',
                type: 'error',
                center: true
              })
              this.phoneForm.show = '获取验证码'
              clearInterval(this.phoneForm.timer)
              this.phoneForm.timer = null
              this.phoneForm.disabled = false
            } else {
              this.$message({
                showClose: true,
                message: '短信验证码已发送',
                type: 'success',
                center: true
              })
              this.phoneForm.getCode = res.data
            }
          }).catch(error => {
            console.log(error)
            this.$message({
              showClose: true,
              message: '请求失败，请检查网络或联系管理员',
              type: 'error',
              center: true
            })
          })
        }
      }
    },
    resetVerify () {
      console.log('失败')
      // 恢复滑动到原点
      $('.login-box .photoCode .dv .dv_handler i').attr('class', 'el-icon-d-arrow-right')
      $('.login-box .photoCode .dv .dv_progress_bar').css('background', 'rgb(42, 203, 248)')
      $('.login-box .photoCode .dv .dv_handler').removeClass('dv_handler_ok_bg')
      $('.m-loginBox .photoCode .dv_handler i').attr('class', 'el-icon-d-arrow-right')
      $('.m-loginBox .photoCode .dv_progress_bar').css('background', 'rgb(42, 203, 248)')
      $('.m-loginBox .photoCode .dv_handler').removeClass('dv_handler_ok_bg')
      console.log(this.$refs.verify)
      this.$refs.verify.isMoving = false
      this.$refs.verify.x = 0
      this.$refs.verify.isPassing = false
      this.$refs.verify.$refs.handler.style.left = '0'
      this.$refs.verify.$refs.progressBar.style.width = '0'
      this.$refs.verify1.isMoving = false
      this.$refs.verify1.x = 0
      this.$refs.verify1.isPassing = false
      this.$refs.verify1.$refs.handler.style.left = '0'
      this.$refs.verify1.$refs.progressBar.style.width = '0'
      this.phoneForm.test = false
    },
    phoneSubmit (e) {
      // let data = {
      //   USERNAME: this.phoneForm.phoneNum,
      //   PHONE: this.phoneForm.phoneNum,
      //   PASSWORD: this.randomCoding()
      // }
      // console.log(qs.stringify(data))
      if (e == 1) {
        this.$refs.phoneForm.validate((valid) => {
          if (valid) {
            if (this.phoneForm.test) {
              if (this.contrastMd5(this.phoneForm.code, this.phoneForm.getCode)) {
                // this.$alert('', '登录成功', {
                //   confirmButtonText: '确定',
                //   type: 'success ',
                //   center: true
                // })
                this.$confirm('登录成功，点击进入个人主页', '提示', {
                  confirmButtonText: '确定',
                  type: 'success',
                  center: true,
                  showCancelButton: false,
                  showClose: false,
                  closeOnPressEscape: false
                }).then(() => {
                  // 保存新用户信息进数据库并获取用户id等信息存入本地
                  if (this.phoneForm.area === '86') {
                    getUserinfo(this.phoneForm.phoneNum).then(res => {
                      console.log(res.data)
                      let userInfo = {
                        'userId': res.data[0].SPONSOR_ID,
                        'userName': res.data[0].USERNAME
                      }
                      userInfo = JSON.stringify(userInfo)
                      localStorage.setItem('userInfo', userInfo)
                      console.log(localStorage.getItem('userInfo'))
                      this.$router.push('/user')
                    })
                    phoneLogin(this.phoneForm.phoneNum).then(res => {
                      console.log(res.data.new['count(phone)'])
                      // eslint-disable-next-line eqeqeq
                      if (res.data.new['count(phone)'] == 0) {
                        this.phoneForm.sjPassword = this.randomCoding()
                        let data = {
                          USERNAME: this.phoneForm.phoneNum,
                          PHONE: this.phoneForm.phoneNum,
                          PASSWORD: this.$md5(this.phoneForm.sjPassword)
                        }
                        savePhone(qs.stringify(data)).then(res => {
                          console.log('保存成功')
                          sendNew(this.phoneForm.phoneNum, this.phoneForm.sjPassword).then(res => {
                          })
                        })
                      }
                    })
                  } else {
                    let newNum = this.phoneForm.area + this.phoneForm.phoneNum
                    // 保存新用户信息进数据库并获取用户id等信息存入本地
                    getUserinfo(newNum).then(res => {
                      console.log(res.data)
                      let userInfo = {
                        'userId': res.data[0].SPONSOR_ID,
                        'userName': res.data[0].USERNAME
                      }
                      userInfo = JSON.stringify(userInfo)
                      localStorage.setItem('userInfo', userInfo)
                      console.log(localStorage.getItem('userInfo'))
                      this.$router.push('/user')
                    })
                    phoneLogin(newNum).then(res => {
                      // eslint-disable-next-line eqeqeq
                      if (res.data.new['count(phone)'] == 0) {
                        let data = {
                          USERNAME: newNum,
                          PHONE: newNum,
                          PASSWORD: this.$md5(this.randomCoding())
                        }
                        savePhone(qs.stringify(data)).then(res => {
                          console.log('保存成功')
                          sendNew(this.phoneForm.phoneNum).then(res => {
                          })
                        })
                      }
                    })
                  }
                })
              } else {
                this.$message({
                  showClose: true,
                  message: '验证码错误',
                  type: 'error',
                  center: true
                })
                this.resetVerify()
              }
            } else {
              this.$message({
                showClose: true,
                message: '请先进行安全验证',
                type: 'error',
                center: true
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.$refs.phoneForm1.validate((valid) => {
          if (valid) {
            if (this.phoneForm.test) {
              if (this.contrastMd5(this.phoneForm.code, this.phoneForm.getCode)) {
                // this.$alert('', '登录成功', {
                //   confirmButtonText: '确定',
                //   type: 'success ',
                //   center: true
                // })
                this.$confirm('登录成功，点击进入个人主页', '提示', {
                  confirmButtonText: '确定',
                  type: 'success',
                  center: true,
                  showCancelButton: false,
                  showClose: false,
                  closeOnPressEscape: false
                }).then(() => {
                  // 保存新用户信息进数据库并获取用户id等信息存入本地
                  if (this.phoneForm.area === '86') {
                    getUserinfo(this.phoneForm.phoneNum).then(res => {
                      console.log(res.data)
                      let userInfo = {
                        'userId': res.data[0].SPONSOR_ID,
                        'userName': res.data[0].USERNAME
                      }
                      userInfo = JSON.stringify(userInfo)
                      localStorage.setItem('userInfo', userInfo)
                      console.log(localStorage.getItem('userInfo'))
                      this.$router.push('/user')
                    })
                    phoneLogin(this.phoneForm.phoneNum).then(res => {
                      console.log(res.data.new['count(phone)'])
                      // eslint-disable-next-line eqeqeq
                      if (res.data.new['count(phone)'] == 0) {
                        this.phoneForm.sjPassword = this.randomCoding()
                        let data = {
                          USERNAME: this.phoneForm.phoneNum,
                          PHONE: this.phoneForm.phoneNum,
                          PASSWORD: this.$md5(this.phoneForm.sjPassword)
                        }
                        savePhone(qs.stringify(data)).then(res => {
                          console.log('保存成功')
                          sendNew(this.phoneForm.phoneNum, this.phoneForm.sjPassword).then(res => {
                          })
                        })
                      }
                    })
                  } else {
                    let newNum = this.phoneForm.area + this.phoneForm.phoneNum
                    // 保存新用户信息进数据库并获取用户id等信息存入本地
                    getUserinfo(newNum).then(res => {
                      console.log(res.data)
                      let userInfo = {
                        'userId': res.data[0].SPONSOR_ID,
                        'userName': res.data[0].USERNAME
                      }
                      userInfo = JSON.stringify(userInfo)
                      localStorage.setItem('userInfo', userInfo)
                      console.log(localStorage.getItem('userInfo'))
                      this.$router.push('/user')
                    })
                    phoneLogin(newNum).then(res => {
                      // eslint-disable-next-line eqeqeq
                      if (res.data.new['count(phone)'] == 0) {
                        let data = {
                          USERNAME: newNum,
                          PHONE: newNum,
                          PASSWORD: this.$md5(this.randomCoding())
                        }
                        savePhone(qs.stringify(data)).then(res => {
                          console.log('保存成功')
                          sendNew(this.phoneForm.phoneNum).then(res => {
                          })
                        })
                      }
                    })
                  }
                })
              } else {
                this.$message({
                  showClose: true,
                  message: '验证码错误',
                  type: 'error',
                  center: true
                })
                this.resetVerify()
              }
            } else {
              this.$message({
                showClose: true,
                message: '请先进行安全验证',
                type: 'error',
                center: true
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    touchstart (e) {
      this.startX = e.targetTouches[0].pageX
      this.startY = e.targetTouches[0].pageY
    },
    touchmove (e) {
      var moveX = e.targetTouches[0].pageX
      var moveY = e.targetTouches[0].pageY
      if (Math.abs(moveX - this.startX) > Math.abs(moveY - this.startY)) {
        e.preventDefault()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('touchstart', this.touchstart)
      window.addEventListener('touchmove', this.touchmove, {
        passive: false
      })
      this.bgHeight = window.innerHeight || document.documentElement.clientHeight
      this.mWidth = parseInt($('.m-loginBox .login-content')[0].clientWidth)
    })
    window.addEventListener('resize', () => {
      this.bgHeight = window.innerHeight || document.documentElement.clientHeight
    }, false)
  },
  beforeDestroy () {
    window.removeEventListener('touchstart', this.touchstart)
    window.removeEventListener('touchmove', this.touchmove)
  },
  components: { dragVerify }
}
</script>

<style scoped lang="less">
.login-bg{
  position: relative;
  min-width: 660px;
  background: url("../../assets/images/Login/bg.jpg") no-repeat center;
  .bg-txt{
    position: absolute;
    top: 26%;
    left: 21%;
    color: #f2fbf7;
    text-align: left;
    font-size: 0.6rem;
    .cn-txt{
    }
    .en-txt{
      font-weight: bold;
    }
  }
  .login-box{
    width: 500px;
    position: fixed;
    top: 18%;
    right: 15%;
    display: inline-block;
    .card{
      padding: 0.65rem;
      background: rgba(0,0,0,0.7);
      .header{
        position: relative;
        text-align: left;
        padding-bottom: 0.37rem;
        border-bottom: 1px solid #00B7CD;
        .txt{
          .en{
            font-size:0.555rem;
            color: #03B0C9;
          }
          .cn{
            font-size: 0.444rem;
            color:#FFFFFF;
          }
        }
        .switch-btn{
          position: absolute;
          right: 0;
          top: 50%;
          ul{
            li{
              display: inline-block;
              font-size: 0.28rem;
              color: #fff;
              margin-right: 0.2rem;
              cursor: pointer;
              i{
                color: #00B7CD;
              }
              img{
                width: 0.3rem;
              }
              span{
                margin-left: 0.1rem;
              }
            }
          }
        }
      }
      .content{
        .account{
          margin-top: 0.8rem;
          .forget{
            text-align: right;
            font-size: 0.25rem;
            a{
              color: #A5CD39;
            }
          }
        }
        .phone{
          margin-top: 0.8rem;
        }
        .wx-code{
          display: flex;
          align-items: center;
          margin-top: 0.8rem;
          .txt{
            text-align: left;
            padding-left: 0.3rem;
            font-size: 0.28rem;
            p{
              color: #fff;
            }
            span{
              color: #00b5cc;
            }
          }
        }
      }
    }
    .copy-txt{
      margin-top: 1.66rem;
      text-align: right;
      font-size:0.222rem;
      color: #f2fbf7;
    }
  }
}
@media screen and (max-width:990px){
  .login-bg{
    background: #fff;
    min-width: unset;
    .m-header{
      position: relative;
      .txt{
        position: absolute;
        top: 50%;
        left: 5%;
        color: #fff;
        text-align: left;
        font-size: 0.4rem;
        .en{
          font-weight: bold;
        }
      }
    }
    .login-content{
      padding: 0.6rem 0;
      margin: 0 0.37rem;
      .title{
        text-align: left;
      }
    }
  }
}
</style>
<style lang="less">
.login-bg{
  .photoCode{
    .drag_verify .dv_handler{
      width: 44px !important;
      height: 44px !important;
    }
  }
  .content{
    .el-form-item__label{
      color: #fff;
      font-size: 0.3rem;
    }
    .el-input__inner{
      border-radius: 0;
    }
    .account{
      .account-btn{
        margin-top: 0.7rem;
        .register-vip{
          padding-left:0.9rem;
          padding-right:0.9rem;
          color: #515555;
          background: #FFFFFF;
          border-radius: 0;
        }
        .sign-in{
          padding-left:0.9rem;
          padding-right:0.9rem;
          color: #ffffff;
          background: #A6CE36;
          border-color: #A6CE36;
          border-radius: 0;
        }
      }
    }
    .phone{
      .getPhone{
        span{
          color: #fff;
          display: inline-block;
          width: 7%;
        }
        .area-num{
          width:32%;
          margin-right: 3%;
          .el-input-group__prepend{
            border-right: 0;
            border-radius:0;
            padding:0 5px;
          }
        }
        .phone-num{
          width: 65%;
        }
      }
      .getCode{
        .el-form-item__content{
          text-align: left;
          .el-input{
            width: 60%;
          }
          .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
            color: #fff;
            background-color: #b3e19d;
            border-color: #b3e19d;
          }
          .el-button{
            width: 35%;
            float: right;
            color: #fff;
            background: #a5cd39;
            border-radius:0;
            border-color: #a5cd39;
          }
        }
      }
      .phone-btn{
        .el-form-item__content{
          margin-left: 0 !important;
          margin-top: 0.2rem;
        }
        .sign-in{
          padding-left:0.9rem;
          padding-right:0.9rem;
          color: #ffffff;
          background: #A6CE36;
          border-color: #A6CE36;
          border-radius: 0;
        }
      }
    }
  }
  .login-content{
    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
      content: '';
    }
    .el-form-item__label{
      font-size: 0.4rem;
      float: unset;
      display: block;
      text-align: left;
    }
    .el-input__inner{
      background: #f7f9fa;
      border-radius: 0;
    }
    .form-box1{
      margin-top: 0.8rem;
      .forget{
        font-size: 0.35rem;
        text-align: right;
        a{
          color: #707070;
        }
      }
      .sign-in{
        margin-top: 0.5rem;
        width: 100%;
        background: #a5cd39;
        color: #fff;
        border: #a5cd39;
        font-size: 0.35rem;
        border-radius: 0;
      }
    }
    .form-box2{
      .getPhone{
        .area-num {
          width: 32%;
          margin-right: 3%;
          .el-input-group__prepend {
            border-right: 0;
            border-radius: 0;
            padding: 0 5px;
          }
        }
        .phone-num {
          width: 65%;
        }
      }
      .getCode{
        .el-input{
          width: 70%;
        }
        .el-button{
          width: 30%;
          background: #a5cd39;
          color: #fff;
          border-color: #a5cd39;
          border-radius: 0;
        }
      }
      .phone-btn{
        .sign-in{
          margin-top: 0.5rem;
          width: 100%;
          background: #a5cd39;
          color: #fff;
          border: #a5cd39;
          font-size: 0.35rem;
          border-radius: 0;
        }
      }
    }
    .btn{
      .button{
        display: inline-block;
        width: 49%;
        font-size: 0.35rem;
        border: 1px solid #C7C7C7;
        padding: 0.15rem 0;
        i{
          color: #a5cd39;
          margin-right: 0.15rem;
        }
        &:nth-child(1){
          margin-right: 2%;
        }
      }
    }
  }
}
</style>
