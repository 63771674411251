// 封装了axios请求方法axios.post
// login是方法名
import axios from 'axios'

// 设置基准路径，给所有请求加上，会自动拼接
axios.defaults.baseURL = '/FH-WEB/'

// export const login = ('参数') => {
//     axios 执行返回得到的promise对象，将promise对象return回去
//     return axios.post('url', 参数)
// }

// 首页接口
export const cityHomea = (obj) => axios.post('web/getszmain?hide=en', obj)
export const cityHomeb = (obj) => axios.post('web/getexpfirst?hide=en', obj)
export const cityHomed = (obj) => axios.post('web/getszview?hide=en', obj)
export const cityHomef = (obj) => axios.post('web/getmaincitynum?hide=en', obj)
export const cityHomeg = (obj) => axios.post('web/getszsee?hide=en', obj)
// 大事件接口
export const cityEvent = (obj) => axios.post('web/getbigevent?HIDE=en', obj)
// 聚合页接口
export const citynap = (obj) => axios.post('web/getseecitynum?hide=en', obj)
// 城市号接口
// export const cityne = (obj) => axios.post('web/getseecitynum?hide=en', obj)
// export const citynz = (obj) => axios.post('web/getseecitynum?hide=zh', obj)
export const citynews = (id, obj) => axios.post(`web/getseecitynumzh?id=${id}`, obj)
export const citylist = (obj) => axios.post(`web/getcitynumnewsall?hide=en`, obj)
export const cityvideo = (id, obj) => axios.post(`web/getseecitynumvideo.do?SEECITYNUM_ID=${id}`, obj)
export const cityplate = (id) => axios.get(`web/city/plates`, { params: { id: id } })
// E5
export const citynewwen = (id, obj) => axios.post(`web/getseecitynummx?inid=${id}`, obj)
export const citynewwenaa = (obj) => axios.post(`web/getseecitynummx?inid=0ae00e2ac7524dfdb20a48e835f14210`, obj)
export const citynewwenbb = (obj) => axios.post(`web/getseecitynummx?inid=19b64f2a287448079aa2245d9ae251f4`, obj)
export const citynewwencc = (obj) => axios.post(`web/getseecitynummx?inid=30f2f85a0e8b42f7aef21216f6763b31`, obj)
export const citynew = (id, obj) => axios.post(`web/getseecitynummxs?inids=${id}`, obj)
// Q1
export const q1Carousel = (obj) => axios.post(`webq/gettest?hide=en`, obj)
// 深圳生活接口
export const szLifed1 = obj => axios.post('web/getszlifecyenter?heid=en', obj)
export const szLifed11 = obj => axios.post('web/getszlifeperson?heid=en', obj)
export const szLifed21 = obj => axios.post('web/getszlifecompanytype?heid=en', obj)
export const szLifed22 = obj => axios.post('web/getszlifeproperty?heid=en', obj)
export const szLifed3 = obj => axios.post('web/getszlifetaxlaw?hide=en', obj)
export const szLifed4 = obj => axios.post('web/getszlifevisa?hide=en', obj)
export const szLifed5 = obj => axios.post('web/getszlifecompanyguide?hide=en', obj)
// 鹏友圈
export const citynewwenb = (id, obj) => axios.post(`web/getseecitynummx??inid=${id}`, obj)
export const citynewwenbs = (id, obj) => axios.post(`web/getseecitynummx??inid=${id}`, obj)

// 深圳介绍接口
// A1q
export const szIntroduceA1 = obj => axios.post('web/getszjsoverview?hide=en', obj)
export const szIntroduceA12 = obj => axios.post('web/getszreform?hide=en', obj)
export const szIntroduceA13 = obj => axios.post('web/getszreformmx?inid=a83cd7e5333d418b8c26d199bed39d33', obj)
// A2
export const szcityba = obj => axios.post('web/getszjspillar?hide=en', obj)
export const szcitybb = obj => axios.post('web/getszjspillarmx?inid=0c36cd85b0c64564b0c777191ccbcacf', obj)
export const szcitybc = obj => axios.post('web/szjsnewindustriesService?hide=en', obj)
export const szcitybd = obj => axios.post('web/szjsfutureindust?hide=en', obj)
// A3
export const szcityc = obj => axios.post('web/getszjsinnovateService?hide=en', obj)
export const szcitycb = obj => axios.post('web/getszjsinnovatemx?inid=cc071805993d4d02ac2a668394b9e8ec', obj)
// A4
export const szcityd = obj => axios.post('web/getszjsdevelop?hide=en', obj)
// A5
// export const szcitye = obj => axios.post('web/getszlifecompanyguide?hide=zh', obj)
// A6
export const szcityf = obj => axios.post('web/getszjsmore?hide=en', obj)
// B1
export const szcityb1 = obj => axios.post('web/getexperiencesz?hide=en', obj)
export const szcityb12 = obj => axios.post('web/getseeSz?hide=en', obj)
export const szcityb13 = obj => axios.post('web/getcommentsz?hide=en', obj)
export const szcityb14 = obj => axios.post('web/getquestionsz?hide=en', obj)
export const getResource = obj => axios.post('web/getmuster?hide=en', obj)

// 平台介绍接口
// F1
export const ptintroduceA = obj => axios.post('web/getgyfunctionService?hide=en', obj)
export const ptintroduceB = obj => axios.post('web/getgywebapp?hide=en', obj)
export const ptintroduceC = obj => axios.post('web/getgywebappmx?inid=c91826f1d9ab42a1aa877e40014d5b46', obj)
// 鵬友圈
export const circleE = obj => axios.post('web/getSeepengs?hide=en', obj)
// 鹏友圈单个页面
export const circleD = (id, obj) => axios.post(`web/getSeepengzh?inid=${id}`, obj)
// 英文手机验证码
// Z1手机验证码
export const testphone = (num, obj) => axios.post(`webg/phoneregisteren?num=${num}`, obj)
// z2手机验证码
export const testphone2 = (num, obj) => axios.post(`webg/phonechangeen?num=${num}`, obj)
// 手机登录短信验证
export const phoneCode = (num, obj) => axios.post(`webg/phoneloginen?num=${num}`, obj)
// 手机信息变更验证码
export const phoneCode2 = (num, obj) => axios.post(`webg/phonechangeen?num=${num}`, obj)
// 英文邮箱验证码
// Z1邮箱验证码
export const testemail = (em, obj) => axios.post(`webg/sendEmailen?em=${em}`, obj)
// 修改密码 找回密码 验证邮箱验证码
export const testemail2 = (em, obj) => axios.post(`webg/sendEmailpwen?em=${em}`, obj)
