<template>
  <div id="app">
    <div id="nav">
      <router-link to="/"></router-link>
      <router-link to="/about"></router-link>
    </div>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { seoInfo } from './api'
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  mounted () {
    // if (sc.isSZSMT()) {
    //   //i深圳平台
    // }
    // else {
    //   //其他平台
    // }

    let user = JSON.parse(localStorage.getItem('userInfo'))
    if (user == undefined || user == null || user.userName == 'jk') {
      let userInfo = {
        userId: '97d7581471354cf4a84ec06218da7ad2',
        userName: 'yk'
      }
      userInfo = JSON.stringify(userInfo)
      localStorage.setItem('userInfo', userInfo)
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  watch: {
    '$route' (to) {
      let str = to.fullPath
      let lastLetter = str.charAt(str.length - 1)
      if (lastLetter == '/') {
        str = str.substring(0, str.length - 1)
      }
      seoInfo(str).then((res) => {
        if (res.data.length == 0) {
          $('title').html('CityPlus')
          $('meta[name="keywords"]').attr('content', 'CityPlus')
          $('meta[name="Description"]').attr('content', 'CityPlus')
        } else {
          $('title').html(res.data[0].title)
          $('meta[name="keywords"]').attr('content', res.data[0].keywords)
          $('meta[name="Description"]').attr('content', res.data[0].description)
        }
      })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  font-family: 'hyq45';
}
#nav {
  padding: 30px;
  background: rgba(0, 0, 0, 0.9);
  a {
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
