<template>
  <div>
    <div class="s-main-plate" v-for="(plate) in plates" :key="plate.id">
      <div class="bg-b-i"><div><img class="bg-b" :src="plate.picUrl" alt="" /></div></div>
      <div class="articles" v-for="(article) in plate.articles" :key="article.id">
        <div class="article">
          <div class="left">
            <a :href="article.articleLinkUrl"><img :src="article.articleImg" alt="" /></a>
          </div>
          <div class="right">
            <a :href="article.articleLinkUrl"><h3>{{ article.articleTitle }}</h3></a>
            <span v-if="article.articleTimeShow">
                          {{ article.articleTimeStart|formatDate }} - {{ article.articleTimeEnd|formatDate }}
                        </span>
            <p v-html="article.articleContent"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cityplate } from '../api'

export default {
  name: 'cityPlates',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      cityidc: '',
      plates: []
    }
  },
  watch: {
    id: function (val) {
      console.log(val)
      this.cityidc = val
      this.getData()
    }
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value)
      let str = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/')
      return str
    }
  },
  created () {
    this.cityidc = this.id
    this.getData()
  },
  methods: {
    getData () {
      cityplate(this.cityidc).then(res => {
        this.plates = res.data
        console.log(this.plates)
      })
    }
  }
}
</script>

<style scoped lang="less">
  .s-main-plate {
    .bg-b-i {
      max-width: 950px;
      margin: auto;
      margin-bottom: 20px;
      text-align: left;
      div{
        width: 23%;
        margin-top: 60px;
        margin-left: -10px;
      }
      .bg-b {
        height: auto;
        overflow: visible;
      }
    }
    @media screen and (max-width: 767px) {
      .bg-b-i {
        margin-bottom: 0;
        div {
          margin-top: 1rem;
          margin-bottom: 10px;
          width: 100%;
        }
        .bg-b {
          height: auto;
          max-width: 100%;
        }
      }
    }
    .articles {
      .article {
        margin: auto;
        max-width: 16.6rem;
        display: flex;
        border-bottom: 1px solid #CDD1D5;
        padding: 0.4815rem 0 0.2592rem 0;

        a {
          color: #2c3e50;
          display: block;
          &:hover {
            color: #2d8cf0;
          }
        }

        .left {
          flex: 0 0 5.037rem;
          a img {
            width: 100%;
          }
        }
        .right {
          padding: 0 0px 0 0.5185rem;
          font-family: 'Microsoft YaHei';
          text-align: left;
          font-size: 14px;
          a h3 {
            line-height: 18px;
            font-size: 18px;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 8.33rem;
          }
          span {
            line-height: 0.5185rem;
            text-align: left;
            color: #64BC4F;
          }
          p {
            padding-top: 0.0925rem;
            text-align: left;
          }
        }

        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
          .left {
            flex: 0 0 100%;
            a img {
              width: 100%;
            }
          }
          .right {
            a h3 {
              max-width: 9.17rem;
            }
            flex: 0 0 100%;
            padding: 0 0px 0 0.05rem;
          }

        }
      }
    }
  }
</style>
