<template>
    <div class="CityCC">
        <el-container>
            <el-header style="padding: 0" height="100%">
                <vheadere v-if="topShow" class="hidden-sm-and-down"></vheadere>
                <ivheaderae v-if="topShow" class="hidden-md-and-up"></ivheaderae>
            </el-header>
            <el-main style="padding: 0;margin:0 ">
              <div class="base-bg"></div>
              <iframe id="iframe" @load="iframeLoad" frameborder="0" scrolling="no" :height="iframeHeight" :src="iframeSrc"  width="100%"></iframe>
            </el-main>
            <el-footer>
              <vfootere v-if="bottomShow" class="hidden-sm-and-down"></vfootere>
              <ivfootere v-if="bottomShow" class="hidden-md-and-up"></ivfootere>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import { cityEventById } from '../../api'
export default {
  data () {
    return {
      iframeHeight: '',
      iframeSrc: '',
      topShow: false,
      bottomShow: false
    }
  },
  methods: {
    iframeLoad () {
      let _iframe = document.getElementById('iframe')
      try {
        let aHeight = _iframe.contentWindow.document.body.scrollHeight
        let bHeight = _iframe.contentWindow.document.documentElement.scrollHeight
        // console.log(aHeight, bHeight)
        var height = Math.max(aHeight, bHeight)
        this.iframeHeight = height
      } catch (e) {
        console.log('setHeight Error')
      }
    }
  },
  mounted () {
    let id = this.$route.query.id
    // console.log(id)
    cityEventById(id).then(res => {
      this.iframeSrc = res.data.PATH + res.data.INDEXPATH
      this.topShow = res.data.TOP == 'true'
      this.bottomShow = res.data.BOTTOM == 'true'
      setTimeout(this.iframeLoad, 2000)
      this.$nextTick(() => {
        this.iframeLoad()
      })
      window.addEventListener('resize', () => {
        this.iframeLoad()
      }, false)
    })
  }
}
</script>

<style lang="less">
.CityCC {
  iframe{
    display: block;
    background: url('../../assets/images/CC/base-bg.jpg') no-repeat center 0 fixed;
    background-size: cover;
  }
  @media screen and (max-width:760px){
    iframe{
      background: transparent;
    }
    .base-bg{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: url('../../assets/images/CC/base-bg-m.jpg') no-repeat;
      background-size: cover;
    }
  }
}
</style>
