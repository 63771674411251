import { render, staticRenderFns } from "./codeTicket.vue?vue&type=template&id=36644da8&scoped=true&"
import script from "./codeTicket.vue?vue&type=script&lang=js&"
export * from "./codeTicket.vue?vue&type=script&lang=js&"
import style0 from "./codeTicket.vue?vue&type=style&index=0&lang=css&"
import style1 from "./codeTicket.vue?vue&type=style&index=1&id=36644da8&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36644da8",
  null
  
)

export default component.exports