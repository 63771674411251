// 封装了axios请求方法axios.post
// login是方法名
import axios from 'axios'

// 设置基准路径，给所有请求加上，会自动拼接
axios.defaults.baseURL = '/FH-WEB/'

// respone拦截器 ==> 对响应做处理
axios.interceptors.response.use(
  // 成功请求到数据
  response => {
    let resStr = JSON.stringify(response)
    let resObj = resStr.replace(new RegExp('http://113.108.70.2:8080', 'gm'), '')
      .replace(new RegExp('http://113.108.70.2', 'gm'), '')
      .replace(new RegExp('http://www.cityplus.com:80', 'gm'), '')
      .replace(new RegExp('http://www.cityplus.com', 'gm'), '')
    return Promise.resolve(JSON.parse(resObj))
  },
  error => {
    return Promise.reject(error)
  }
)

// export const login = ('参数') => {
//     axios 执行返回得到的promise对象，将promise对象return回去
//     return axios.post('url', 参数)
// }
// seo接口
export const seoInfo = (link, obj) => axios.post(`web/getseoinfo?link=${link}`, obj)
// 首页接口
export const cityHomea = (obj) => axios.post('web/getszmain?hide=zh', obj)
export const cityHomeb = (obj) => axios.post('web/getexpfirst?hide=zh', obj)
export const cityHomed = (obj) => axios.post('web/getszview?hide=zh', obj)
export const cityHomef = (obj) => axios.post('web/getmaincitynum?hide=zh', obj)
export const cityHomeg = (obj) => axios.post('web/getszsee?hide=zh', obj)
// 大事件接口
export const cityEvent = (obj) => axios.post('web/getbigevent?HIDE=zh', obj)
export const cityEventById = (id, obj) => axios.post(`web/getBigeventById?ID=${id}`, obj)
// 聚合页接口
export const citynap = (obj) => axios.post('web/getseecitynum?hide=zh', obj)
// export const citynapen = (obj) => axios.post('getseecitynum?hide=en', obj)
// 城市号接口
// export const cityne = (obj) => axios.post('getseecitynum?hide=en', obj)
// export const citynz = (obj) => axios.post('web/getseecitynum?hide=zh', obj)
export const citynews = (id, obj) => axios.post(`web/getseecitynumzh?id=${id}`, obj)
export const citylist = (obj) => axios.post(`web/getcitynumnewsall?hide=zh`, obj)
export const cityvideo = (id, obj) => axios.post(`web/getseecitynumvideo.do?SEECITYNUM_ID=${id}`, obj)
export const cityplate = (id) => axios.get(`web/city/plates`, { params: { id: id } })

// E5
export const citynewwen = (id, obj) => axios.post(`web/getseecitynummx?inid=${id}`, obj)
// export const citynewwenaa = (obj) => axios.post(`web/getseecitynummx?inid=0ae00e2ac7524dfdb20a48e835f14210`, obj)
// export const citynewwenbb = (obj) => axios.post(`web/getseecitynummx?inid=19b64f2a287448079aa2245d9ae251f4`, obj)
// export const citynewwencc = (obj) => axios.post(`web/getseecitynummx?inid=30f2f85a0e8b42f7aef21216f6763b31`, obj)
export const citynew = (id, obj) => axios.post(`web/getseecitynummxs?inids=${id}`, obj)

// 深圳生活接口
export const szLifed1 = obj => axios.post('web/getszlifecyenter?heid=zh', obj)
export const szLifed11 = obj => axios.post('web/getszlifeperson?heid=zh', obj)
export const szLifed21 = obj => axios.post('web/getszlifecompanytype?heid=zh', obj)
export const szLifed22 = obj => axios.post('web/getszlifeproperty?heid=zh', obj)
export const szLifed3 = obj => axios.post('web/getszlifetaxlaw?hide=zh', obj)
export const szLifed4 = obj => axios.post('web/getszlifevisa?hide=zh', obj)
export const szLifed5 = obj => axios.post('web/getszlifecompanyguide?hide=zh', obj)
// 鹏友圈
export const citynewwenb = (id, obj) => axios.post(`web/getseecitynummx?inid=${id}`, obj)
export const citynewwenbs = (id, obj) => axios.post(`web/getseecitynummx?inid=${id}`, obj)

// 深圳介绍接口
// A1q
export const szIntroduceA1 = obj => axios.post('web/getszjsoverview?hide=zh', obj)
export const szIntroduceA12 = obj => axios.post('web/getszreform?hide=zh', obj)
export const szIntroduceA13 = obj => axios.post('web/getszreformmx?inid=62a4f1a424564c7f81d2db6f87c2c4fa', obj)
// A2
export const szcityba = obj => axios.post('web/getszjspillar?hide=zh', obj)
export const szcitybb = obj => axios.post('web/getszjspillarmx?inid=941e8501c9e148ddb7d9a5226da34a9d', obj)
export const szcitybc = obj => axios.post('web/szjsnewindustriesService?hide=zh', obj)
export const szcitybd = obj => axios.post('web/szjsfutureindust?hide=zh', obj)
// A3
export const szcityc = obj => axios.post('web/getszjsinnovateService?hide=zh', obj)
export const szcitycb = obj => axios.post('web/getszjsinnovatemx?inid=d8eea4b5a1f74c75a599d85e1c157266', obj)
// A4
export const szcityd = obj => axios.post('web/getszjsdevelop?hide=zh', obj)
// A5
// export const szcitye = obj => axios.post('getszlifecompanyguide?hide=zh', obj)
// A6
export const szcityf = obj => axios.post('web/getszjsmore?hide=zh', obj)

// B1
export const szcityb1 = obj => axios.post('web/getexperiencesz?hide=zh', obj)
export const szcityb12 = obj => axios.post('web/getseeSz?hide=zh', obj)
export const szcityb13 = obj => axios.post('web/getcommentsz?hide=zh', obj)
export const szcityb14 = obj => axios.post('web/getquestionsz?hide=zh', obj)
export const getResource = obj => axios.post('web/getmuster?hide=zh', obj)

// 平台介绍接口
// F1
export const ptintroduceA = obj => axios.post('web/getgyfunctionService?hide=zh', obj)
export const ptintroduceB = obj => axios.post('web/getgywebapp?hide=zh', obj)
export const ptintroduceC = obj => axios.post('web/getgywebappmx?inid=30df84480987463aaf4687ca96ef6a18', obj)
// 鵬友圈
export const circleE = obj => axios.post('web/getSeepengs?hide=zh', obj)
// 鹏友圈单个页面
export const circleD = (id, obj) => axios.post(`web/getSeepengzh?inid=${id}`, obj)

// Q6接口
export const registration = (obj) => axios.post('web/SaveEnterActive.do', obj)
// 获取活动信息
export const getActInfo = (obj) => axios.post('web/getactiveedit', obj)
// 编辑活动信息
export const editActInfo = (obj) => axios.post('web/editactive', obj)

// Q5接口

export const activity = (obj) => axios.post(`web/getactivity`, obj)

// Q2接口
export const funlist = (obj) => axios.post('web/getactive?hide=zh', obj)

// Z1手机验证码
export const testphone = (num, obj) => axios.post(`webq/phone?num=${num}`, obj)
// z2手机验证码
export const testphone2 = (num, obj) => axios.post(`webg/phoneRePW?num=${num}`, obj)
// Z1注册
export const logon = (obj) => axios.post('webq/saveuser.do', obj)
// Z1手机验证账号是否存在
export const checknum = (num, obj) => axios.post(`webg/Authnum?num=${num}`, obj)

// Z1邮箱验证码
export const testemail = (em, obj) => axios.post(`webg/sendEmail?em=${em}`, obj)
// 修改密码 找回密码 验证邮箱验证码
export const testemail2 = (em, obj) => axios.post(`webg/sendEmailpw?em=${em}`, obj)
// Z1邮箱验证账号是否存在
export const checkemail = (em, obj) => axios.post(`webg/Authem?em=${em}`, obj)

// 验证用户名是否注册
export const checkname = (name, obj) => axios.post(`webg/Authuser?user=${name}`, obj)

// 登录验证账号密码是否正确
export const accountLogin = (num, obj) => axios.post(`webg/Login?un=${num}`, obj)
// 新的登录接口
export const NaccountLogin = (obj) => axios.post(`webg/logins.do`, obj)
// 手机登录验证
export const phoneLogin = (num, obj) => axios.post(`webg/Authnum?num=${num}`, obj)
// 手机登录短信验证
export const phoneCode = (num, obj) => axios.post(`webg/phoneenter?num=${num}`, obj)
// 手机信息变更验证码
export const phoneCode2 = (num, obj) => axios.post(`webg/phonechange?num=${num}`, obj)
// 保存用户信息
export const savePhone = (obj) => axios.post('webq/saveuser.do', obj)
// 用户发送短信接口
export const sendNew = (num, password, obj) => axios.post(`webg/Loginpw?un=${num}&pw=${password}`, obj)
// G修改密码(旧密码查询)
export const editpw = (un, upw, obj) => axios.post(`webg/editpw?un=${un}&upw=${upw}`, obj)

// G修改密码(修改新密码)
export const editsave = (obj) => axios.post(`webg/editsave`, obj)
// 登录成功获取用户信息
export const getUserinfo = (num, obj) => axios.post(`webg/loginuser?un=${num}`, obj)

// 收藏主办方
export const collectSponsor = (num, obj) => axios.post(`webg/listrzsponsor?uid=${num}`, obj)
// 没收藏主办方
export const noCollectSponsor = (num, obj) => axios.post(`webg/listrzsponsorno?uid=${num}`, obj)
// 取消收藏
export const delCollect = (obj) => axios.post(`webg/Delcollectsponsor.do`, obj)
// 添加收藏
export const addCollect = (obj) => axios.post(`webg/Savecollectsponsor.do`, obj)
// 判断是否收藏
export const checkCollect = (userId, sponsorId, obj) => axios.post(`webg/Checksp?USERID=${userId}&SPONSORID=${sponsorId}`, obj)
// 地区分类
export const sidesort = (obj) => axios.post('webq/FunArea?hide=zh', obj)

// 活动分类
export const funsort = (obj) => axios.post('webq/Classify?hide=zh', obj)

// 主办方认证
export const sponsoratttest = (obj) => axios.post('webq/Rzsponsor.do', obj)

// 找回密码
export const findpsw = (obj) => axios.post('webg/EditPwUsername.do', obj)

// G4收藏活动
export const colfunlist = (id, obj) => axios.post(`webg/listhd?uid=${id}`, obj)

// G3参与活动
export const joinfunlist = (id, obj) => axios.post(`webg/reactive?id=${id}`, obj)

// 获取用户个人资料
export const getUserData = (obj) => axios.post('webg/getsponsor', obj)
// 修改用户个人资料
export const saveUserinfo = (obj) => axios.post('webg/editperson', obj)
// 修改邮箱
export const editEmail = (obj) => axios.post('webg/editpersonem.do', obj)
// 修改手机
export const editPhone = (obj) => axios.post('webg/editpersonnum.do', obj)
// G2发起活动

// 签到验票
export const checkCode = (num, obj) => axios.post(`webg/checkcode?code=${num}`, obj)
export const shstate = (obj) => axios.post(`webg/shstate.do`, obj)

export const initiatelist = (num, obj) => axios.post(`webq/getactivezbf?uid=${num}`, obj)

// G6主办方内容展示
export const listrzsp = (num, obj) => axios.post(`webq/listrzsp?id=${num}`, obj)

// q1
export const q1Carousel = (obj) => axios.post(`webq/gettest?hide=zh`, obj)
export const q1Activity = (obj) => axios.post(`web/getactive?hide=zh`, obj)
// Q3主办方列表
export const sponsorList = (obj) => axios.post(`webq/getsponsorrz`, obj)
export const addSponsor = (obj) => axios.post(`webq/Savecollectsponsor.do`, obj)
// G2b获取列表
export const examinelist = (id, obj) => axios.post(`web/getactivesp?id=${id}`, obj)
// G2B审核(通过)
export const examine = (obj) => axios.post(`webg/shsponsors`, obj)
// G2b审核(不通过)
export const noexamine = (obj) => axios.post(`webg/shfalse`, obj)
// G2b审核(已取消)
export const cancel = (obj) => axios.post(`webg/shcancel`, obj)
// Q5留言
export const Leavemsg = (obj) => axios.post(`web/SaveFormActive.do`, obj)
// G取消参与
export const stopjoin = (obj) => axios.post(`webg/CancelEnterActive`, obj)
// G2b群发消息
export const qsendems = (cnt, obj) => axios.post(`webg/sendems?conent=${cnt}`, obj)
// G2b设置留言
export const shsponsorsedit = (obj) => axios.post(`webg/shsponsorsedit`, obj)
// G2b降序
export const getactivesppx = (id, obj) => axios.post(`web/getactivesppx?id=${id}`, obj)
// G2b升序
export const getactivesppxs = (id, obj) => axios.post(`web/getactivesppxs?id=${id}`, obj)
// G2b查询
export const searchlist = (obj) => axios.post(`web/findfree`, obj)
// Q5留言列表
export const listly = (id, obj) => axios.post(`web/listly?id=${id}`, obj)
// G2取消活动
export const CancelActive = (obj) => axios.post(`webg/CancelActive`, obj)
// Q5收藏接口
export const colfun = (obj) => axios.post(`webg/SavecollectActive.do`, obj)
// Q5取消收藏
export const delcolfun = (obj) => axios.post(`webg/DelcollectActive.do`, obj)
// Q5判断收藏
export const cleckcol = (uid, aid, obj) => axios.post(`webg/Check?USERID=${uid}&ACTIVITYID=${aid}`, obj)
// Q5点赞
export const dz = (obj) => axios.post(`web/editly.do`, obj)
// Q2列表时间降序
export const timelist = (obj) => axios.post(`web/getactivesort?hide=zh`, obj)
// 参与人数
export const joinNum = (obj) => axios.post(`web/getactivesortsp`, obj)
// 没参与人数
export const nojoinNum = (obj) => axios.post(`web/getactivesortspnot`, obj)
// 机构类型
export const jgtypelist = (obj) => axios.post(`webq/OrganClassify`, obj)
// get活动收藏量
export const getfuncolnum = (id, obj) => axios.post(`web/collectactive?id=${id}`, obj)
// get主办方收藏量
export const getzbfcolnum = (id, obj) => axios.post(`web/collectsponsor?id=${id}`, obj)
// get活动浏览量
export const getfunlooknum = (obj) => axios.post(`web/editactivessee.do`, obj)
// 添加主办方浏览量
export const postfunlooknum = (obj) => axios.post(`webg/editspssee.do`, obj)
// G6判断是否审核通过
export const checkshpass = (id, obj) => axios.post(`webq/listrzspno?id=${id}`, obj)
// Q4获取主办方资料
export const getzbfs = (id, obj) => axios.post(`webq/getzbfs?uid=${id}`, obj)
