<template>
    <div class="CityCC">
        <el-container>
            <el-header style="padding: 0" height="100%">
                <vheader v-if="topShow" class="hidden-sm-and-down"></vheader>
                <ivheadera v-if="topShow" class="hidden-md-and-up"></ivheadera>
            </el-header>
            <el-main style="padding: 0;margin:0 ">
              <div class="base-bg"></div>
              <iframe id="iframe" @load="iframeLoad" frameborder="0" scrolling="no" allowfullscreen="true" :height="iframeHeight" :src="iframeSrc"  width="100%"></iframe>
            </el-main>
            <el-footer>
              <vfooter v-if="bottomShow" class="hidden-sm-and-down"></vfooter>
              <ivfooter v-if="bottomShow" class="hidden-md-and-up"></ivfooter>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import { cityEventById } from '../../api'
export default {
  data () {
    return {
      iframeHeight: '',
      iframeSrc: '',
      topShow: false,
      bottomShow: false
    }
  },
  methods: {
    iframeLoad () {
      let _iframe = document.getElementById('iframe')
      try {
        let aHeight = _iframe.contentWindow.document.body.scrollHeight
        let bHeight = _iframe.contentWindow.document.documentElement.scrollHeight
        var height = Math.max(aHeight, bHeight)
        this.iframeHeight = height
      } catch (e) {
        console.log('setHeight Error')
      }
    },
    throttle (fn, threshhold) {
      // 记录上次执行的时间
      var last
      // 定时器
      var timer
      // 默认间隔为 250ms
      threshhold || (threshhold = 250)
      // 返回的函数，每过 threshhold 毫秒就执行一次 fn 函数
      return function () {
        // 保存函数调用时的上下文和参数，传递给 fn
        var context = this
        var args = arguments
        var now = +new Date()
        // 如果距离上次执行 fn 函数的时间小于 threshhold，那么就放弃
        // 执行 fn，并重新计时
        if (last && now < last + threshhold) {
          clearTimeout(timer)
          // 保证在当前时间区间结束后，再执行一次 fn
          timer = setTimeout(function () {
            last = now
            fn.apply(context, args)
          }, threshhold)
          // 在时间区间的最开始和到达指定间隔的时候执行一次 fn
        } else {
          last = now
          fn.apply(context, args)
        }
      }
    }
  },
  mounted () {
    let id = this.$route.query.id
    cityEventById(id).then(res => {
      this.iframeSrc = res.data.PATH + res.data.INDEXPATH
      this.topShow = res.data.TOP == 'true'
      this.bottomShow = res.data.BOTTOM == 'true'
      setTimeout(this.iframeLoad, 2000)
      this.$nextTick(() => {
        this.iframeLoad()
      })
    })
    window.addEventListener('resize', this.throttle(this.iframeLoad, 250))
  }
}
</script>

<style lang="less">
.CityCC {
  iframe{
    display: block;
    background: url('../../assets/images/CC/base-bg.jpg') no-repeat center 0 fixed;
    background-size: cover;
  }
  @media screen and (max-width:760px){
    iframe{
      background: transparent;
    }
    .base-bg{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: url('../../assets/images/CC/base-bg-m.jpg') no-repeat;
      background-size: cover;
    }
  }
}
</style>
