import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: '',
    userName: ''
  },
  mutations: {
    saveId (state, newVal) {
      state.userId = newVal
    },
    saveName (state, newVal) {
      state.userName = newVal
    }
  },
  actions: {

  }
})
