import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import iView from 'iview'
import 'element-ui/lib/theme-chalk/index.css'
import 'lib-flexible/flexible'
import './static/css/reset.css'
import 'iview/dist/styles/iview.css'
import 'element-ui/lib/theme-chalk/display.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import header from './components/headerNav'
import footer from './components/footer'
import ifooter from './components/ifooter'
import iheadera from './components/iheader1'
import headere from './components/headerNave'
import footere from './components/footere'
import ifootere from './components/ifootere'
import iheaderae from './components/iheader1e'
import Gotop from './components/gotop'
import './assets/css/font.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VueClipboard from 'vue-clipboard2'
// import VueAMap from 'vue-amap';
import BaiduMap from 'vue-baidu-map'
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css'
// import locale from 'element-ui/lib/locale/lang/en'
// import i18n from 'vue-i18n'
// import QRCode from 'qrcodejs2'
import i18n from './i18n/i18n'
import md5 from 'js-md5'
import MetaInfo from 'vue-meta-info'
Vue.prototype.$md5 = md5
Vue.use(BaiduMap, {
  ak: '0c47ve1VaBp0ZXyjzQ98lFMKpGMM8q4p'
})

Vue.use(MetaInfo)
Vue.use(Share)
Vue.use(ElementUI)
// Vue.use(VueAMap)
if (window.location.href.indexOf('EN') != -1) {
  localStorage.setItem('lang', 'en')
  i18n.locale = 'en'
} else {
  localStorage.setItem('lang', 'cn')
  i18n.locale = 'cn'
}
// 在跳转之后判断
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
// 在跳转之前执行
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})
Vue.directive('anchor', {
  inserted: function (el, binding) {
    el.onclick = function () {
      $('html,body').animate({
        scrollTop: $('#anchor-' + binding.value).offset().top - 100
      }, 500)
    }
  }
})
// Vue.use(i18n)
// Vue.use(ElementUI, {
//   i18n:(key,value) => i18n.t(key,value)
// })
Vue.use(iView)
Vue.use(VueClipboard)
// Vue.use(ElementUI)
// Vue.use(QRCode)

Vue.prototype.$video = Video
Vue.component('vheader', header)
Vue.component('vfooter', footer)
Vue.component('ivfooter', ifooter)
Vue.component('ivheadera', iheadera)
Vue.component('vheadere', headere)
Vue.component('vfootere', footere)
Vue.component('ivfootere', ifootere)
Vue.component('ivheaderae', iheaderae)
Vue.component('gotop', Gotop)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app')
