<template>
  <div class="w">
    <div class="main-p main-p2b" v-show="true">
      <p class="tit">名单管理</p>
      <div class="main-top-sel">
        <div class="first-sel">
          <p>名单排序</p>
          <el-select v-model="sortoptsval"><el-option v-for="item in sortopts" :key="item.value" :label="item.value" :value="item.value"></el-option></el-select>
        </div>
        <div class="second-sel">
          <div class="secsel">
            <p>筛选查找</p>
            <el-select v-model="seloptsval" @change="changeval">
              <el-option v-for="item in selopts" :key="item.value" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="sec-inp">
            <el-input v-model="selinput" placeholder="输入名称/手机/邮箱查找"></el-input>
            <button @click="search()">查找</button>
          </div>
        </div>
        <div class="back" @click="backList"><button>返回发起列表</button></div>
      </div>
      <div class="main-operate">
        <ul class="operate-ul">
          <li>
            <i class="el-icon-s-tools"></i>
            操作区
          </li>
          <!-- <li @click="listreview = !listreview" :class="listreview ? 'act' : ''">
            <i class="el-icon-s-check"></i>
            名单审核
            <div class="listreview" v-show="listreview">
              <ul>
                <li @click="opentc('tc2')">审核通过</li>
                <li @click="opentc('tc4')">审核不通过</li>
              </ul>
            </div>
          </li> -->
          <li @click="opentc('tc3')">
            <i class="el-icon-error"></i>
            取消资格
          </li>
          <li @click="opentc('tc7')">
            <i class="el-icon-message-solid"></i>
            群发通知
          </li>
          <li @click="opentc('tc6')">
            <i class="el-icon-circle-plus-outline"></i>
            添加名单
          </li>
          <li @click="opentc('tc5')">
            <i class="el-icon-excel"></i>
            导出名单
          </li>
          <li @click="opentc('tc1')">
            <i class="el-icon-s-comment"></i>
            设置备注
          </li>
        </ul>
      </div>
      <div class="roler-list" ref="rolerlist">
        <p class="list-tit">{{ title }} 名单列表</p>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" v-if="tableData.length != 0">
          <el-table-column type="selection" show-overflow-tooltip></el-table-column>
          <el-table-column prop="NAME" label="名称" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="CELLPHONE" label="手机" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="EMAIL" label="邮箱" show-overflow-tooltip></el-table-column>
          <el-table-column prop="A" label="状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <p :class="`txt${scope.row.A}`">{{ typestatus(scope.row.A) }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="TIME" label="报名时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.TIME != undefined ? gettime(scope.row.TIME) : '-' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="TEN" label="备注" show-overflow-tooltip></el-table-column>
          <el-table-column prop="NIGHT" label="更多信息" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <div slot="content">
                  <div v-if="scope.row.NIGHT == ''">
                    <p>无</p>
                  </div>
                  <div v-if="scope.row.NIGHT != ''">
                    <p v-for="(item, index) in JSON.parse(scope.row.NIGHT) || []" :key="index" v-if="item.value != ''">{{ item.title }}:{{ item.value }}</p>
                  </div>
                </div>
                <i class="el-more-msg"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-table tooltip-effect="dark" style="width: 100%;border-top: 1px solid #EBEEF5;" v-if="tableData.length == 0"></el-table>
      </div>

      <div class="mask" v-show="mask"></div>
      <!-- 设置备注 -->
      <div class="tc main-tc1" v-show="tc1">
        <p class="tit">设置备注</p>
        <p class="sel-tit">您已选择{{ passlist.length }}个用户：</p>
        <div class="con-form">
          <span v-for="(item, index) in passlist" :key="index">{{ item.NAME }}{{ item.CELLPHONE }}；</span>
        </div>
        <p class="sel-tit">添加备注</p>
        <textarea class="inp" v-model="remaskinp" />
        <div class="btns">
          <a class="btn1" @click="closetc('tc1')">取消</a>
          <a class="btn2" @click="setup()">确认添加</a>
        </div>
      </div>
      <!-- 审核通过 -->
      <div class="tc main-tc1" v-show="tc2">
        <p class="tit">审核通过</p>
        <p class="sel-tit">您已选择{{ passlist.length }}个用户：</p>
        <div class="con-form">
          <span v-for="(item, index) in passlist" :key="index">{{ item.NAME }}{{ item.CELLPHONE }}；</span>
        </div>
        <p class="sel-tit">以上用户顺利通过审核。</p>
        <div class="btns">
          <a class="btn1" @click="closetc('tc2')">取消</a>
          <a class="btn2" @click="pass()">完成操作</a>
        </div>
      </div>
      <!-- 取消资格 -->
      <div class="tc main-tc1" v-show="tc3">
        <p class="tit">取消资格</p>
        <p class="sel-tit">您已选择{{ passlist.length }}个用户：</p>
        <div class="con-form">
          <span v-for="(item, index) in passlist" :key="index">{{ item.NAME }}{{ item.CELLPHONE }}；</span>
        </div>
        <p class="sel-tit">请填写取消资格理由</p>
        <textarea class="inp" v-model="nopasstext" />
        <div class="btns">
          <a class="btn1" @click="closetc('tc3')">取消</a>
          <a class="btn2" @click="disqualification()">完成操作</a>
        </div>
      </div>
      <!-- 审核不通过 -->
      <div class="tc main-tc1" v-show="tc4">
        <p class="tit">审核不通过</p>
        <p class="sel-tit">您已选择{{ passlist.length }}个用户：</p>
        <div class="con-form">
          <span v-for="(item, index) in passlist" :key="index">{{ item.NAME }}{{ item.CELLPHONE }}；</span>
        </div>
        <p class="sel-tit">请填写不通过理由</p>
        <textarea class="inp" />
        <div class="btns">
          <a class="btn1" @click="closetc('tc4')">取消</a>
          <a class="btn2" @click="nopass()">完成操作</a>
        </div>
      </div>
      <!-- 导出名单 -->
      <div class="tc main-tc1" v-show="tc5">
        <p class="tit">导出名单</p>
        <div class="con-form2">
          <!-- <el-radio v-model="dradio" label="1">
            PDF
            <span>适用于打印</span>
          </el-radio> -->
          <el-radio v-model="dradio" label="1">
            EXCEL
            <span>适用于编辑名单</span>
          </el-radio>
        </div>
        <div class="btns">
          <a class="btn1" @click="closetc('tc5')">取消</a>
          <a class="btn2" @click="getformimg()">完成操作</a>
        </div>
      </div>
      <!-- 添加名单 -->
      <div class="tc main-tc1" v-show="tc6">
        <p class="tit">添加名单</p>
        <div class="con-form3">
          <el-radio v-model="tradio" label="1">填写添加(单个添加)</el-radio>
          <!-- <el-radio v-model="tradio" label="2">批量添加</el-radio> -->
        </div>
        <div v-show="tradio == '1'">
          <div class="tform">
            <el-form :model="tformdata" :rules="tformrules" ref="tform">
              <el-form-item label="姓名" prop="name"><el-input v-model="tformdata.name"></el-input></el-form-item>
              <el-form-item label="邮箱" prop="email"><el-input v-model="tformdata.email"></el-input></el-form-item>
              <!-- <el-form-item label="手机" prop="phone"><el-input type="number" v-model="tformdata.phone"></el-input></el-form-item> -->
            </el-form>
          </div>
          <div class="btns">
            <a class="btn1" @click="closetc('tc6')">取消</a>
            <a class="btn2" @click="tsubmit()">完成操作</a>
          </div>
        </div>
        <!-- <div v-show="tradio == '2'">
          <p class="tip">
            您可以通过上传EXCEL来批量导入名单：
            <span>
              <i class="el-icon-download"></i>
              <a>下载EXCEL表模板</a>
            </span>
          </p>
          <div class="upload">
            <p v-show="filename != ''">
              您已经上传文件：
              <span>{{filename}}</span>
            </p>
            <button class="upload-btn">
              上传名单文件
              <input type="file" accept=".xls,.xlsx" @change="importf($event)" />
            </button>
          </div>
          <div class="btns">
            <a class="btn1" @click="closetc('tc6')">取消</a>
            <a class="btn2">完成操作</a>
          </div>
        </div> -->
      </div>
      <!-- 群发信息 -->
      <div class="tc main-tc1" v-show="tc7">
        <p class="tit">群发消息</p>
        <div class="con-form3">
          <el-radio v-model="qradio" label="1">入场信息</el-radio>
          <el-radio v-model="qradio" label="2">取消参与</el-radio>
          <el-radio v-model="qradio" label="3">活动改期</el-radio>
        </div>
        <p class="sel-tit">消息内容</p>
        <el-date-picker
          v-show="dateinp"
          v-model="value1"
          class="dateinp"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <textarea class="inp" v-model="qinp" readonly="readonly" />
        <p class="tip">
          <i style="color: #a5cd39;font-style: inherit;">参数说明：</i>
          $code票码编号
        </p>
        <p class="tip" style="padding-top: 0;" v-show="dateinp">请在上方时间选择器选择调整后的活动日期</p>
        <div class="btns">
          <a class="btn1" @click="closetc('tc7')">取消</a>
          <a class="btn2" @click="qesendmail()">确定群发</a>
        </div>
      </div>
    </div>
    <div id="table1" ref="table" v-show="tabletc">
      <table border="1" cellspacing="0" cellpadding="0">
        <thead>
          <tr style="background: #eee;">
            <td>名称</td>
            <!-- <td>手机</td> -->
            <td>邮箱</td>
            <td>状态</td>
            <td>报名时间</td>
            <td>备注</td>
            <template v-if="tableData.length != 0 && tableData[0].NIGHT != ''">
              <td v-for="(item, index) in JSON.parse(tableData[0].NIGHT) || []" :key="index">{{ item.title }}</td>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>{{ item.NAME }}</td>
            <!-- <td>{{ item.CELLPHONE }}</td> -->
            <td>{{ item.EMAIL }}</td>
            <td :class="`txt${item.A}`">{{ typestatus(item.A) }}</td>
            <td>{{ item.TIME }}</td>
            <td>{{ item.TEN }}</td>
            <template v-if="item.NIGHT !=''">
              <td v-for="(item2, index2) in JSON.parse(item.NIGHT) || []" :key="index2">{{ item2.value }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import XLSX from 'xlsx'
import { examinelist, examine, noexamine, cancel, qsendems, Leavemsg, getactivesppx, getactivesppxs, searchlist, shsponsorsedit } from '../../api'
import qs from 'qs'
export default {
  data () {
    const _this = this
    var checkemail = (rule, value, callback) => {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (reg.test(this.tformdata.email)) {
        callback()
      } else {
        callback(new Error('邮箱格式错误'))
      }
    }
    return {
      Screentext: '',
      title: '',
      passlist: [],
      nopasstext: '',
      sortopts: [
        {
          value: '按报名日期升序'
        },
        {
          value: '按报名日期降序'
        }
      ],
      sortoptsval: '按报名日期升序',
      selopts: [
        {
          value: '全部状态'
        },
        {
          value: '已签到'
        },
        {
          value: '未签到'
        },
        // {
        //   value: '有效'
        // },
        // {
        //   value: '不通过'
        // },
        // {
        //   value: '待审核'
        // },
        {
          value: '已取消'
        }
      ],
      seloptsval: '全部状态',
      selinput: '',
      listreview: false,
      tableData: [
        // {
        //   name: '花生晴晴',
        //   phone: '13800138000',
        //   email: 'abc@abc.com',
        //   status: '1',
        //   joindate: '7月9日 18:00',
        //   remarks: '无'
        // },
        // {
        //   name: '花生晴晴',
        //   phone: '13800138000',
        //   email: 'abc@abc.com',
        //   status: '2',
        //   joindate: '7月9日 18:00',
        //   remarks: '无'
        // },
        // {
        //   name: '花生晴晴',
        //   phone: '13800138000',
        //   email: 'abc@abc.com',
        //   status: '3',
        //   joindate: '7月9日 18:00',
        //   remarks: '无'
        // },
        // {
        //   name: '花生晴晴',
        //   phone: '13800138000',
        //   email: 'abc@abc.com',
        //   status: '4',
        //   joindate: '7月9日 18:00',
        //   remarks: '无'
        // },
        // {
        //   name: '花生晴晴',
        //   phone: '13800138000',
        //   email: 'abc@abc.com',
        //   status: '5',
        //   joindate: '7月9日 18:00',
        //   remarks: '无'
        // },
        // {
        //   name: '花生晴晴',
        //   phone: '13800138000',
        //   email: 'abc@abc.com',
        //   status: '6',
        //   joindate: '7月9日 18:00',
        //   remarks: '无'
        // }
      ],
      tableData2: [],
      multipleSelection: [],
      dradio: '1',
      tradio: '1',
      tformdata: {
        name: '',
        email: '',
        phone: ''
      },
      tformrules: {
        name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' },
          {
            required: true,
            validator: checkemail,
            trigger: 'change'
          }
        ],
        phone: [{ required: true, message: '请填写手机', trigger: 'blur' }]
      },
      qradio: '1',
      filename: '',
      // 弹窗
      mask: false, // 蒙版
      tc1: false, // 设置备注
      tc2: false, // 审核通过
      tc3: false, // 取消资格
      tc4: false, // 审核不通过
      tc5: false, // 导出名单
      tc6: false, // 添加名单
      tc7: false, // 群发信息
      tabletc: false,
      qinp: '',
      value1: [new Date(2019, 10, 10, 10, 10), new Date(2019, 10, 11, 10, 10)],
      dateinp: false,
      remaskinp: ''
    }
  },
  inject: ['reload'],
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/Login')
      })
    } else {
      if (JSON.stringify(this.$route.params) != '{}') {
        let data = JSON.stringify(this.$route.params.item)
        localStorage.setItem('examine', data)
      }
      let datas = JSON.parse(localStorage.getItem('examine'))
      this.title = datas.TITLE
      let id = datas.MAKEACTIVITY_ID
      getactivesppxs(id).then(res => {
        if (res.data[0].hasNext == 'no') {
          this.tableData = []
          this.tableData2 = []
          this.qinp = `感谢您报名“${datas.TITLE}活动”您的票码编号为$code，活动时间是${this.gettime(datas.STARTTIME)}-${this.gettime(datas.ENDTIME)},请提早半小时入场。`
        } else {
          this.tableData = res.data
          this.tableData2 = res.data
          this.qinp = `感谢您报名“${datas.TITLE}活动”您的票码编号为$code，活动时间是${this.gettime(datas.STARTTIME)}-${this.gettime(datas.ENDTIME)},请提早半小时入场。`
        }
      })
    }
  },
  methods: {
    handleSelectionChange (val) {
      this.multipleSelection = val
      this.passlist = this.multipleSelection
    },
    backList () {
      this.$router.push('/user/eventadmin')
    },
    typestatus (str) {
      let status
      str == '1'
        ? (status = '有效')
        : str == '2'
          ? (status = '已取消')
          : str == '3'
            ? (status = '不通过')
            : str == '4'
              ? (status = '已签到')
              : str == '5'
                ? (status = '待审核')
                : (status = '未签到')
      return status
    },
    tsubmit () {
      let acstarttime = new Date((JSON.parse(localStorage.getItem('examine')).STARTTIME2)).valueOf()
      let acendtime = new Date((JSON.parse(localStorage.getItem('examine')).ENDTIME2)).valueOf()
      let nowtime = (new Date()).valueOf()
      if(acendtime < nowtime) {
        this.$message({
          showClose: true,
          message: '该活动已结束。',
          type: 'error',
          center: true
        })
      } else if( acstarttime > nowtime ){
        this.$message({
          showClose: true,
          message: '该活动未到报名时间。',
          type: 'error',
          center: true
        })
      } else {
        this.$refs['tform'].validate(valid => {
          if (valid) {
            let datas = JSON.parse(localStorage.getItem('examine'))
            let list = {
              NAME: this.tformdata.name,
              CELLPHONE: '',
              EMAIL: this.tformdata.email,
              NIGHT: '',
              MAKEACTIVITY_ID: datas.MAKEACTIVITY_ID,
              language: 'cn'
            }
            Leavemsg(qs.stringify(list)).then(res => {
              if(res.data.resultemail == 'YES'){
                this.$message({
                  type: 'error',
                  message: '该邮箱已报名该活动，请勿重复报名！',
                  center: true
                })
              } else {
                if (res.data.result == 'ok') {
                  this.$message({
                    type: 'success',
                    message: '操作成功！',
                    center: true
                  })
                  this.reload()
                } else {
                  this.$message({
                    type: 'error',
                    message: '操作失败，请刷新页面后重试！',
                    center: true
                  })
                }
              }
            })
          }
        })
      }
    },
    opentc (tcname) {
      this.mask = true
      this[tcname] = true
    },
    closetc (tcname) {
      this.mask = false
      this[tcname] = false
    },
    gettime (date) {
      var newdate = new Date(date)
      var year = newdate.getFullYear()
      var month = newdate.getMonth() + 1
      var day = newdate.getDate()
      var hour = newdate.getHours() < 10 ? '0' + newdate.getHours() : newdate.getHours()
      var minute = newdate.getMinutes() < 10 ? '0' + newdate.getMinutes() : newdate.getMinutes()
      var currentTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
      return currentTime
    },
    getformimg () {
      // let dom = this.$refs.table
      // window.scrollTo(0, 0)
      // if (this.dradio == '1') {
      //   // 导出pdf
      //   this.tabletc = true
      //   setTimeout(() => {
      //     html2Canvas(dom, {
      //       backgroundColor: null,
      //       allowTaint: true
      //     }).then(canvas => {
      //       let contentWidth = canvas.width
      //       let contentHeight = canvas.height
      //       let pageHeight = (contentWidth / 592.28) * 841.89
      //       let leftHeight = contentHeight
      //       let position = 0
      //       let imgWidth = 595.28
      //       let imgHeight = (592.28 / contentWidth) * contentHeight
      //       let pageData = canvas.toDataURL('image/jpeg', 1.0)
      //       this.tabletc = false
      //       console.log(pageData)
      //       let PDF = new JsPDF('', 'pt', 'a4')
      //       if (leftHeight < pageHeight) {
      //         PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      //       } else {
      //         while (leftHeight > 0) {
      //           PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
      //           leftHeight -= pageHeight
      //           position -= 841.89
      //           if (leftHeight > 0) {
      //             PDF.addPage()
      //           }
      //         }
      //       }
      //       // PDF.addImage(pageData, 'JPEG', 0, 0, 595.28, 592.28 / canvas.width * canvas.height);
      //       PDF.save(this.title + '人员名单.pdf')
      //     })
      //   }, 500)
      //   // -->
      // } else {
      // 导出excel
      this.btn_export()
      // -->
      // }
    },
    btn_export () {
      var table1 = document.querySelector('#table1')
      var sheet = XLSX.utils.table_to_sheet(table1) // 将一个table对象转换成一个sheet对象
      this.openDownloadDialog(this.sheet2blob(sheet), this.title + '活动人员.xlsx')
    },
    openDownloadDialog (url, saveName) {
      if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url) // 创建blob地址
      }
      var aLink = document.createElement('a')
      aLink.href = url
      aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
      var event
      if (window.MouseEvent) event = new MouseEvent('click')
      else {
        event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      }
      aLink.dispatchEvent(event)
    },
    sheet2blob (sheet, sheetName) {
      sheetName = sheetName || 'sheet1'
      var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
      }
      workbook.Sheets[sheetName] = sheet // 生成excel的配置项

      var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
      }
      var wbout = XLSX.write(workbook, wopts)
      var blob = new Blob([s2ab(wbout)], {
        type: 'application/octet-stream'
      }) // 字符串转ArrayBuffer
      function s2ab (s) {
        var buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
        return buf
      }
      return blob
    },
    importf (obj) {
      var rABS = false
      let file = obj.target
      console.log(obj)
      // 导入
      if (!file.files) {
        return
      }
      var f = file.files[0]
      this.filename = file.files[0].name
      {
        var reader = new FileReader()
        var name = f.name
        const _this = this
        reader.onload = function (e) {
          var data = e.target.result
          var wb
          if (rABS) {
            wb = XLSX.read(data, { type: 'binary' })
          } else {
            var arr = _this.fixdata(data)
            wb = XLSX.read(btoa(arr), { type: 'base64' })
          }
          console.log(XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]))
        }
        if (rABS) reader.readAsBinaryString(f)
        else reader.readAsArrayBuffer(f)
      }
    },
    fixdata (data) {
      var o = ''
      var l = 0
      var w = 10240
      for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
      return o
    },
    changeval (e) {
      let _list = []
      if (this.seloptsval == '全部状态') {
        _list = this.tableData2
      } else {
        for (let i = 0, len = this.tableData2.length; i < len; i++) {
          if (this.typestatus(this.tableData2[i].A) == this.seloptsval) {
            _list.push(this.tableData2[i])
          }
        }
      }
      this.tableData = _list
    },
    pass () {
      if (this.passlist.length == 0) {
        this.$message({
          type: 'error',
          message: '用户数不能为0'
        })
      } else {
        let datalist = ''
        for (let i = 0, len = this.passlist.length; i < len; i++) {
          datalist += `${this.passlist[i].MAKEACTIVITYMX_ID},`
        }
        let datas = {
          // A: '1',
          DATA_IDS: datalist.slice(0, datalist.length - 1)
        }
        examine(qs.stringify(datas)).then(res => {
          if (res.data.list[0].msg == 'ok') {
            this.$message({
              type: 'success',
              message: '操作成功！',
              center: true
            })
            this.reload()
          } else {
            this.$message({
              type: 'error',
              message: '操作失败，请刷新页面后重试！',
              center: true
            })
          }
        })
      }
    },
    nopass () {
      if (this.passlist.length == 0) {
        this.$message({
          type: 'error',
          message: '用户数不能为0',
          center: true
        })
      } else {
        let datalist = ''
        for (let i = 0, len = this.passlist.length; i < len; i++) {
          datalist += `${this.passlist[i].MAKEACTIVITYMX_ID},`
        }
        // let nopassstr = this.nopasstext
        let datas = {
          // A: '3',
          // B: nopassstr,
          DATA_IDS: datalist.slice(0, datalist.length - 1)
        }
        noexamine(qs.stringify(datas)).then(res => {
          if (res.data.list[0].msg == 'ok') {
            this.$message({
              type: 'success',
              message: '操作成功！',
              center: true
            })
            this.reload()
          } else {
            this.$message({
              type: 'error',
              message: '操作失败，请刷新页面后重试！',
              center: true
            })
          }
        })
      }
    },
    disqualification () {
      if (this.passlist.length == 0) {
        this.$message({
          type: 'error',
          message: '用户数不能为0',
          center: true
        })
      } else {
        let datalist = ''
        for (let i = 0, len = this.passlist.length; i < len; i++) {
          datalist += `${this.passlist[i].MAKEACTIVITYMX_ID},`
        }
        let nopassstr = this.nopasstext
        if (nopassstr == '') {
          this.$message({
            type: 'error',
            message: '取消理由不能为空',
            center: true
          })
          return false
        } else {
          let datas = {
            // A: '3',
            language: 'cn',
            contextName: nopassstr,
            DATA_IDS: datalist.slice(0, datalist.length - 1)
          }
          cancel(qs.stringify(datas)).then(res => {
            if (res.data.list[0].msg == 'ok') {
              this.$message({
                type: 'success',
                message: '操作成功！',
                center: true
              })
              this.reload()
            } else {
              this.$message({
                type: 'error',
                message: '操作失败，请刷新页面后重试！',
                center: true
              })
            }
          })
        }
      }
    },
    qesendmail () {
      if (this.passlist.length == 0) {
        this.$message({
          type: 'error',
          message: '用户数不能为0',
          center: true
        })
      } else {
        let datalist = ''
        for (let i = 0, len = this.passlist.length; i < len; i++) {
          datalist += `${this.passlist[i].MAKEACTIVITYMX_ID},`
        }
        // let nopassstr = this.nopasstext
        let cnt = this.qinp
        let datas = {
          // A: '3',
          // B: nopassstr,
          DATA_IDS: datalist.slice(0, datalist.length - 1),
          CONENT: cnt
        }
        qsendems('', qs.stringify(datas)).then(res => {
          if (res.data.list[0].msg == 'ok') {
            this.$message({
              type: 'success',
              message: '操作成功！',
              center: true
            })
            this.reload()
          } else {
            this.$message({
              type: 'error',
              message: '操作失败，请刷新页面后重试！',
              center: true
            })
          }
        })
      }
    },
    setup () {
      if (this.passlist.length == 0) {
        this.$message({
          type: 'error',
          message: '用户数不能为0',
          center: true
        })
      }
      //  else if (this.passlist.length >= 2) {
      //   this.$message({
      //     type: 'error',
      //     message: '用户数只能选择一个',
      //     center: true
      //   })
      // }
       else if (this.remaskinp == '') {
        this.$message({
          type: 'error',
          message: '请输入备注',
          center: true
        })
      } else {
        let datalist = ''
        for (let i = 0, len = this.passlist.length; i < len; i++) {
          datalist += `${this.passlist[i].MAKEACTIVITYMX_ID},`
        }
        // let nopassstr = this.nopasstext
        let cnt = this.remaskinp
        let datas = {
          // A: '3',
          // B: nopassstr,
          DATA_IDS: datalist.slice(0, datalist.length - 1),
          TEN: cnt
        }
        shsponsorsedit(qs.stringify(datas)).then(res => {
          if (res.data.list[0].msg == 'ok') {
            this.$message({
              type: 'success',
              message: '操作成功！',
              center: true
            })
            this.reload()
          } else {
            this.$message({
              type: 'error',
              message: '操作失败，请刷新页面后重试！',
              center: true
            })
          }
        })
      }
    },
    search () {
      if (this.selinput == '') {
        this.$message({
          type: 'error',
          message: '请输入搜索内容！',
          center: true
        })
      } else {
        let list = {
          search: this.selinput
        }
        searchlist(qs.stringify(list)).then(res => {
          this.tableData = res.data
          this.tableData2 = res.data
        })
      }
    },
    getinptime (data) {
      let time1 = data[0]
      let time2 = data[1]
      let times = {
        time1: this.gettime(time1),
        time2: this.gettime(time2)
      }
      return times
    }
  },
  watch: {
    qradio (newValue, oldValue) {
      let datas = JSON.parse(localStorage.getItem('examine'))
      if (newValue == '1') {
        this.dateinp = false
        this.qinp = `感谢您报名“${datas.TITLE}活动”您的票码编号为$code，活动时间是${this.gettime(datas.STARTTIME)}-${this.gettime(datas.ENDTIME)},请提早半小时入场。`
      } else if (newValue == '2') {
        this.dateinp = false
        this.qinp = `很抱歉通知您，您报名的“${datas.TITLE}活动”已经取消，详情请关注活动页面 ${window.location.protocol}//${window.location.host}${window.location.pathname}#/activity?id=${
          datas.MAKEACTIVITY_ID
        }&uid=${datas.USERID}`
      } else if (newValue == '3') {
        this.dateinp = true
        this.qinp = `您报名的“${datas.TITLE}活动”原活动时间从${this.gettime(datas.STARTTIME)}-${this.gettime(datas.ENDTIME)}，更改为${this.getinptime(this.value1).time1}-${
          this.getinptime(this.value1).time2
        }，请提早半小时入场。`
      }
    },
    value1 (newValue, oldValue) {
      let datas = JSON.parse(localStorage.getItem('examine'))
      this.qinp = `您报名的“${datas.TITLE}活动”原活动时间从${this.gettime(datas.STARTTIME)}-${this.gettime(datas.ENDTIME)}，更改为${this.getinptime(newValue).time1}-${
        this.getinptime(newValue).time2
      }，请提早半小时入场。`
    },
    sortoptsval (newValue, oldValue) {
      let datas = JSON.parse(localStorage.getItem('examine'))
      let id = datas.MAKEACTIVITY_ID
      if (newValue == '按报名日期降序') {
        getactivesppx(id).then(res => {
          this.tableData = res.data
          this.tableData2 = res.data
        })
      } else if (newValue == '按报名日期升序') {
        getactivesppxs(id).then(res => {
          this.tableData = res.data
          this.tableData2 = res.data
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.main-p
  text-align left
  font-size 0.2592rem
  .tit
    font-size 0.4444rem
    color #a5cd39
    border-bottom 0.0185rem solid #a5cd39
    padding 0.3rem 0 0.3rem 0.1rem
  .main-top-sel
    padding 0.4rem 0 0 0
    .first-sel
      display inline-block
      width 26%
      p
        display inline-block
        padding 0 0.2rem
        text-align center
        width 40%
      .el-select
        padding 0 0.1rem 0 0
        border-right 0.0185rem solid #c8c8c8
        width 60%
    .second-sel
      width 60%
      display inline-block
      p
        display inline-block
        padding 0 0.2rem
        text-align center
      .secsel
        width 45%
        display inline-block
        p
          width 40%
        .el-select
          width 60%
          padding 0 0.2rem 0 0
      .sec-inp
        width 55%
        display inline-block
        .el-input
          width 70%
          padding 0 0.2rem 0 0
        button
          background #a5cd39
          color #fff
          border none
          outline none
          width 30%
          height 40px
          cursor pointer
    .back
      width 14%
      display inline-block
      text-align right
      button
        color #8d8d8d
        border none
        outline none
        background #fff
        cursor pointer
        &::before
          content ''
          display inline-block
          vertical-align middle
          width 0.2962rem
          height 0.2962rem
          background url('../../assets/images/G/back.png') no-repeat center 0 / 100% 100%
          margin 0 0.1rem 0 0
  .main-operate
    padding 0.2rem 0 0 0
    ul.operate-ul
      border 0.0185rem solid #d0d0d0
      >li
        width (100 / 6)%
        color #8d8d8d
        display inline-block
        text-align center
        padding 0.2rem 0
        position relative
        cursor pointer
        &:first-child
          background #c2c2c2
          color #fff
          i
            color #fff
          &::after
            content ''
            position absolute
            width 0
            height 0
            border-width 0.1rem
            border-color transparent transparent transparent #c2c2c2
            top 50%
            right -0.2rem
            border-style solid
            transform translateY(-50%)
        i
          font-size 0.2962rem
          vertical-align text-top
          color #a5cd39
          margin 0 0.1rem 0 0
        i.el-icon-excel
          display inline-block
          width 0.2962rem
          height 0.2962rem
          vertical-align middle
          background url('../../assets/images/G/excel.png') no-repeat center 0 / 100% 100%
        &:not(:first-child)::after
          content ''
          position absolute
          width 0.03rem
          height 80%
          background #d0d0d0
          top 50%
          right 0
          transform translateY(-50%)
        &:last-child::after
          display none
        // &:nth-child(2)
        //   &.act
        //     &::before
        //       transform rotate(180deg)
        //       top 30%
          // &::before
          //   content ''
          //   position absolute
          //   width 0
          //   height 0
          //   border-width 0.1rem
          //   border-color #707070 transparent transparent transparent
          //   top 55%
          //   right 0.2rem
          //   border-style solid
          //   transform translateY(-50%)
          //   transition all 0.2s
          .listreview
            position absolute
            width 100%
            left 0
            padding 0.2rem 0.1rem 0
            border 0.03rem solid #c8c8c8
            border-top none
            background #fff
            li
              padding 0.2rem 0
              border-top 1px solid #c8c8c8
  .roler-list
    margin 0.4rem 0 0 0
    padding 0.4rem 0 0 0
    border-top 1px dashed #a5cd39
    .list-tit
      color #474747
      font-size 0.2962rem
      text-align center
      padding 0.4rem 0
.mask
  position fixed
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  top 0
  left 0
  z-index 999
.tc
  position fixed
  top 50%
  left 50%
  transform translate(-50%, -50%)
  background #fff
  z-index 999
  width 9.8888rem
  text-align left
  font-size 0.2962rem
  padding 0.37rem
  .tit
    font-size 0.4444rem
    color #464646
    padding 0.2rem 0.2rem
    border-bottom 0.0185rem solid #d3d3d3
  .sel-tit
    color #8d8d8d
    padding 0.4rem 0.2rem 0
  .con-form
    min-height 2rem
    padding 0.2rem 0.2rem 0
    border-bottom 0.0185rem dashed #a5cd39
  .con-form2
    padding 0.2rem 0.2rem 0
    .el-radio
      display block
      padding 0.4rem 0 0 0
      font-size 0.2962rem
      color #373737
      span
        font-size 0.2592rem
        color #8d8d8d
        padding 0 0 0 0.4rem
      &:first-child
        span
          padding 0 0 0 0.7rem
  .con-form3
    padding 0.2rem 0.2rem
    border-bottom 0.0185rem dashed #a5cd39
  .tip
    padding 0.4rem 0.2rem 0
    color #8d8d8d
    span
      padding 0 0 0 0.9rem
      i
        font-size 0.2592rem
        color #a5cd39
        display inline-block
        vertical-align middle
      a
        font-size 0.2592rem
        color #373737
        text-decoration underline
  .upload
    text-align center
    padding 0.8rem 0 1rem 0
    p
      color #8d8d8d
      font-size 0.2592rem
      padding 0 0 0.2rem 0
      span
        text-decoration underline
    .upload-btn
      display inline-block
      border 0.0185rem solid #c1c1c1
      color #000
      background #fff
      padding 0.2rem 0.8rem
      outline none
      cursor pointer
      position relative
      input
        position absolute
        width 100%
        height 100%
        top 0
        left 0
        opacity 0
        cursor pointer
  .tform
    padding 0.4rem 0.2rem 0
  .dateinp
    margin 0.2rem auto 0
    display flex
    width 95%
  .inp
    width 95%
    min-height 2rem
    padding 0.2rem
    margin 0.2rem auto 0
    display block
    border 0.0185rem solid #d1d1d1
    word-break break-all
  .btns
    padding 0.6rem 0.6rem 0.4rem
    a
      display inline-block
      width 49%
      padding 0.2rem 0
      vertical-align middle
      text-align center
      color #fff
    .btn1
      background #fff
      color #000
      border 0.0185rem solid #c2c2c2
      margin 0 1% 0 0
    .btn2
      color #fff
      background #a5cd39
      border 0.0185rem solid #a5cd39
      margin 0 0 0 1%
</style>
<style lang="stylus">
.main-p{
  &.main-p2b{
    .el-input__inner{
      border-radius 0
      border 1px solid #d4d4d4
    }
    .roler-list{
      .el-table{
        .el-table__body-wrapper{
          .el-table__body{
            tbody{
              tr{
                td{
                  &:last-child{
                    .cell{
                      overflow inherit
                    }
                  }
                }
              }
            }
          }
        }
        .last-etable{

        }
        .has-gutter{
          >tr,>tr>th{
            background #eaeaea
            color #000
          }
        }
        td,th{
          text-align center
        }
        .el-more-msg{
          width 0.2962rem
          height 0.2962rem
          background url(../../assets/images/P/more-msg.png) no-repeat center 0 /100% 100%
          display inline-block
          vertical-align middle
          cursor pointer
          position relative
        }
        .moretip{
          position absolute
          background transparent
          color transparent
          top 0
          left 0
          height 100%
          width 100%
        }
        p{
          &.txt1{
            color #00b6cd
          }
          &.txt2{
            color #cbcbcb
          }
          &.txt3{
            color #ff0000
          }
          &.txt4{
            color #f3900d
          }
          &.txt5{
            color #a5cd39
          }
          &.txt6{
            color #00b6cd
          }
        }
      }
    }
    .tc{
      .tform{
        .el-form-item__label{
          color #8d8d8d
        }
        .el-input__inner{
          background #f7f9fa
        }
      }
    }
  }
}
#table1{
      border 10px solid transparent
      display inline-block
      background #fff
      overflow hidden
      tr{
        td{
          border 1px solid #000
          padding 10px
          &.txt1{
            color #00b6cd
          }
          &.txt2{
            color #cbcbcb
          }
          &.txt3{
            color #ff0000
          }
          &.txt4{
            color #f3900d
          }
          &.txt5{
            color #a5cd39
          }
          &.txt6{
            color #00b6cd
          }
        }
      }
    }
</style>
