<template>
  <div class="foot">
    <div class="content">
      <ul>
        <li>
          <a href="javascript:return false;" class="blue">了解深圳</a>
          <router-link to="/About">城市概览</router-link>
          <router-link to="/Industries">产业介绍</router-link>
          <router-link to="/Innovation">创新驱动</router-link>
          <router-link to="/Green">绿色发展</router-link>
          <router-link to="/More">了解更多</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">体验深圳</a>
          <router-link to="/JustSZ">路线介绍</router-link>
          <router-link to="/WholeCity">全域路线介绍</router-link>
          <router-link to="/CultureCreative">文创路线介绍</router-link>
          <router-link to="/EcoDiscoveryEN">生态路线介绍</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">超级话题</a>
          <router-link to="/Events">大事件</router-link>
          <router-link to="/Activities">趣活动</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">深圳生活</a>
          <router-link to="/Policies">在深创业</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">城市交流</a>
          <router-link to="/Accounts">城市号</router-link>
          <router-link to="/Friendship">鹏友圈</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">关于我们</a>
          <router-link to="/AboutUs">平台介绍</router-link>
        </li>
        <li style="width: 130px;text-align: left">
          <a href="javascript:return false;" class="green">友情链接</a>
          <a href="http://www.sz.gov.cn/cn/">深圳市人民政府</a>
          <a href="http://www.szfao.gov.cn/">深圳市外事办办公室</a>
          <a href="http://www.eyeshenzhen.com/">EYESHENZHEN</a>
        </li>
      </ul>
      <div class="beian">
        <div class="mt1"><img src="../assets/images/common/logo.png"></div>
        <!-- <div class="mt2" @click="iconBtn(4)"><img src="../assets/images/common/foot_p1.png"></div> -->
        <div class="mt3">
          <a style="color: #fff" href="http://beian.miit.gov.cn">粤ICP备17121829号</a>
        </div>
      </div>
      <div class="icon-sel-content" v-show="false">
      <ul class="sel-list-i" >
        <li><img src="../assets/images/common/wxCode.png" alt=""></li>
        <li>
          <div class="sign-txt">
            <p>请关注</p>
            <p>CITYPLUS官网微信</p>
          </div>
        </li>
      </ul>
      <ul class="sel-list-j">
        <li><img src="../assets/images/en/A/wxCode1.png" alt=""></li>
        <li>
          <div class="sign-txt">
            <p>请下载</p>
            <p>CITYPLUS APP</p>
          </div>
        </li>
      </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>

<style scoped>
.foot{width:100%; height:337px;background:url('../assets/images/common/foot_bg.jpg') center no-repeat;background-size: cover; position: relative; overflow: hidden;}
.foot .content{width:1122px; height: auto; position:relative;line-height:0px; margin:  0 auto; }
.foot .content ul{width: 860px; height:auto; overflow: hidden; margin-top: 60px; float: left;}
.foot .content ul li{min-width:60px;height: auto; float: left; margin-left:20px;}
.foot .content ul li:first-child{margin-left:0px;}
.foot .content ul li a{font-size: 14px; color: #fff; line-height: 32px; width: 100%; display: block;}
.foot .content ul li a.blue{color: #01b7cd;}
.foot .content ul li a.green{color: #a5cd39;}
.foot .content ul li a:hover{color: #cd0127;}
.foot .content ul li a:first-child:hover{color: #01b7cd;}
.foot .content .beian{width: 200px;  float: right;}
.foot .content .beian .mt1{margin-top: 47px;}
.foot .content .beian .mt2{margin-top: 47px;}
.foot .content .beian .mt3{font-size: 14px; line-height: 30px; color: #fff; margin-top: 110px;}
</style>
