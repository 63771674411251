<template>
    <div>
      <!-- 手机  头部  -->
      <ul class="wap_menu">
        <li class="wm1"><a href=""><img src="../assets/images/common/ilogo.png"></a></li>
        <li class="wm2" @click="menuBtn"><img src="../assets/images/common/top_menu.png" alt=""></li>
         <li class="wm3" @click="changeLang"><img src="../assets/images/common/top_btn_ch.png"></li>
       <li class="wm4" @click="userBtn">
         <i class="el-icon-user-solid"></i>
       </li>
      </ul>
      <!-- end 手机  头部 -->
      <!-- 手机菜单  -->
      <div class="wap_menu_content" v-show="imenuShowA">
        <div><router-link to="/EN">Home</router-link></div>
        <div class="label">
          <span @click="imainSel(1)">The CITY<i :class="imainShow===1?'is-open':''" class="el-icon-arrow-right"></i></span>
          <div v-show="imainShow===1">
            <router-link to="/AboutEN" class="min">About Shenzhen</router-link>
            <router-link to="/IndustriesEN" class="min">Industries</router-link>
            <router-link to="/InnovationEN" class="min">Innovation</router-link>
            <router-link to="/GreenEN" class="min">Green Development</router-link>
<!--            <a href="" class="min">城市人文</a>-->
            <router-link to="/MoreEN" class="min">More</router-link>
          </div>
        </div>
        <div class="label">
          <span @click="imainSel(2)">The ADVENTURE<i :class="imainShow===2?'is-open':''" class="el-icon-arrow-right"></i></span>
          <div v-show="imainShow===2">
            <router-link to="/JustSZEN" class="min">Just shenzhen</router-link>
            <router-link to="/WholeCityEN" class="min">Whole-City Discovery Route</router-link>
            <router-link to="/CultureCreativeEN" class="min">Culture & Creative Route</router-link>
            <router-link to="/EcoDiscoveryEN" class="min">Eco-Discovery Route</router-link>
          </div>
        </div>
        <div class="label"><span @click="imainSel(3)">The BUZZ<i :class="imainShow===3?'is-open':''" class="el-icon-arrow-right"></i></span>
          <div v-show="imainShow===3">
            <router-link  to="/EventsEN" class="min">The Focus</router-link>
            <router-link to="/ActivitiesEN" class="min">Activities</router-link>
          </div>
        </div>
        <div class="label"><span @click="imainSel(4)">The LIFE<i :class="imainShow===4?'is-open':''" class="el-icon-arrow-right"></i></span>
          <div v-show="imainShow===4">
            <router-link to="/PoliciesEN" class="min">Start-up Policies</router-link>
          </div>
        </div>
        <div class="label"><span @click="imainSel(5)">The NETWORK<i :class="imainShow===5?'is-open':''" class="el-icon-arrow-right"></i></span>
          <div v-show="imainShow===5">
            <router-link to="/AccountsEN" class="min">Cities</router-link>
            <router-link to="/FriendshipEN" class="min">Friendship Cities</router-link>
          </div>
        </div>
        <div class="label"><span @click="imainSel(6)">The WEBSITE<i :class="imainShow===6?'is-open':''" class="el-icon-arrow-right"></i></span>
          <div v-show="imainShow===6">
            <router-link to="/AboutUsEN" class="min">About Us</router-link>
          </div>
        </div>
<!--        <ul>-->
<!--          <li><a href=""><img src="../assets/images/common/ico_search.png">搜索网站</a></li>-->
<!--          <li><a href=""><img src="../assets/images/common/ico_iuser.png">登录注册</a></li>-->
<!--          <li><a href=""><img src="../assets/images/common/ico_iweixin.png" style="width: .53rem;">官方微信</a></li>-->
<!--          <li><a href=""><img src="../assets/images/common/ico_iapp.png">官方APP</a></li>-->
<!--        </ul>-->
      </div>
      <!-- END 手机菜单  -->
    </div>
</template>
<script>
import { circleD, cityEventById, citynews, citynew } from '../api'
export default {
  data () {
    return {
      imainShow: 0,
      imenuShowA: false,
      routeName: ''
    }
  },
  methods: {
    routeLink (lang) {
      let oppoId = ''
      let str = this.routeName.slice(0, this.routeName.length - 1)
      for (let i = 0; i < lang.length; i++) {
        if (lang[i].HIDE == 'zh') {
          oppoId = lang[i].ID
        }
      }
      if (oppoId) {
        this.$router.push({
          name: str,
          query: {
            id: oppoId
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: 'Coming Soon！',
          type: 'error',
          center: true
        })
      }
    },
    changeLang () {
      localStorage.setItem('lang', 'cn')
      this.$i18n.locale = 'cn'
      let str = this.routeName.slice(0, this.routeName.length - 1)
      if (this.routeName == 'cityE3e') {
        citynews(this.$route.query.id).then(res => {
          this.routeLink(res.data[0].language)
        })
      } else if (this.routeName == 'cityE4e') {
        circleD(this.$route.query.id).then(res => {
          this.routeLink(res.data[0].language)
        })
      } else if (this.routeName == 'cityCCe') {
        cityEventById(this.$route.query.id).then(res => {
          this.routeLink(res.data.language)
        })
      } else if (this.routeName == 'cityE5e') {
        let id = this.$route.params.newId.replace('new', '')
        let str = this.routeName.slice(0, this.routeName.length - 1)
        citynew(id).then(res => {
          let lang = res.data[0].language
          let oppoId = ''
          for (let i = 0; i < lang.length; i++) {
            if (lang[i].HIDE == 'zh') {
              oppoId = lang[i].ID
            }
          }
          if (oppoId) {
            let newId = 'new' + oppoId
            this.$router.push({
              name: str,
              params: {
                newId: newId
              }
            })
          } else {
            this.$message({
              showClose: true,
              message: 'Coming Soon！',
              type: 'error',
              center: true
            })
          }
        })
      } else {
        this.$router.push({
          name: str,
          query: this.$route.query
        })
      }
      this.reload()
    },
    imainSel (e) {
      if (this.imainShow === e) {
        this.imainShow = 0
      } else {
        this.imainShow = e
      }
    },
    menuBtn () {
      this.imenuShowA = !this.imenuShowA
    },
    userBtn () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
        this.$router.push('/LoginEN')
      } else {
        this.$router.push('/userEN')
      }
    }
  },
  mounted () {
    this.routeName = this.$route.name
  }
}
</script>

<style scoped>
.wap_menu{width: 100%;height: 1.1rem; background-color: rgba(0,0,0,0.7); position: fixed; top: 0; left: 0; z-index: 999;}
.wap_menu .wm1{width: 2.73rem; text-align: center; float: left;}
.wap_menu .wm1 img{width: 2.23rem;margin-top: .24rem;}
.wap_menu .wm3{width: .96rem; float: right;}
.wap_menu .wm3 img{width: .5rem; margin-top: .35rem;margin-right: 0.5rem}
.wap_menu .wm4{width: .96rem; float: right;}
.wap_menu .wm4 i{width: .5rem; margin-top: .35rem;margin-right: 0.4rem;font-size: 0.46rem;color: #10b7d1}
.wap_menu .wm2{    width: 0.9rem;
  height: 100%;
  float: right;
  display: flex;
  align-items: center;
}
.wap_menu .wm2 i{
  font-size: 0.6rem;
  color: #10b7d1;
}
.wap_menu .wm2 img{width: .45rem;}
.wap_banner_events{width: 100%; position: relative;}
.wap_banner_events .title{width: 2.5rem; position: absolute; top:2.18rem; left: .25rem;}
.wap_location{position:absolute; left: .25rem; bottom: 0; width: 2.6rem; height: .4rem; background-color: #0fb7d1;}
.wap_location div{width: 95%; padding-left: 5%; height: .4rem;  display: table-cell; vertical-align: middle; color: #fff; font-size: .22rem;}
.wap_menu_content{position: fixed;top: 1.1rem; left: 0; background-color: #10b7d1; width: 100%; height: 100%; overflow: hidden;  z-index: 999;padding-top: 0.8rem;}
.wap_menu_content a{color: #fff;}
.wap_menu_content div{
  padding-left: 1rem;
  font-size: .4rem;
  margin-top: .5rem;
  display: block;
  text-align: left;
}
.wap_menu_content .min{
  height: .5rem;
  margin-top: 0.2rem;
  line-height: .5rem;
  font-size: .35rem;
  display: block;
}
.wap_menu_content div.cur{background-color: #0da3ba;}
.wap_menu_content div.cur .min{display: block;}
.wap_menu_content ul{width: 9rem; height: auto; overflow: hidden; margin: .1rem auto 0;margin-top: .5rem}
.wap_menu_content ul li{width: 4.4rem; float: left; height: 1rem;}
.wap_menu_content ul li:nth-child(even){float: right;}
.wap_menu_content ul li a{width: 4.4rem; height: .94rem; display: table-cell; text-align: center; vertical-align: middle; background-color: #0da4bc; font-size: .24rem;}
.wap_menu_content ul li a img{width: .46rem; margin-right: .1rem; vertical-align: middle;}
/*.wap_menu_content div:nth-child(1){margin-top: .2rem;}*/
/*.wap_menu_content div a:nth-last-child(){ background-color: chartreuse}*/
.wap_menu_content div span i{
  float: right;
  color: #fff;
  margin-right: .6rem;
  transition: all .3s ease-out 0s;
}
.wap_menu_content .label span{
  color: #fff;
}
.wap_menu_content .is-open{transform: rotate(90deg);}
</style>
