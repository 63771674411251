import { render, staticRenderFns } from "./CityG3.vue?vue&type=template&id=b5b18c90&scoped=true&"
import script from "./CityG3.vue?vue&type=script&lang=js&"
export * from "./CityG3.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/CityGe.styl?vue&type=style&index=0&id=b5b18c90&lang=stylus&scoped=true&"
import style1 from "./CityG3.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5b18c90",
  null
  
)

export default component.exports