<template>
  <!-- 手机尾部 -->
  <div class="wap_foot">
    <div class="content">
      <div class="item" @click="imenuShow = !imenuShow">CityPlus  <i class="el-icon-arrow-right" :class="imenuShow?'is-open':''"></i>
        <transition name="menu-fade">
        <div class="footmenu-c" v-show="imenuShow">
          <router-link to="/">Home</router-link>
          <router-link to="/AboutEN">The CITY</router-link>
          <router-link to="/JustSZ">The ADVENTURE</router-link>
          <router-link to="/Events">The BUZZ</router-link>
          <router-link to="/PoliciesEN">The LIFE</router-link>
          <router-link to="/AccountsEN">The NETWORK</router-link>
          <router-link to="/AboutUsEN">The WEBSITE</router-link>
        </div>
        </transition>
      </div>
      <div  class="item" @click="ilinkShow= !ilinkShow">Links <i class="el-icon-arrow-right" :class="ilinkShow?'is-open':''"></i>
        <transition name="menu-fade">
        <div v-show="ilinkShow">
          <a href="http://www.sz.gov.cn/cn/">Shenzhen Government online</a><a href="http://www.szfao.gov.cn/">Shenzhen Foreign Affairs Office</a><a href="http://www.eyeshenzhen.com/">EYESHENZHEN</a>
        </div>
        </transition>
      </div>
      <div  class="item" @click="ilShow= !ilShow"><img width="100%" src="../assets/images/en/A/foot_p1.png" alt="">
        <transition name="menu-fade">
        <div v-show="ilShow">
          <div class="top-s-re">
<!--            <div class="item-s-img" >-->
<!--              <img width="100%" src="/img/wxCode.3cdd6879.png" alt="">-->
<!--            </div>-->
<!--            <div class="item-s-img">-->
<!--              <img width="100%" src="/img/wx.png" alt="">-->
<!--            </div>-->
          </div>
        </div>
        </transition>
      </div>
      <!-- <div  class="app">
        <img src="../assets/images/common/ifoot_p1.png">
      </div> -->
      <div  class="beian">
        <a style="color: #fff" href="http://beian.miit.gov.cn">粤ICP备17121829号</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      imenuShow: false,
      ilinkShow: false,
      ilShow: false
    }
  }
}
</script>
<style scoped>
.wap_foot{width: 100%; background-color: #142746; overflow: hidden;}
.wap_foot .is-open{transform: rotate(90deg);}
.wap_foot .content{width: 87.5%; margin: .2rem auto 0;}
.wap_foot .content .item{position: relative; border-bottom: 1px #fff solid; line-height: .8rem; font-size: .35rem; color: #fff;text-align: left;padding: .1rem 0}
.wap_foot .content .item i{position: absolute;top:0.25rem ;right:0.25rem;transition: all .3s ease-out 0s;}
.wap_foot .content .app{border-bottom: 1px #fff solid; padding: .3rem 0; text-align: center;}
.wap_foot .content .app img{width: 5.53rem; }
.wap_foot .content .beian{ text-align: right;line-height: .8rem; font-size: .26rem; color: #fff;}
.wap_foot .content .item div a{width: 100%; display: block;line-height: .6rem; font-size: .3rem; color: #fff;padding: .1rem 0;}
.wap_foot .content .footmenu-c{
  padding-left: 0.4rem;
}
.menu-fade-enter-active {
  animation: fadeInUpBig .5s;
}
.menu-fade-leave-active {
  animation: fadeInUpBig .5s reverse;
}
.item .top-s-re {
  position: relative;
}
.item .top-s-re .item-s-img {
  width: 150px;
  height: 150px;
  margin-left: 0.3rem;
  margin-bottom: 0.4rem;
}
.item .top-s-re .item-s-img:nth-child(2n) {
  position: absolute;
  right: 0.15rem;
  top: 0;
}
@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>
