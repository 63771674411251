<template>
    <div>
        <div class="baseImg"></div>
        <el-container>
            <el-header style="padding: 0" height="100%">
                <vheader class="hidden-sm-and-down"></vheader>
                <ivheadera class="hidden-md-and-up"></ivheadera>
<!--              <div class="top-img">-->
<!--                <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">-->
<!--                <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">-->
<!--              </div>-->
<!--              </div>-->
            </el-header>
            <div class="carousel hidden-sm-and-down">
              <el-carousel :height="curHeight - 60 +'px'" autoplay :interval="6500">
                <el-carousel-item v-for="(item,index) in carouselData" :key="index">
                    <div class="small">
                      <a :href="item.URL == ''?'javascript:return false;':item.URL"  target="_blank">
                        <video v-show="item.CONTENT.indexOf('mp4') != -1" width="100%" autoplay muted loop :src="item.CONTENT"></video>
                        <img ref="curImg" @load="curLoad" v-show="item.CONTENT.indexOf('mp4') == -1" width="100%" :src="item.CONTENT" alt="">
                        <div class="car-card" >
                          <div class="line" v-show="item.CTIME == 'true'">
                            <img width="100%" src="../../assets/images/HOME/cur-line.png" alt="">
                          </div>
                          <div class="car-txt" v-show="item.STEMFROM == 'true'">
                            <div class="car-txt-en" v-html="item.SHORTCONTENT">
                            </div>
                            <div class="car-txt-cn">
                              <p>{{item.TITLE}}</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="carousel hidden-md-and-up">
            <el-carousel :height="bannerHeight+'px'">
                <el-carousel-item v-for="(item,index) in carouselData" :key="index" v-if="item.CONTENT.indexOf('mp4') == -1">
                  <div class="small">
                    <a :href="item.URL == ''?'javascript:return false;':item.URL"  target="_blank">
                    <img ref="bannerHeight" @load="imgLoad"  width="100%" :src="item.IMAGE" alt="">
                      <div class="car-card">
                        <div class="line" v-show="item.CTIME == 'true'">
                          <img width="100%" src="../../assets/images/HOME/cur-line.png" alt="">
                        </div>
                        <div class="car-txt" v-show="item.STEMFROM == 'true'">
                          <div class="car-txt-en"  v-html="item.SHORTCONTENT">
                          </div>
                          <div class="car-txt-cn">
                            <p>{{item.TITLE}}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </el-carousel-item>
            </el-carousel>
          </div>
            <div class="home-content">
            <div class="adv-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title01.png" alt="">
                </div>
                <div class="adv-content">
                  <el-row type="flex">
                    <el-col :xs="24" :sm="24" :md="16">
                      <div class="adv-map">
                        <div>
                          <img class="bg-img" width="100%" height="100%" :src="require(cirSel==1?'../../assets/images/HOME/adv-map.jpg':(cirSel == 2?'../../assets/images/HOME/adv-map2.jpg':'../../assets/images/HOME/adv-map3.jpg'))" alt="">
                          <div class="title">{{cirSel==1?'深圳全域路线':(cirSel == 2?'深圳文创路线':'深圳生态路线')}}</div>
                          <div class="map-icon">
                            <div class="map1">
                              <transition name="mapIcon1-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon11.png" alt="">
                              </transition>
                              <transition name="mapIcon2-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon12.png" alt="">
                              </transition>
                              <transition name="mapIcon3-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon13.png" alt="">
                              </transition>
                              <transition name="mapIcon4-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon14.png" alt="">
                              </transition>
                              <transition name="mapIcon5-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon15.png" alt="">
                              </transition>
                              <transition name="mapIcon6-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon16.png" alt="">
                              </transition>
                              <transition name="mapIcon7-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon17.png" alt="">
                              </transition>
                              <transition name="mapIcon8-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon18.png" alt="">
                              </transition>
                              <transition name="mapIcon9-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon19.png" alt="">
                              </transition>
                            </div>
                            <div class="map2">
                              <transition name="mapIcon21-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon21.png" alt="">
                              </transition>
                              <transition name="mapIcon22-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon22.png" alt="">
                              </transition>
                              <transition name="mapIcon23-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon23.png" alt="">
                              </transition>
                              <transition name="mapIcon24-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon24.png" alt="">
                              </transition>
                              <transition name="mapIcon25-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon25.png" alt="">
                              </transition>
                              <transition name="mapIcon26-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon26.png" alt="">
                              </transition>
                              <transition name="mapIcon27-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon27.png" alt="">
                              </transition>
                              <transition name="mapIcon28-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon28.png" alt="">
                              </transition>
                              <transition name="mapIcon29-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon29.png" alt="">
                              </transition>
                              <transition name="mapIcon210-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon210.png" alt="">
                              </transition>
                              <transition name="mapIcon211-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon211.png" alt="">
                              </transition>
                              <transition name="mapIcon212-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon212.png" alt="">
                              </transition>
                              <transition name="mapIcon213-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon213.png" alt="">
                              </transition>
                              <transition name="mapIcon214-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon214.png" alt="">
                              </transition>
                              <transition name="mapIcon215-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon215.png" alt="">
                              </transition>
                            </div>
                            <div class="map3">
                              <transition name="mapIcon31-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon31.png" alt="">
                              </transition>
                              <transition name="mapIcon32-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon32.png" alt="">
                              </transition>
                              <transition name="mapIcon33-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon33.png" alt="">
                              </transition>
                              <transition name="mapIcon34-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon34.png" alt="">
                              </transition>
                              <transition name="mapIcon35-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon35.png" alt="">
                              </transition>
                              <transition name="mapIcon36-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon36.png" alt="">
                              </transition>
                              <transition name="mapIcon37-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon37.png" alt="">
                              </transition>
                              <transition name="mapIcon38-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon38.png" alt="">
                              </transition>
                              <transition name="mapIcon39-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon39.png" alt="">
                              </transition>
                              <transition name="mapIcon310-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon310.png" alt="">
                              </transition>
                              <transition name="mapIcon311-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon311.png" alt="">
                              </transition>
                              <transition name="mapIcon312-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon312.png" alt="">
                              </transition>
                            </div>
                          </div>
                          <router-link class="adv-moreBtn" :to="cirSel==1?'WholeCity':(cirSel == 2?'CultureCreative':'EcoDiscovery')">了解更多</router-link>
                        </div>
                          <span @click="cirBtn(1)" class="circle" :class="cirSel===1?'cir-active':''" style="left: 24%"></span>
                          <span @click="cirBtn(2)" class="circle" :class="cirSel===2?'cir-active':''" style="left: 49%"></span>
                          <span @click="cirBtn(3)" class="circle" :class="cirSel===3?'cir-active':''" style="left: 74%"></span>
                          <span class="map-sign" :style="'left:'+signLeft"></span>
                          <div class="icon1-txt">深圳全域路线</div>
                          <div class="icon2-txt">深圳文创路线</div>
                          <div class="icon3-txt">深圳生态路线</div>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8">
                      <div class="adv-right">
                        <img :src="adventure.TITLE" alt="">
                        <p>{{adventure.SHORTCONTENT}}</p>
                        <div class="adv-btn">
                          <img @click="popupBtn(adventure.URL)" :src="adventure.IMAGE" alt="">
                          <img @click="popupBtn(adventure.URL2)" :src="adventure.IMAGE2" alt="">
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div v-show="popupShow" class="popup-box" @click="closeBtn">
              <div class="play-box">
                <i @click="closeBtn" class="close-btn el-icon-error"></i>
                <video ref="video" controls autoplay :src="this.popupSrc"></video>
              </div>
            </div>
            <div class="popup2-box" v-show="popupShow2">
                <div class="popup-card">
                  <div class="popup-header">
                    <i @click="popup2Close" class="el-icon-close"></i>
                  </div>
                  <div class="popup-img">
                    <img width="100%" src="../../assets/images/common/heart.png" alt="">
                  </div>
                  <div class="letter-list">
                    <ul>
                      <li>
                        <div class="txt-box">
                          <div class="en-txt">Fight for all, Stand against Virus</div>
                          <div class="cn-txt">
                            同舟共济，抗击疫情
                          </div>
                        </div>
                        <div class="btn-box">
                          <div class="more-btn" @click="goEvent('http://www.cityplus.com/uploadFiles/uploadFile/48b22b0c5a164aebb92890554eb5b18f/index.html')">
                            More
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="txt-box">
                          <div class="en-txt">
                            FAQs for Foreigners during the Period of Prevention and Control on Pneumonia caused by Novel Coronavirus
                          </div>
                          <div class="cn-txt">
                            疫情防控期间外籍人士有关问题解答
                          </div>
                        </div>
                        <div class="btn-box">
                          <div class="more-btn" @click="goEvent('http://www.cityplus.com/uploadFiles/uploadFile/6dfd7e6fee6043b2a61925942b964f46/index.html')">
                            More
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="txt-box">
                          <div class="en-txt">
                            How to report and be verified to enter and exit a residential area?
                          </div>
                          <div class="cn-txt">
                            疫情防控期间外籍人士有关问题解答（第二期）
                          </div>
                        </div>
                        <div class="btn-box">
                          <div class="more-btn" @click="goEvent('http://www.cityplus.com/uploadFiles/uploadFile/98b4c6e32c6540cf866dbf2a617050be/index.html')">
                            More
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
            <div class="buzz-box">
                <div class="w">
                  <div class="header-title">
                    <img src="../../assets/images/HOME/header-title02.png" alt="">
                  </div>
                  <div class="buzz-event">
                    <div class="event-title">
                      <img src="../../assets/images/HOME/title_bigevent.png" alt="">
                    </div>
                    <div class="event-cur">
                      <el-carousel  indicator-position="none" trigger="click" :height="eventCurH+'px'">
                        <el-carousel-item v-for="(item, index) in eventData" :key="index">
                          <h3 class="small">
                            <img ref="buzzCur" @click="linkBtn(item.TOP, item.BOTTOM, item.ID, item.PATH + item.INDEXPATH)"  @load="eventLoad" width="100%" :src="item.IMAGE" alt="">
                          </h3>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                    <div class="buzz-funActive">
                      <div class="funActive-title">
                        <img src="../../assets/images/HOME/title_funactive.png" alt="">
                        <div  class="fun-btn display-none">
                          <router-link to="/create"><button class="fbtn-active">创建我的活动</button></router-link>
                          <router-link to="/activitylist"><button>查看全部活动</button></router-link>
                        </div>
                      </div>
                      <div class="funActive-card">
                        <el-row>
                          <el-carousel  trigger="hover" :height="funCurHeight+30+'px'">
                            <el-carousel-item v-for="(arr, index) in recActivity" :key="index">
                              <h3 class="small" ref="funCur">
                                <el-col :xs="24" :sm="8" :md="8" v-for="(item, index1) in arr" :key="index1">
                                  <div class="fcard-item" @click="activityBtn(item.MAKEACTIVITY_ID,item.USERID)">
                                    <el-col :xs="12" :sm="24" :md="24">
                                      <div class="over-hidden">
                                        <img class="item-img"  @load="actLoad" width="100%" :src="item.ONE" alt="">
                                      </div>
                                    </el-col>
                                    <el-col :xs="12" :sm="24" :md="24">
                                      <div class="fcard-content">
                                        <div class="fcard-txt">
                                          <p>{{item.TITLE}}</p>
                                        </div>
                                        <div class="fcard-icon">
                                          <p><i class="el-icon-time"></i><span>{{getTime(item.STARTTIME)}}</span>-<span>{{getTime(item.ENDTIME)}}</span></p>
                                          <p><i class="el-icon-location"></i>{{item.ADDRESS}}</p>
                                        </div>
                                        <div class="fcard-foot clearfloat display-none">
                                          <img :src="item.images" alt="">
                                          <span class="display-none">{{item.CNNAME == '' ? item.ENNAME : item.CNNAME }}</span>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-col>
                              </h3>
                            </el-carousel-item>
                          </el-carousel>
                        </el-row>
                      </div>
                    </div>
                  </div>
                  <div class="fun-btn sm-up-noshow">
                    <button @click="btn4phone">创建我的活动</button>
                    <router-link to="/activitylist"><button>查看全部活动</button></router-link>
                  </div>
                </div>
            </div>
            <div class="overview-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title03.png" alt="">
                </div>
                <div class="over-content">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="4" class="f-left">
                      <div class="over-sel-icon">
                        <ul>
                          <li @click="overIcon(0)" :class="overShow == 0?'over-isactive':''"><img :src="require(overShow == 0?'../../assets/images/HOME/over-icon11.png': '../../assets/images/HOME/over-icon1.png')" alt=""></li>
                          <li @click="overIcon(1)" :class="overShow == 1?'over-isactive':''"><img :src="require(overShow == 1?'../../assets/images/HOME/over-icon21.png': '../../assets/images/HOME/over-icon2.png')" alt=""></li>
                          <li @click="overIcon(2)" :class="overShow == 2?'over-isactive':''"><img :src="require(overShow == 2?'../../assets/images/HOME/over-icon31.png':'../../assets/images/HOME/over-icon3.png')" alt=""></li>
                          <li @click="overIcon(3)" :class="overShow == 3?'over-isactive':''"><img :src="require(overShow == 3?'../../assets/images/HOME/over-icon41.png':'../../assets/images/HOME/over-icon4.png')" alt=""></li>
<!--                          <li @click="overIcon(4)" :class="overShow == 4?'over-isactive':''"><img :src="require(overShow == 4?'../../assets/images/HOME/over-icon51.png':'../../assets/images/HOME/over-icon5.png')" alt=""></li>-->
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="22" class="f-right">
                      <div class="over-sel-c" v-show="overShow == 0" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[0].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              深圳<span>{{overview[0].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[0].SHORTCONTENT}}
                            </div>
                            <a :href="overview[0].STEMFROM"><button class="btn-more">了解更多</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>全市面积约</p>
                                  <p><countTo ref="countUp11" :startVal='1900' :endVal='Number(overview[0].TONE-0)' :duration='3000'></countTo>平方公里</p>
                                </li>
                                <li>
                                  <p>常住人口约</p>
                                  <p><countTo ref="countUp12" :startVal='1100' :endVal='Number(overview[0].TTWO-0)' :duration='3000'></countTo>万人</p>
                                </li>
                                <li>
                                  <p>全年全市生产总值</p>
                                  <p><countTo ref="countUp13" :startVal='1.5' :endVal='Number(overview[0].TTHREE)' :duration='3000' :decimals="Number('2')"></countTo>万亿元</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                      <div class="over-sel-c" v-show="overShow == 1" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[1].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              深圳<span>{{overview[1].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[1].SHORTCONTENT}}
                            </div>
                            <a :href="overview[1].STEMFROM"><button class="btn-more">了解更多</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>支柱产业</p>
                                  <p><countTo ref="countUp21" :startVal='0' :endVal='Number(overview[1].TONE-0)' :duration='3000'></countTo>大</p>
                                </li>
                                <li>
                                  <p>战略新兴产业</p>
                                  <p><countTo ref="countUp22" :startVal='0' :endVal='Number(overview[1].TTWO-0)' :duration='3000'></countTo>大</p>
                                </li>
                                <li>
                                  <p>未来产业</p>
                                  <p><countTo ref="countUp23" :startVal='0' :endVal='Number(overview[1].TTHREE-0)' :duration='3000'></countTo>大</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                      <div class="over-sel-c" v-show="overShow == 2" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[2].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              深圳<span>{{overview[2].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[2].SHORTCONTENT}}
                            </div>
                            <a :href="overview[2].STEMFROM"><button class="btn-more">了解更多</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>全社会投入超过</p>
                                  <p><countTo ref="countUp31" :startVal='800' :endVal='Number(overview[2].TONE-0)' :duration='3000'></countTo>亿元</p>
                                </li>
                                <li>
                                  <p>高新企业累计</p>
                                  <p><countTo ref="countUp32" :startVal='11000' :endVal='Number(overview[2].TTWO-0)' :duration='3000'></countTo>家</p>
                                </li>
                                <li>
                                  <p>占GDP比重</p>
                                  <p><countTo ref="countUp33" :startVal='30' :endVal='Number(overview[2].TTHREE)' :duration='3000' :decimals="Number('1')"></countTo>%</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                      <div class="over-sel-c" v-show="overShow == 3" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[3].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              深圳<span>{{overview[3].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[3].SHORTCONTENT}}
                            </div>
                            <a :href="overview[3].STEMFROM"><button class="btn-more">了解更多</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>森林覆盖率高达</p>
                                  <p><countTo ref="countUp41"  :startVal='35' :endVal='Number(overview[3].TONE-0)' :duration='3000' :decimals="Number('1')"></countTo>%</p>
                                </li>
                                <li>
                                  <p>PM2.5年均浓度</p>
                                  <p><countTo ref="countUp42" :startVal='20' :endVal='Number(overview[3].TTWO-0)' :duration='3000'></countTo>微克/立方米</p>
                                </li>
                                <li>
                                  <p>联合国环境保护</p>
                                  <p>全球<countTo ref="countUp43" :startVal='400' :endVal='Number(overview[3].TTHREE-0)' :duration='3000'></countTo>佳</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="network-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title04.png" alt="">
                </div>
                <div class="network-content">
                  <el-row type="flex" >
                    <el-col :xs="24" :sm="24" :md="6" class="network-left">
                      <div class="network-card">
                        <div class="title">
                          <p><countTo :startVal='0' :endVal='Number(network.TITLE)' :duration='3000'></countTo>个城市号</p>
                          <p><countTo :startVal='0' :endVal='Number(network.ONE)' :duration='3000'></countTo>个友好城市</p>
                        </div>
                        <div class="txt">
                          {{network.TWO}}
                        </div>
                        <div class="btn">
                          <router-link to="/Accounts"><button>了解城市号</button></router-link>
                          <router-link to="/Friendship"><button>了解鹏友圈</button></router-link>
                        </div>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="16" :offset="2">
                      <div class="network-map">
                        <img width="100%" height="100%" src="../../assets/images/HOME/map.png" alt="">
                        <transition name="star1-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars01.png" alt="">
                        </transition>
                        <transition name="star2-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars02.png" alt="">
                        </transition>
                        <transition name="star3-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars03.png" alt="">
                        </transition>
                        <transition name="star4-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars04.png" alt="">
                        </transition>
                        <transition name="star5-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars05.png" alt="">
                        </transition>
                        <transition name="star6-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars06.png" alt="">
                        </transition>
                        <transition name="star7-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars07.png" alt="">
                        </transition>
                        <div class="landmark">
                          <router-link to="/City?id=30f2f85a0e8b42f7aef21216f6763b31">
                            <transition name="sign1-fade">
                                <img v-show="starShow" src="../../assets/images/HOME/sign-xyt.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=19b64f2a287448079aa2245d9ae251f4">
                            <transition name="sign2-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-dld.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=b16dc5ee5c6545b4b2c3311815900578">
                            <transition name="sign3-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-benz.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=d7526a094936441895fd58b91d5a47ff">
                            <transition name="sign4-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-hy.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=6fbcef940605480aa42341a0b3f3dd74">
                            <transition name="sign5-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-plfdf.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=708d8d3b0fbc4176b1891f1e2a2318ad">
                            <transition name="sign6-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-lr.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=5260bbc7179d428a8715ebc83f1c888c">
                            <transition name="sign7-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-sj.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=0ae00e2ac7524dfdb20a48e835f14210">
                            <transition name="sign8-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-kpd.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=e34e075829ba406e84eb9253eb49df37">
                            <transition name="sign9-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-zb.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=38ffe54b00414IMAGE35595ca2bf2d9c461ac">
                            <transition name="sign10-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-sz.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/City?id=9e6a39cdf437410fae6b9d2d3d850e9b">
                            <transition name="sign11-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-blsb.png" alt="">
                            </transition>
                          </router-link>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="city-list">
                  <div class="accounts-box">
                    <div class="title">
                      城市号
                    </div>
                    <div class="accounts-list">
                       <el-carousel :autoplay="false" type="card" :height="accountsCurH +'px'" arrow="always" trigger="click" indicator-position="none">
                          <el-carousel-item v-for="(item,index) in accountsList" :key="index">
                            <div class="item" ref="accountsH">
                              <ul>
                                <li>
                                  <a :href="'/City?id='+ item.SEECITYNUM_ID">
                                  <div class="img-cover">
                                    <img @load="accountsLoad" :src="item.IMAGE" alt="">
                                   </div>
                                  <div class="city-name">
                                    {{item.CITYNAME}}
                                  </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </el-carousel-item>
                       </el-carousel>
                    </div>
                  </div>
                  <div class="friendship-box">
                    <div class="title">
                      友好城市
                    </div>
                    <div class="friendship-list">
                       <el-carousel :height="friendshipCurH + 'px'" type="card" arrow="always" :autoplay="false" trigger="click" indicator-position="none">
                          <el-carousel-item v-for="(item,index) in friendshipList" :key="index">
                            <div class="item" ref="friendshipH">
                              <ul>
                                <li>
                                  <a :href="'/FCdetail?id=' + item.SEEPENG_ID">
                                  <div class="img-cover">
                                    <img @load="friendshipLoad" :src="item.IMAGE" alt="">
                                  </div>
                                  <div class="city-name">
                                    <p class="name">{{item.CITYNAME}}</p>
                                    <p class="country">{{item.COUNTRY}}</p>
                                  </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </el-carousel-item>
                       </el-carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="about-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title05.png" alt="">
                </div>
                <div class="about-content">
                  <el-row type="flex" class="clearfloat">
                    <el-col class="about-left" :xs="24" :sm="24" :md="8" >
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="over-hidden">
                          <a :href="aboutUs[0].URL == ''?'javascript:return false':aboutUs[0].URL" target="_blank"><img :src="aboutUs[0].IMAGE" alt=""></a>
                        </div>
                      </el-col>
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="card">
                          <div class="title">
                            {{aboutUs[0].TITLE}}
                          </div>
                          <div class="txt">
                            {{aboutUs[0].SHORTCONTENT}}
                          </div>
                        </div>
                      </el-col>
                    </el-col>
                    <el-col class="about-center" :xs="24" :sm="24" :md="8" >
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="card">
                          <div class="title">
                            {{aboutUs[1].TITLE}}
                          </div>
                          <div class="txt">
                            {{aboutUs[1].SHORTCONTENT}}
                          </div>
                        </div>
                      </el-col>
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="over-hidden">
                          <a :href="aboutUs[1].URL == ''?'javascript:return false':aboutUs[1].URL" target="_blank"><img :src="aboutUs[1].IMAGE" alt=""></a>
                        </div>
                      </el-col>
                    </el-col>
                    <el-col class="about-right" :xs="24" :sm="24" :md="8" >
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="over-hidden">
                          <a :href="aboutUs[2].URL == ''?'javascript:return false':aboutUs[2].URL" target="_blank"><img :src="aboutUs[2].IMAGE" alt=""></a>
                        </div>
                      </el-col>
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <router-link to="/AboutUs">
                        <div class="card">
                          <div class="title">
                            {{aboutUs[2].TITLE}}
                          </div>
                          <div class="txt">
                            <img class="icon" src="../../assets/images/HOME/go-icon.png" alt="">
                          </div>
                        </div>
                        </router-link>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
            <el-footer>
              <vfooter class="hidden-sm-and-down"></vfooter>
              <ivfooter class="hidden-md-and-up"></ivfooter>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import countTo from 'vue-count-to'
import { cityHomea, circleE, cityHomeb, cityHomed, cityHomeg, cityHomef, q1Activity, cityEvent, citynap } from '../../api'
export default {
  data () {
    return {
      eventData: [],
      popupShow: false,
      popupShow2: false,
      popupSrc: '',
      curHeight: 0,
      cirSel: 1,
      signLeft: [],
      overShow: 0,
      mapIcon: false,
      starShow: false,
      adventure: {},
      overview: [{},{},{},{}],
      network: {},
      aboutUs: [{},{},{},{}],
      bannerHeight: '',
      funCurHeight: '',
      eventCurH: '',
      accountsCurH: '',
      friendshipCurH: '',
      carouselData: {},
      recActivity: [],
      accountsList: [],
      friendshipList: []
    }
  },
  methods: {
    moreBtn (id) {
      let newId = 'new' + id
      this.$router.push({ name: `cityE5`, params: { newId: newId } })
    },
    goEvent (link) {
      window.open(link)
    },
    popup2Close () {
      this.popupShow2 = false
    },
    btn4phone () {
      this.$message({
        type: 'info',
        message: '请到PC端发起活动。',
        center: true
      })
    },
    linkBtn (top, bottom, id, src) {
      if (top == 'false' && bottom == 'false') {
        window.open(src)
      } else {
        const { href } = this.$router.resolve({
          name: 'cityCC',
          query: {
            id: id
          }
        })
        window.open(href, '_blank')
      }
    },
    popupBtn (src) {
      this.popupShow = true
      this.popupSrc = src
    },
    closeBtn () {
      this.popupShow = false
      this.$refs.video.pause()
    },
    getTime (timeDate) {
      var date = new Date(timeDate.replace(/-/g, '/'))
      // var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var currentTime = month + '月' + day + '日  ' + hour + ':' + minute
      return currentTime
    },
    activityBtn (e, uid) {
      this.$router.push({
        name: 'cityQ5',
        query: {
          id: e,
          uid: uid
        }
      })
    },
    cirBtn (e) {
      this.cirSel = e
      if (e === 1) {
        this.signLeft = '23.2%'
      } else if (e === 2) {
        this.signLeft = '48.2%'
      } else {
        this.signLeft = '73.2%'
      }
    },
    overIcon (e) {
      this.overShow = e
      if (e === 0) {
        this.$refs.countUp11.start()
        this.$refs.countUp12.start()
        this.$refs.countUp13.start()
      } else if (e === 1) {
        this.$refs.countUp21.start()
        this.$refs.countUp22.start()
        this.$refs.countUp23.start()
      } else if (e === 2) {
        this.$refs.countUp31.start()
        this.$refs.countUp32.start()
        this.$refs.countUp33.start()
      } else if (e === 3) {
        this.$refs.countUp41.start()
        this.$refs.countUp42.start()
        this.$refs.countUp43.start()
      }
    },
    getElementTopLeft (obj) {
      var top = 0
      var left = 0
      while (obj) {
        top += obj.offsetTop
        left += obj.offsetLeft
        obj = obj.offsetParent
      }
      return {
        top: top,
        left: left
      }
    },
    pageScroll () {
      let mapObj = document.getElementsByClassName('adv-map')[0]
      let netmapObj = document.getElementsByClassName('network-map')[0]
      if (this.getElementTopLeft(mapObj).top + mapObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight > this.getElementTopLeft(mapObj).top) {
        // console.log(1)
        this.mapIcon = true
      } else {
        // console.log(2)
        this.mapIcon = false
      }
      if (this.getElementTopLeft(netmapObj).top + netmapObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight - 450 > this.getElementTopLeft(netmapObj).top) {
        this.starShow = true
      }
      if (this.getElementTopLeft(netmapObj).top + netmapObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight < this.getElementTopLeft(netmapObj).top) {
        this.starShow = false
      }
    },
    curLoad () {
      this.$nextTick(() => {
        this.curHeight = this.$refs.curImg[1].height
        // console.log(this.$refs.curImg[1].height)
      })
    },
    imgLoad () {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[1].height
        // console.log(this.$refs.bannerHeight[1])
      })
    },
    eventLoad () {
      this.$nextTick(() => {
        this.eventCurH = this.$refs.buzzCur[0].height
        // this.funCurHeight = this.$refs.funCur.clientHeight
        // console.log(this.$refs.buzzCur.height)
        // console.log(this.$refs.funCur.clientHeight)
      })
    },
    actLoad () {
      this.$nextTick(() => {
        this.funCurHeight = this.$refs.funCur[0].clientHeight
      })
    },
    accountsLoad () {
      this.$nextTick(() => {
        this.accountsCurH = this.$refs.accountsH[0].clientHeight
      })
    },
    friendshipLoad () {
      this.$nextTick(() => {
        this.friendshipCurH = this.$refs.friendshipH[0].clientHeight
      })
    }
  },
  components: {
    countTo
  },
  destroyed() {
    window.removeEventListener('scroll', this.pageScroll)
  },
  mounted () {
    cityHomed().then(res => {
      this.overview = res.data
      // console.log(this.overview)
    })
    this.popupShow2 = true
    cityEvent().then(res => {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].RE == 'true') {
          this.eventData.push(res.data[i])
        }
      }
    })
    cityHomea().then(res => {
      this.carouselData = res.data
      // console.log(this.carouselData)
    })
    cityHomeb().then(res => {
      this.adventure = res.data[0]
      // console.log(this.adventure)
    })
    cityHomef().then(res => {
      this.network = res.data[0]
      // console.log(this.network)
    })
    cityHomeg().then(res => {
      this.aboutUs = res.data
      // console.log(this.aboutUs)
    })
    q1Activity().then((res) => {
      let result = []
      let result1 = []
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].RECOMMEND == 'true') {
          if (res.data[i].是否显示 == 'true') {
            result.push(res.data[i])
          }
        }
      }
      for (let i = 0; i < result.length; i += 3) {
        result1.push(result.slice(i, i + 3))
      }
      this.recActivity = result1
      // console.log(this.recActivity)
      // this.funCurHeight = this.$refs.funCur.clientHeight
      // console.log(this.$refs.funCur)
    })
    citynap().then((res) => {
      // let result = []
      // if (document.body.scrollWidth <= 768){
      //   for(let i = 0; i < res.data.length; i += 1) {
      //     result.push(res.data.slice(i,i + 1))
      //   }
      // } else {
      //   for(let i = 0; i < res.data.length; i += 3) {
      //     result.push(res.data.slice(i,i + 3))
      //   }
      // }
      this.accountsList = res.data
    })
    circleE().then(res => {
      // let result = []
      //  if (document.body.scrollWidth <= 768){
      //   for(let i = 0; i < res.data.length; i += 2) {
      //     result.push(res.data.slice(i,i + 2))
      //   }
      // } else {
      //   for(let i = 0; i < res.data.length; i += 4) {
      //     result.push(res.data.slice(i,i + 4))
      //   }
      // }
      this.friendshipList = res.data
    })
    // const that = this
    // this.$nextTick(() => {
    //   that.curHeight = window.innerHeight || document.documentElement.clientHeight
    // })
    // this.imgLoad()
    // this.eventLoad()
    // this.curLoad()
    window.addEventListener('scroll', this.pageScroll)
    window.addEventListener('resize', () => {
      this.bannerHeight = this.$refs.bannerHeight[1].height
      this.imgLoad()
      this.eventCurH = this.$refs.buzzCur[0].height
      this.eventLoad()
      this.curHeight = this.$refs.curImg[1].height
      this.curLoad()
      this.funCurHeight = this.$refs.funCur[0].clientHeight
      this.accountsCurH = this.$refs.accountsH[0].clientHeight
      this.accountsLoad()
      this.friendshipCurH = this.$refs.friendshipH[0].clientHeight
      this.friendshipLoad()
      // this.actLoad()
      // this.$forceUpdate()
    }, false)
  }
}
</script>
<style lang="less" scoped src="../../assets/css/City.less">
</style>
<style>
.city-list .el-carousel__arrow{
  background-color: rgba(31,45,61,.5);
}
</style>
<style scoped lang="less">
.home-content .popup-box{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 555;
  background: rgba(0,0,0,0.8);
  .play-box{
    position: relative;
    img{
      width: 9rem;
    }
    video{
      width: 13rem;
    }
    .close-btn{
      display: block;
      text-align: right;
      font-size: .5rem;
      cursor: pointer;
      z-index: 666;
      color: #0fb7d1;
      margin-right: -0.5rem;
    }
  }
}
@media screen and (max-width: 760px) {
  .home-content{
    .popup-box .play-box video{
      width : 8.5rem;
    }
  }
}
</style>
