<template>
  <div class="ebezE">
    <el-container>
      <el-header  height="10%">
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
        <Affix>
          <span class="demo-affix">
            <div class="ress">
              <!-- <video :src="contt[0]" autoplay controls width="30%"></video> -->
              <video autoplay muted loop class="bg-a" :src="contt" alt=""></video>
              <div class="top-s">
                <div class="top-right">
                  <img :src="news.NationalIMAGE" alt="">
                  <div class="top-r-r">
                    <h3>{{news.CITYNAME}}</h3>
                    <p>{{news.SHORTCONTENT}}</p>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </Affix>
      </el-header>
      <el-main class="eaec">
        <el-row type="flex" justify="center">
          <el-col class="emains" :xs="24" :sm="24" :md="20" :lg="20">
            <div class="w miec">
              <div class="ec-top-foot">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item ><router-link to="/EN">>Home</router-link></el-breadcrumb-item>
                  <el-breadcrumb-item to="/AccountsEN">Cities</el-breadcrumb-item>
                  <el-breadcrumb-item>{{news.CITYNAME}}</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <div class="bg-b-i">
                <img class="bg-b" src="../../assets/images/en/A/h-bga.png" alt>
              </div>
              <div class="hea" v-html="msg.ABOUT"></div>
              <!-- 板块插入开始 ***********************************************************************************-->
              <city-plates :id="cityidc"></city-plates>
              <!-- 板块插入结束 ***********************************************************************************-->
              <div class="s-main-b">
                  <div class="bg-b-i">
                      <img class="bg-b" src="../../assets/images/en/A/h-bgb.png" alt="">
                  </div>
                  <!-- <el-carousel indicator-position="outside" class="ind">
                     <el-carousel-item v-for="(item, index) in midea" :key="index">
                        <h3>
                          <video class="hidden-sm-and-down" width="100%"  autoplay muted loop :src="item" poster="../../assets/images/cityplus.jpg"></video>
                          <video class="hidden-md-and-up" width="100%" controls preload muted loop :src="item" poster="../../assets/images/cityplus.jpg"></video>
                        </h3>
                     </el-carousel-item>
                  </el-carousel> -->
                  <el-carousel indicator-position="outside" class="ind d2" :autoplay="false">
                    <el-carousel-item v-for="(item, index) in midea" :key="index">
                      <h3>
                        <video class="video hidden-sm-and-down" width="100%" muted loop :src="item.VIDEOPATH"></video>
                        <video class="hidden-md-and-up" width="100%" controls preload muted loop :src="item.VIDEOPATH" poster="../../assets/images/cityplus.jpg"></video>
                        <div class="mask hidden-sm-and-down">
                          <div class="cnts">
                            <h1>{{ index > 9 ? index + 1 : `0${index + 1}` }}</h1>
                            <h2 v-html="item.TITLE"></h2>
                            <p v-html="item.INTRODUCTION"></p>
                            <button :class="item.play ? 'play-btn pause' : 'play-btn play'" @click="play(index)">
                              <div>
                                <span></span>
                                <span></span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </h3>
                    </el-carousel-item>
                  </el-carousel>
              </div>
              <div class="s-main-c">
                  <div class="bg-b-i">
                      <img class="bg-b" src="../../assets/images/en/A/h-bgc.png" alt="">
                  </div>
                    <el-carousel :interval="4000" type="card" height="300px">
                      <el-carousel-item v-for="(item,index) in places" :key="index">
                      <!-- <el-carousel-item v-for="item in 4" :key="item"> -->
                        <h3 class="medium img-s"><img :src="item" alt=""></h3>
                      </el-carousel-item>
                    </el-carousel>
                </div>
              <div class="s-main-d" v-show="true">
                <div class="bg-b-i">
                  <img class="bg-b" src="../../assets/images/E/h-bgd-en.png" alt="">
                </div>
<!--                <div class="s-main-d-i"  @click="toxinwen(cityxinwen.SEECITYNUMMX_ID)">-->
<!--                  <div class="d-i-left" >-->
<!--                    <img class="left-i-i" :src="cityxinwen.IMAGE || ''" alt="">-->
<!--                  </div>-->
<!--                  <div class="d-i-right">-->
<!--                    <p class="d-i-b">{{ cityxinwen.TITLE }}</p>-->
<!--                    <div class="d-i-txt" v-html="cityxinwen.CONTENT"></div>-->
<!--                  </div>-->
<!--                </div>-->
                <div>
                  <el-row class="d-i-fo" type="flex" justify="left">
                    <div class="new-item-box">
                      <el-col class="d-fo-up" :xs="24" :sm="24" :md="8" :lg="8" v-for="(item, index) in xinwen" :key="index">
                        <div @click="toxinwen(item.SEECITYNUMMX_ID)" class="new-item">
                          <el-col class="hidden-sm-and-down" :xs="12" :sm="12" :md="24" :lg="24"><img class="d-i-fo-i-i" :title="'Image Source:' + item.Picture" :src="item.IMAGE || ''" alt=""></el-col>
                          <el-col class="hidden-md-and-up d-i-fo-l" :xs="12" :sm="12" :md="24" :lg="24"><img class="d-i-fo-i-i" :title="'Image Source:' + item.Picture" :src="item.IMAGE || ''" alt=""></el-col>
                          <el-col class="hidden-sm-and-down" :xs="12" :sm="12" :md="24" :lg="24"><div class="d-i-fo-txt"><p>{{item.TITLE}}</p></div></el-col>
                          <el-col class="hidden-md-and-up d-i-fo-r" :xs="12" :sm="12" :md="24" :lg="24"><div class="di-fo-txt"><p>{{item.TITLE}}</p></div></el-col>
                        </div>
                      </el-col>
                    </div>
                  </el-row>
                </div>
              </div>
              <gotop></gotop>
            </div>
          </el-col>
        </el-row>
      </el-main>
      <el-footer style="z-index: 999;">
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Gotop from '../../components/gotop'
import CityPlates from '../../components/cityPlates'
import { citynewwen, citynews, cityvideo } from '../../api-en'
export default {
  data () {
    return {
      taDa: {},
      msg: {},
      midea: {},
      places: {},
      news: {},
      contt: {},
      cityidc: '',
      ims: [],
      xinwen: {},
      cityxinwen: {},
      msge: {}
    }
  },
  created () {
    // console.log(this.cityidc)
  },
  mounted () {
    let times = Date()
    let arr = String(times).split(' ')
    // console.log(arr)
    this.cityidc = this.$route.query.id
    window.addEventListener('scroll', this.scrollToTop)
    citynews(this.cityidc).then(res => {
      // console.log(res.data, 1)
      this.msg = res.data[0]
      // console.log(this.msg)
      // console.log(res.data[0])
      this.places = res.data[0].PLACES
      // console.log(res.data[0])
      this.contt = res.data[0].CONTENT
      // console.log(this.contt)
      // this.midea = res.data[0].MIDEA
      this.news = res.data[0]
      this.ims = this.news.IMAGE || ''
      // console.log(res.data)
      // 轮播图
      // var arr = this.midea.split('</p>')
      // // eslint-disable-next-line no-array-constructor
      // var src = new Array()
      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i].indexOf('src') !== -1) {
      //     // src.push('' + arr[i].match(/src="(\S*)"/)[1])
      //     src.push(arr[i].match(/src="(\S*)"/)[1])
      //   }
      // }
      // this.midea = src
      // console.log(this.midea)
      // 轮播图2
      // console.log(this.midea)
      var Arr = this.places.split('</p>')
      // eslint-disable-next-line no-array-constructor
      var Src = new Array()
      for (var y = 0; y < Arr.length; y++) {
        if (Arr[y].indexOf('src') !== -1) {
          Src.push(Arr[y].match(/src="(\S*)"/)[1])
        }
      }
      this.places = Src
      // console.log(this.places)
      // var Aa = this.contt.split('</p>')
      // // eslint-disable-next-line no-array-constructor
      // var Bb = new Array()
      // for (var g = 0; g < Aa.length; g++) {
      //   if (Aa[g].indexOf('src') !== -1) {
      //     Bb.push(Aa[g].match(/src="(\S*)"/)[1])
      //   }
      // }
      // this.contt = Bb
    })
    // console.log(this.places)
    // console.log(this.contt)
    // citynews(this.cityidc).then(res => {
    //   this.msg = res.data[0]
    //   // console.log(this.msg)
    //   // console.log(res.data[0])
    //   this.places = res.data[0].PLACES
    //   // console.log(res.data[0])
    //   this.contt = res.data[0].CONTENT
    //   // console.log(this.contt)
    //   this.midea = res.data[0].MIDEA
    //   this.news = res.data[0]
    //   this.ims = this.news.IMAGE
    //   // console.log(res.data)
    //   // 轮播图
    //   var arr = this.midea.split('</p>')
    //   // eslint-disable-next-line no-array-constructor
    //   var src = new Array()
    //   for (var i = 0; i < arr.length; i++) {
    //     if (arr[i].indexOf('src') !== -1) {
    //       // src.push('' + arr[i].match(/src="(\S*)"/)[1])
    //       src.push(arr[i].match(/src="(\S*)"/)[1])
    //     }
    //   }
    //   this.midea = src
    //   // console.log(this.midea)
    //   // 轮播图2
    //   // console.log(this.midea)
    //   var Arr = this.places.split('</p>')
    //   // eslint-disable-next-line no-array-constructor
    //   var Src = new Array()
    //   for (var y = 0; y < Arr.length; y++) {
    //     if (Arr[y].indexOf('src') !== -1) {
    //       Src.push(Arr[y].match(/src="(\S*)"/)[1])
    //     }
    //   }
    //   this.places = Src
    //   // console.log(this.places)
    //   var Aa = this.contt.split('</p>')
    //   // eslint-disable-next-line no-array-constructor
    //   var Bb = new Array()
    //   for (var g = 0; g < Aa.length; g++) {
    //     if (Aa[g].indexOf('src') !== -1) {
    //       Bb.push(Aa[g].match(/src="(\S*)"/)[1])
    //     }
    //   }
    //   this.contt = Bb
    //   // console.log(this.contt[0])
    // })
    citynewwen(this.cityidc).then(res => {
      this.xinwen = res.data
    })
    cityvideo(this.cityidc).then(res => {
      let list = []
      for (let i = 0, len = res.data.length; i < len; i++) {
        res.data[i].play = false
        list.push(res.data[i])
      }
      this.midea = res.data
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  components: {
    Gotop,
    CityPlates
  },
  methods: {
    toxinwen (id) {
      let newId = 'new' + id
      this.$router.push({ name: `cityE5e`, params: { newId: newId } })
      // this.$router.push({ name: `cityE5`, query: { name: this.xinwen } })
      // console.log(this.xinwen)
      // console.log(id)
    },
    play (i) {
      this.$nextTick(() => {
        let video = $('.ind.d2 .el-carousel__item')
          .eq(i)
          .find('.video')
          .get(0)
        if (video.paused) {
          video.play()
          this.midea[i].play = true
        } else {
          video.pause()
          this.midea[i].play = false
        }
      })
    }
  }
}
</script>

<style scoped src='../../assets/css/CityE3.css'></style>

<style lang="less">
.ebezE{
  .d-i-fo{
    .new-item-box{
      margin-right: -0.25rem;
      width: 100%;
      padding-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      .new-item{
        padding-right: 0.25rem;
        display: inline-block;
        width: 100%;
      }
    }
  }
  .ind {
    video {
      object-fit: cover;
    }
    &.d2 {
      position: relative;
      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .cnts {
          padding: 0.3703rem 1.2rem;
          color: #fff;
          line-height: initial;
          text-align: left;
          width: 70%;
          h1 {
            font-size: 1.3333rem;
          }
          h2 {
            font-size: 0.8148rem;
          }
          p {
            font-size: 0.2592rem;
            text-align: justify;
          }
          .play-btn {
            background: rgba(255, 255, 255, 0.8);
            padding: 0.2rem;
            outline: none;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            margin-top: 0.2rem;
            transition: all 0.2s;
            div {
              border-radius: 50%;
              padding: 0.2rem;
              background: #fff;
              width: 0.8rem;
              height: 0.8rem;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                display: inline-block;
                margin: 0 0.05rem;
              }
            }
            &.play {
              div {
                span {
                  &:nth-child(1) {
                    width: 0;
                    height: 0;
                    border-width: 0.15rem;
                    border-left-width: 0.3rem;
                    border-style: solid;
                    margin-left: 0.3rem;
                    border-color: transparent transparent transparent #000;
                  }
                  &:nth-child(2) {
                    display: none;
                    width: 0.05rem;
                    height: 0.2rem;
                    background: #000;
                  }
                }
              }
            }
            &.pause {
              div {
                span {
                  &:nth-child(1) {
                    width: 5px;
                    height: 0.3rem;
                    background: #000;
                  }
                  &:nth-child(2) {
                    width: 5px;
                    height: 0.3rem;
                    background: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .el-main {
    margin: 0;
    padding: 0;
    z-index: 12;
    margin-top: -4.5rem;
    position: relative;
  }
  .s-main-c .el-carousel--horizontal {
    max-width: 890px;
    margin: auto;
  }
  .el-carousel__item .medium {
    opacity: 1;
    height: 100%;
  }
  .el-carousel__item h3 {
    height: 100%;
  }
  .el-carousel__item h3 video {
    height: 100%;
    width: 50%;
  }
  .el-carousel__item--card.is-in-stage {
    width: 56%;
  }
  .el-carousel__item .medium {
    width: 100%;
  }
  .ind .el-carousel__container {
    height: 92%;
  }
  .ind .el-carousel__item h3 video {
    width: 100%;
  }
  .ind .el-carousel__arrow--left {
    left: 2%;
  }
  .ind .el-carousel__arrow--right {
    right: 2%;
  }
  .eaec .miec {
    position: relative;
  }
  .eaec .miec .ec-top-foot {
    position: absolute;
    top: -41px;
    left: 0;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.76rem;
    align-items: center;
  }
  .eaec .miec .ec-top-foot .el-breadcrumb {
    line-height: 0.76rem;
    padding-left: 10px;
  }
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #fff600;
  }
  .mind .s-top {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;
    justify-content: space-between;
  }
  .s-top .s-top-left,
  .s-top .s-top-right {
    flex: 49%;
    width: 100%;
    max-width: 440px;
  }
  .s-top .s-top-right img {
    width: 100%;
    height: 100%;
  }
  .s-top .s-top-left > p {
    width: 100%;
    word-wrap:break-word;
    margin-bottom: 0.5rem;
    font-size: 0.25rem;
    text-align: left;
    text-indent: 2em;
    line-height: 0.5rem;
  }
  .s-main {
    display: flex;
    max-width: 900px;
    margin: auto;
    margin-top: 0.7rem;
  }
  .s-main-i {
    position: relative;
    height: 190px;
    background: #00b6cd;
    flex: 1;
    margin-right: 0.2rem;
  }
  .s-main-i:nth-child(2n) {
    background: #a5cd39;
  }
  .s-main-i:nth-child(4) {
    margin-right: 0;
  }
  .s-main-i img {
    position: absolute;
    left: 15px;
    top: 50px;
  }
  .s-main-tx {
    position: absolute;
    top: 125px;
    left: 15px;
    color: #fff;
    font-size: 0.3rem;
    padding-bottom: 5px;
  }
  .s-main-txt {
    position: absolute;
    top: 145px;
    left: 15px;
    color: #fff;
    font-size: 0.3rem;
  }
  .s-main-b .ind {
    width: 16.6rem;
    height: 9rem;
    margin: auto;
  }
  .s-top .s-top-left > p:last-child {
    margin-bottom: 0;
  }
  .d-i-txt p {
    line-height: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
  }
  @media screen and (max-width:1200px){
    .el-main {
      margin-top: -2.5rem;
    }
    .el-carousel__item h3 video {
      height: 80%;
      width: 100%;
    }
    .emains .w .s-main-c {
      margin-top: -1rem
    }
    .s-main-b .ind {
      width: 13.6rem;
      height: 9rem;
      margin: auto;
    }
    .s-main-b .ind .el-carousel__indicators--outside {
      margin-top: -1.6rem;
    }
    .s-main-c .el-carousel .el-carousel__container {
      height: 250px !important;
    }
    .s-main-c .el-carousel .el-carousel__indicators--outside {
      margin-top: 0;
    }
    .s-top .s-top-left, .s-top .s-top-right{
      flex: 49%;
    }
    .s-top .s-top-left{
      margin-right: 0.1rem;
    }
    .s-main-c .bg-b-i .bg-b {
      margin-top: 0;
    }
  }
  @media screen and (max-width:1056px){
    .eaec .miec .ec-top-foot {
      display: none;
    }
  }
  @media screen and (max-width:767px){
    .d-i-txt p {
      -webkit-line-clamp: 3;
    }
    .el-main {
      margin: 0;
    }
    .el-carousel__item h3 video {
      height: 80%;
      width: 100%;
    }
    .s-main-b .ind .el-carousel__indicators--outside {
      margin-top: -1.6rem;
    }
    .medium img {
      width: 100%;
      height: 52%;
    }
    .s-main-b .ind {
      width: 9.4rem;
      height: 8rem;
      margin: auto;
    }
    .s-main-c .el-carousel .el-carousel__container {
      height: 150px !important;
    }
    .s-main-c .el-carousel .el-carousel__indicators--outside {
      margin-top: -0.1rem;
    }
    .s-top .s-top-left,
    .s-top .s-top-right {
      width: 100%;
      flex: 51%;
    }
    .ind .el-carousel__container {
      height: 100%;
    }
    .s-main-i img {
      position: absolute;
      left: 0.3rem;
      top: 0.5rem;
      width: 25%;
    }
    .s-main-i {
      position: relative;
      height: 3rem;
      background: #00b6cd;
      margin-bottom: 0.3rem;
      flex: 45%;
    }
    .s-main-i:nth-child(2) {
      margin-right: 0;
    }
    .s-main-tx {
      position: absolute;
      top: 1.7rem;
      left: 0.2rem;
      color: #fff;
      font-size: 0.3rem;
    }
    .s-main-txt {
      position: absolute;
      top: 2.1rem;
      left: 0.2rem;
      color: #fff;
      font-size: 0.3rem;
    }
    .emains .w .s-main-c {
      margin-top: -0.3rem
    }
    .s-top .s-top-left > p:last-child {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
