<template>
    <div>
        <div class="baseImg"></div>
        <el-container>
            <el-header style="padding: 0" height="100%">
                <vheadere class="hidden-sm-and-down"></vheadere>
                <ivheaderae class="hidden-md-and-up"></ivheaderae>
<!--              <div class="top-img">-->
<!--                <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">-->
<!--                <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">-->
<!--              </div>-->
<!--              </div>-->
            </el-header>
            <div class="carousel hidden-sm-and-down">
              <el-carousel :height="curHeight+'px'" >
                <el-carousel-item v-for="(item,index) in carouselData" :key="index">
                    <div class="small">
                      <a :href="item.URL == ''?'javascript:return false;':item.URL"  target="_blank">
                      <video v-show="item.CONTENT.indexOf('mp4') != -1" width="100%" autoplay muted loop :src="item.CONTENT"></video>
                      <img ref="curImg" @load="curLoad" v-show="item.CONTENT.indexOf('mp4') == -1" width="100%" :src="item.CONTENT" alt="">
                      <div class="car-card" >
                        <div class="line" v-show="item.CTIME == 'true'">
                          <img width="100%" src="../../assets/images/HOME/cur-line.png" alt="">
                        </div>
                        <div class="car-txt" v-show="item.STEMFROM == 'true'">
                          <div class="car-txt-en" v-html="item.SHORTCONTENT">
                          </div>
                          <div class="car-txt-cn">
                            <p>{{item.TITLE}}</p>
                          </div>
                        </div>
                      </div>
                      </a>
                    </div>
                </el-carousel-item>
<!--                <el-carousel-item>-->
<!--                  <div class="small">-->
<!--                    <img height="100%" width="100%" src="../../assets/images/HOME/car01.png" alt="">-->
<!--                    <div class="car-card">-->
<!--                      <div class="line">-->
<!--                        <img width="100%" src="../../assets/images/HOME/cur-line.png" alt="">-->
<!--                      </div>-->
<!--                      <div class="car-txt">-->
<!--                        <div class="car-txt-en">-->
<!--                          <p>HELLO!</p>-->
<!--                          <p>SHENZHEN</p>-->
<!--                        </div>-->
<!--                        <div class="car-txt-cn">-->
<!--                          <p>从这里，爱上深圳</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </el-carousel-item>-->
<!--                <el-carousel-item>-->
<!--                  <div class="small">-->
<!--                    <img height="100%" width="100%" src="../../assets/images/HOME/02.jpg" alt="">-->
<!--                    <div class="car-card">-->
<!--                      <div class="line">-->
<!--                        <img width="100%" src="../../assets/images/HOME/cur-line.png" alt="">-->
<!--                      </div>-->
<!--                      <div class="car-txt">-->
<!--                        <div class="car-txt-en">-->
<!--                          <p>HELLO!</p>-->
<!--                          <p>SHENZHEN</p>-->
<!--                        </div>-->
<!--                        <div class="car-txt-cn">-->
<!--                          <p>从这里，爱上深圳</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </el-carousel-item>-->
<!--                <el-carousel-item>-->
<!--                  <div class="small">-->
<!--                    <img height="100%" width="100%" src="../../assets/images/HOME/03.jpg" alt="">-->
<!--                    <div class="car-card">-->
<!--                      <div class="line">-->
<!--                        <img width="100%" src="../../assets/images/HOME/cur-line.png" alt="">-->
<!--                      </div>-->
<!--                      <div class="car-txt">-->
<!--                        <div class="car-txt-en">-->
<!--                          <p>HELLO!</p>-->
<!--                          <p>SHENZHEN</p>-->
<!--                        </div>-->
<!--                        <div class="car-txt-cn">-->
<!--                          <p>从这里，爱上深圳</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </el-carousel-item>-->
              </el-carousel>
            </div>
            <div class="carousel hidden-md-and-up">
            <el-carousel :height="bannerHeight+'px'">
                <el-carousel-item v-for="(item,index) in carouselData" :key="index" v-if="item.CONTENT.indexOf('mp4') == -1">
                  <div class="small">
                    <a :href="item.URL == ''?'javascript:return false;':item.URL"  target="_blank">
                    <img ref="bannerHeight" @load="imgLoad"  width="100%" :src="item.IMAGE" alt="">
                    <div class="car-card">
                      <div class="line" v-show="item.CTIME == 'true'">
                        <img width="100%" src="../../assets/images/HOME/cur-line.png" alt="">
                      </div>
                      <div class="car-txt" v-show="item.STEMFROM == 'true'">
                        <div class="car-txt-en"  v-html="item.SHORTCONTENT">
                        </div>
                        <div class="car-txt-cn">
                          <p>{{item.TITLE}}</p>
                        </div>
                      </div>
                    </div>
                    </a>
                  </div>
                </el-carousel-item>
<!--              <el-carousel-item>-->
<!--                <div class="small">-->
<!--                  <img  width="100%" src="../../assets/images/HOME/02-m.jpg" alt="">-->
<!--                </div>-->
<!--              </el-carousel-item>-->
<!--              <el-carousel-item>-->
<!--                <div class="small">-->
<!--                  <img  width="100%" src="../../assets/images/HOME/03-m.jpg" alt="">-->
<!--                </div>-->
<!--              </el-carousel-item>-->
            </el-carousel>
          </div>
            <div class="home-content en">
            <div class="adv-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title01en.png" alt="">
                </div>
                <div class="adv-content">
                  <el-row type="flex">
                    <el-col :xs="24" :sm="24" :md="16">
                      <div class="adv-map">
                        <div>
                          <img class="bg-img" width="100%" height="100%" :src="require(cirSel==1?'../../assets/images/HOME/adv-map.jpg':(cirSel == 2?'../../assets/images/HOME/adv-map2.jpg':'../../assets/images/HOME/adv-map3.jpg'))" alt="">
                          <div class="title">{{cirSel==1?'Whole-City Discovery Route':(cirSel == 2?'Culture & Creative Route':'Eco-Discovery Route')}}</div>
                          <div class="map-icon">
                            <div class="map1">
                              <transition name="mapIcon1-fade">
                                <img v-show="mapIcon&&cirSel==1" src="../../assets/images/HOME/adv-mapIcon11.png" alt="">
                              </transition>
                              <transition name="mapIcon2-fade">
                                <img v-show="mapIcon&&cirSel==1" src="../../assets/images/HOME/adv-mapIcon12.png" alt="">
                              </transition>
                              <transition name="mapIcon3-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon13.png" alt="">
                              </transition>
                              <transition name="mapIcon4-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon14.png" alt="">
                              </transition>
                              <transition name="mapIcon5-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon15.png" alt="">
                              </transition>
                              <transition name="mapIcon6-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon16.png" alt="">
                              </transition>
                              <transition name="mapIcon7-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon17.png" alt="">
                              </transition>
                              <transition name="mapIcon8-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon18.png" alt="">
                              </transition>
                              <transition name="mapIcon9-fade">
                                <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon19.png" alt="">
                              </transition>
                            </div>
                            <div class="map2">
                              <transition name="mapIcon21-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon21.png" alt="">
                              </transition>
                              <transition name="mapIcon22-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon22.png" alt="">
                              </transition>
                              <transition name="mapIcon23-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon23.png" alt="">
                              </transition>
                              <transition name="mapIcon24-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon24.png" alt="">
                              </transition>
                              <transition name="mapIcon25-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon25.png" alt="">
                              </transition>
                              <transition name="mapIcon26-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon26.png" alt="">
                              </transition>
                              <transition name="mapIcon27-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon27.png" alt="">
                              </transition>
                              <transition name="mapIcon28-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon28.png" alt="">
                              </transition>
                              <transition name="mapIcon29-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon29.png" alt="">
                              </transition>
                              <transition name="mapIcon210-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon210.png" alt="">
                              </transition>
                              <transition name="mapIcon211-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon211.png" alt="">
                              </transition>
                              <transition name="mapIcon212-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon212.png" alt="">
                              </transition>
                              <transition name="mapIcon213-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon213.png" alt="">
                              </transition>
                              <transition name="mapIcon214-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon214.png" alt="">
                              </transition>
                              <transition name="mapIcon215-fade">
                                <img v-show="mapIcon&&cirSel===2" src="../../assets/images/HOME/adv-mapIcon215.png" alt="">
                              </transition>
                            </div>
                            <div class="map3">
                              <transition name="mapIcon31-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon31.png" alt="">
                              </transition>
                              <transition name="mapIcon32-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon32.png" alt="">
                              </transition>
                              <transition name="mapIcon33-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon33.png" alt="">
                              </transition>
                              <transition name="mapIcon34-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon34.png" alt="">
                              </transition>
                              <transition name="mapIcon35-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon35.png" alt="">
                              </transition>
                              <transition name="mapIcon36-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon36.png" alt="">
                              </transition>
                              <transition name="mapIcon37-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon37.png" alt="">
                              </transition>
                              <transition name="mapIcon38-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon38.png" alt="">
                              </transition>
                              <transition name="mapIcon39-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon39.png" alt="">
                              </transition>
                              <transition name="mapIcon310-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon310.png" alt="">
                              </transition>
                              <transition name="mapIcon311-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon311.png" alt="">
                              </transition>
                              <transition name="mapIcon312-fade">
                                <img v-show="mapIcon&&cirSel===3" src="../../assets/images/HOME/adv-mapIcon312.png" alt="">
                              </transition>
                            </div>
                          </div>
                          <router-link class="adv-moreBtn" :to="cirSel==1?'WholeCityEN':(cirSel == 2?'CultureCreativeEN':'EcoDiscoveryEN')">Learn more</router-link>
                        </div>
                          <span @click="cirBtn(1)" class="circle" :class="cirSel===1?'cir-active':''" style="left: 24%"></span>
                          <span @click="cirBtn(2)" class="circle" :class="cirSel===2?'cir-active':''" style="left: 49%"></span>
                          <span @click="cirBtn(3)" class="circle" :class="cirSel===3?'cir-active':''" style="left: 74%"></span>
                          <span class="map-sign" :style="'left:'+signLeft"></span>
                          <div class="icon1-txt">
                            <p>Whole-City Discovery</p>
                            <p>Route</p>
                          </div>
                          <div class="icon2-txt">
                            <p>Culture & Creative</p>
                            <p>Route</p>
                          </div>
                          <div class="icon3-txt">
                            <p>Eco-Discovery</p>
                            <p>Route</p>
                          </div>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8">
                      <div class="adv-right">
                        <img :src="adventure.TITLE" alt="">
                        <p>{{adventure.SHORTCONTENT}}</p>
                        <div class="adv-btn">
                          <img @click="popupBtn(adventure.URL)" :src="adventure.IMAGE" alt="">
                          <img @click="popupBtn(adventure.URL2)" :src="adventure.IMAGE2" alt="">
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div v-show="popupShow" class="popup-box"  @click="closeBtn">
              <div class="play-box">
                <i @click="closeBtn" class="close-btn el-icon-error"></i>
                <video ref="video" controls autoplay :src="this.popupSrc"></video>
              </div>
            </div>
            <div class="popup2-box" v-show="popupShow2">
                <div class="popup-card">
                  <div class="popup-header">
                    <i @click="popup2Close" class="el-icon-close"></i>
                  </div>
                  <div class="popup-img">
                    <img width="100%" src="../../assets/images/common/heart.png" alt="">
                  </div>
                  <div class="letter-list">
                    <ul>
                      <li>
                        <div class="txt-box">
                          <div class="en-txt">Fight for all, Stand against Virus</div>
                          <div class="cn-txt">
                            同舟共济，抗击疫情
                          </div>
                        </div>
                        <div class="btn-box">
                          <div class="more-btn" @click="goEvent('http://www.cityplus.com/uploadFiles/uploadFile/9e72f16d2e354d2f92d36224b95f8aea/index.html')">
                            More
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="txt-box">
                          <div class="en-txt">
                            FAQs for Foreigners during the Period of Prevention and Control on Pneumonia caused by Novel Coronavirus
                          </div>
                          <div class="cn-txt">
                            疫情防控期间外籍人士有关问题解答
                          </div>
                        </div>
                        <div class="btn-box">
                          <div class="more-btn" @click="goEvent('http://www.cityplus.com/uploadFiles/uploadFile/949bf37991b944d5afd30c3ee596d7cc/index.html')">
                            More
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="txt-box">
                          <div class="en-txt">
                            How to report and be verified to enter and exit a residential area?
                          </div>
                          <div class="cn-txt">
                            疫情防控期间外籍人士有关问题解答（第二期）
                          </div>
                        </div>
                        <div class="btn-box">
                          <div class="more-btn" @click="goEvent('http://www.cityplus.com/uploadFiles/uploadFile/b3158ee4f7db4c73a74f43610b86b5c7/index.html')">
                            More
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            <div class="buzz-box">
                <div class="w">
                  <div class="header-title">
                    <img src="../../assets/images/HOME/header-title02en.png" alt="">
                  </div>
                  <div class="buzz-event">
                    <div class="event-title">
                      <img src="../../assets/images/HOME/title_bigevent-en.png" alt="">
                    </div>
                    <div class="event-cur">
                      <el-carousel  indicator-position="none" trigger="click" :height="eventCurH+'px'">
                        <el-carousel-item v-for="(item, index) in eventData" :key="index">
                          <h3 class="small">
                            <img ref="buzzCur" @click="linkBtn(item.TOP, item.BOTTOM, item.ID, item.PATH + item.INDEXPATH)"  @load="eventLoad" width="100%" :src="item.IMAGE" alt="">
                          </h3>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                    <div class="buzz-funActive">
                      <div class="funActive-title">
                        <img src="../../assets/images/HOME/title_funactive_en.png" alt="">
                        <div  class="fun-btn display-none">
                          <button  class="fbtn-active">Create Events for Free</button>
                          <router-link to="/activitylistEN"><button>View All</button></router-link>
                        </div>
                      </div>
                      <div class="funActive-card">
                        <el-row>
                          <el-carousel  trigger="hover" :height="funCurHeight+1+'px'">
                            <el-carousel-item v-for="(arr, index) in recActivity" :key="index">
                              <h3 class="small" ref="funCur">
                                <el-col :xs="24" :sm="8" :md="8" v-for="(item, index1) in arr" :key="index1">
                                  <div class="fcard-item" @click="activityBtn(item.MAKEACTIVITY_ID,item.USERID)">
                                    <el-col :xs="12" :sm="24" :md="24">
                                      <div class="over-hidden">
                                        <img class="item-img"  @load="actLoad" width="100%" :src="item.ONE" alt="">
                                      </div>
                                    </el-col>
                                    <el-col :xs="12" :sm="24" :md="24">
                                      <div class="fcard-content">
                                        <div class="fcard-txt">
                                          <p>{{item.TITLE}}</p>
                                        </div>
                                        <div class="fcard-icon">
                                          <p><i class="el-icon-time"></i><span>{{getTime(item.STARTTIME)}}</span> ~ <span>{{getTime(item.ENDTIME)}}</span></p>
                                          <p><i class="el-icon-location"></i>{{item.ADDRESS}}</p>
                                        </div>
                                        <div class="fcard-foot clearfloat display-none">
                                          <img :src="item.images" alt="">
                                          <span class="display-none">{{item.ENNAME == '' ? item.CNNAME : item.ENNAME }}</span>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-col>
                              </h3>
                            </el-carousel-item>
                          </el-carousel>
                        </el-row>
                      </div>
                    </div>
                  </div>
                  <div class="fun-btn sm-up-noshow">
                    <button @click="btn4phone">Create Events for Free</button>
                    <router-link to="/activitylistEN"><button>View All</button></router-link>
                  </div>
                </div>
            </div>
            <div class="overview-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title03en.png" alt="">
                </div>
                <div class="over-content">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="4" class="f-left">
                      <div class="over-sel-icon">
                        <ul>
                          <li @click="overIcon(0)" :class="overShow == 0?'over-isactive':''"><img :src="require(overShow == 0?'../../assets/images/HOME/over-icon11.png': '../../assets/images/HOME/over-icon1.png')" alt=""></li>
                          <li @click="overIcon(1)" :class="overShow == 1?'over-isactive':''"><img :src="require(overShow == 1?'../../assets/images/HOME/over-icon21.png': '../../assets/images/HOME/over-icon2.png')" alt=""></li>
                          <li @click="overIcon(2)" :class="overShow == 2?'over-isactive':''"><img :src="require(overShow == 2?'../../assets/images/HOME/over-icon31.png':'../../assets/images/HOME/over-icon3.png')" alt=""></li>
                          <li @click="overIcon(3)" :class="overShow == 3?'over-isactive':''"><img :src="require(overShow == 3?'../../assets/images/HOME/over-icon41.png':'../../assets/images/HOME/over-icon4.png')" alt=""></li>
<!--                          <li @click="overIcon(4)" :class="overShow == 4?'over-isactive':''"><img :src="require(overShow == 4?'../../assets/images/HOME/over-icon51.png':'../../assets/images/HOME/over-icon5.png')" alt=""></li>-->
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="22" class="f-right">
                      <div class="over-sel-c" v-show="overShow == 0" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[0].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              Shenzhen<span>{{overview[0].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[0].SHORTCONTENT}}
                            </div>
                            <a :href="overview[0].STEMFROM"><button class="btn-more">Learn more</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>Area</p>
                                  <p><countTo ref="countUp11" :startVal='1900' :endVal='Number(overview[0].TONE-0)' :duration='3000'></countTo>sq kms</p>
                                </li>
                                <li>
                                  <p>Resident Population</p>
                                  <p><countTo ref="countUp12" :startVal='1100' :endVal='Number(overview[0].TTWO-0)' :duration='3000'></countTo>million</p>
                                </li>
                                <li>
                                  <p>Annual GDP</p>
                                  <p>RMB<countTo ref="countUp13" :startVal='1.5' :endVal='Number(overview[0].TTHREE)' :duration='3000' :decimals="2"></countTo>trillion</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                      <div class="over-sel-c" v-show="overShow == 1" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[1].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              Shenzhen<span>{{overview[1].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[1].SHORTCONTENT}}
                            </div>
                            <a :href="overview[1].STEMFROM"><button class="btn-more">Learn more</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>Pillar Industries</p>
                                  <p><countTo ref="countUp21" :startVal='0' :endVal='Number(overview[1].TONE-0)' :duration='3000'></countTo>大</p>
                                </li>
                                <li>
                                  <p>Emerging Industries</p>
                                  <p><countTo ref="countUp22" :startVal='0' :endVal='Number(overview[1].TTWO-0)' :duration='3000'></countTo>大</p>
                                </li>
                                <li>
                                  <p>Future Industries</p>
                                  <p><countTo ref="countUp23" :startVal='0' :endVal='Number(overview[1].TTHREE-0)' :duration='3000'></countTo>大</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                      <div class="over-sel-c" v-show="overShow == 2" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[2].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              Shenzhen<span>{{overview[2].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[2].SHORTCONTENT}}
                            </div>
                            <a :href="overview[2].STEMFROM"><button class="btn-more">Learn more</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>Total Societal Investment</p>
                                  <p><countTo ref="countUp31" :startVal='800' :endVal='Number(overview[2].TONE-0)' :duration='3000'></countTo>billion</p>
                                </li>
                                <li>
                                  <p>Number of High-tech Enterprises</p>
                                  <p><countTo ref="countUp32" :startVal='11000' :endVal='Number(overview[2].TTWO-0)' :duration='3000'></countTo></p>
                                </li>
                                <li>
                                  <p>Percentage of GDP</p>
                                  <p><countTo ref="countUp33" :startVal='30' :endVal='Number(overview[2].TTHREE)' :duration='3000' :decimals="1"></countTo>%</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                      <div class="over-sel-c" v-show="overShow == 3" >
                        <el-col :xs="24" :sm="24" :md="14">
                          <div class="over-hidden">
                            <img width="100%" :src="overview[3].IMAGE" alt="">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="10">
                          <div class="over-r-card">
                            <div class="over-card-title">
                              Shenzhen<span>{{overview[3].TITLE}}</span>
                            </div>
                            <div class="over-card-txt">
                              {{overview[3].SHORTCONTENT}}
                            </div>
                            <a :href="overview[3].STEMFROM"><button class="btn-more">Learn more</button></a>
                            <div class="over-card-data">
                              <ul>
                                <li>
                                  <p>Forest Coverage Rate</p>
                                  <p><countTo ref="countUp41"  :startVal='35' :endVal='Number(overview[3].TONE-0)' :duration='3000' :decimals="1"></countTo>%</p>
                                </li>
                                <li>
                                  <p>Annual Concentration of PM 2.5</p>
                                  <p><countTo ref="countUp42" :startVal='20' :endVal='Number(overview[3].TTWO-0)' :duration='3000'></countTo>micrometer/m3</p>
                                </li>
                                <li>
                                  <p>UN Environmental Protection</p>
                                  <p><countTo ref="countUp43" :startVal='400' :endVal='Number(overview[3].TTHREE-0)' :duration='3000'></countTo>Globally</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </el-col>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="network-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title04en.png" alt="">
                </div>
                <div class="network-content">
                  <el-row type="flex" >
                    <el-col :xs="24" :sm="24" :md="6" class="network-left">
                      <div class="network-card">
                        <div class="title">
                          <p><countTo :startVal='0' :endVal='Number(network.TITLE)' :duration='3000'></countTo>City Accounts</p>
                          <p><countTo :startVal='0' :endVal='Number(network.ONE)' :duration='3000'></countTo>Friendship Cities</p>
                        </div>
                        <div class="txt">
                          {{network.TWO}}
                        </div>
                        <div class="btn">
                          <router-link to="/AccountsEN"><button>More about City Accounts</button></router-link>
                          <router-link to="/FriendshipEN"><button>More about the Network</button></router-link>
                        </div>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="16" :offset="2">
                      <div class="network-map en">
                        <img width="100%" height="100%" src="../../assets/images/HOME/mapen.png" alt="">
                        <transition name="star1-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars01.png" alt="">
                        </transition>
                        <transition name="star2-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars02.png" alt="">
                        </transition>
                        <transition name="star3-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars03.png" alt="">
                        </transition>
                        <transition name="star4-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars04.png" alt="">
                        </transition>
                        <transition name="star5-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars05.png" alt="">
                        </transition>
                        <transition name="star6-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars06.png" alt="">
                        </transition>
                        <transition name="star7-fade">
                          <img v-show="starShow" class="star"  width="100%" height="100%" src="../../assets/images/HOME/stars07.png" alt="">
                        </transition>
                        <div class="landmark">
                          <router-link to="/CityEN?id=ecaf5d59f0294b0e8964248f01374936">
                            <transition name="sign1-fade">
                                <img v-show="starShow" src="../../assets/images/HOME/sign-xyt-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=a82fcfc5201d4304a3c638744253775d">
                            <transition name="sign2-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-dld-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=1d4bf1eefcd9489c91f9de61f13e688d">
                            <transition name="sign3-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-benz-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=928d07724bdb4ee698600db17a75c489">
                            <transition name="sign4-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-hy-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=30e9faa2f4ea426abad5934aab9d14fd">
                            <transition name="sign5-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-plfdf-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=ae9ef7d33f3d400d9ac2ecac085211cb">
                            <transition name="sign6-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-lr-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=9656307dd2d7499c9b6e19b1df69573c">
                            <transition name="sign7-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-sj-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=49e22d11d662409aaec7ce4cfa977edc">
                            <transition name="sign8-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-kpd-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=c62d973dc2894bd4a93d32d72ca7e46f">
                            <transition name="sign9-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-zb-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=8c3b03860af84900be2ae50d48250f3a">
                            <transition name="sign10-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-sz-en.png" alt="">
                            </transition>
                          </router-link>
                          <router-link to="/CityEN?id=720ea8a1878944009e4c1c6d0c3273c0">
                            <transition name="sign11-fade">
                              <img v-show="starShow" src="../../assets/images/HOME/sign-blsb-en.png" alt="">
                            </transition>
                          </router-link>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="city-list">
                  <div class="accounts-box">
                    <div class="title">
                      City Accounts
                    </div>
                    <div class="accounts-list">
                       <el-carousel :autoplay="false" type="card" :height="accountsCurH +'px'" arrow="always" trigger="click" indicator-position="none">
                          <el-carousel-item v-for="(item,index) in accountsList" :key="index">
                            <div class="item" ref="accountsH">
                              <ul>
                                <li>
                                  <a :href="'/CityEN?id='+ item.SEECITYNUM_ID">
                                  <div class="img-cover">
                                    <img @load="accountsLoad" :src="item.IMAGE" alt="">
                                   </div>
                                  <div class="city-name">
                                    {{item.CITYNAME}}
                                  </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </el-carousel-item>
                       </el-carousel>
                    </div>
                  </div>
                  <div class="friendship-box">
                    <div class="title">
                      Friendship Cities
                    </div>
                    <div class="friendship-list">
                       <el-carousel :height="friendshipCurH + 'px'" type="card" arrow="always" :autoplay="false" trigger="click" indicator-position="none">
                          <el-carousel-item v-for="(item,index) in friendshipList" :key="index">
                            <div class="item" ref="friendshipH">
                              <ul>
                                <li>
                                  <a :href="'/FCdetailEN?id=' + item.SEEPENG_ID">
                                  <div class="img-cover">
                                    <img @load="friendshipLoad" :src="item.IMAGE" alt="">
                                  </div>
                                  <div class="city-name">
                                    <p class="name">{{item.CITYNAME}}</p>
                                    <p class="country">{{item.COUNTRY}}</p>
                                  </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </el-carousel-item>
                       </el-carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="about-box">
              <div class="w">
                <div class="header-title">
                  <img src="../../assets/images/HOME/header-title05en.png" alt="">
                </div>
                <div class="about-content">
                  <el-row type="flex" class="clearfloat">
                    <el-col class="about-left" :xs="24" :sm="24" :md="8" >
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="over-hidden">
                          <a :href="aboutUs[0].URL == ''?'javascript:return false':aboutUs[0].URL" target="_blank"><img :src="aboutUs[0].IMAGE" alt=""></a>
                        </div>
                      </el-col>
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="card">
                          <div class="title">
                            {{aboutUs[0].TITLE}}
                          </div>
                          <div class="txt">
                            {{aboutUs[0].SHORTCONTENT}}
                          </div>
                        </div>
                      </el-col>
                    </el-col>
                    <el-col class="about-center" :xs="24" :sm="24" :md="8" >
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="card">
                          <div class="title">
                            {{aboutUs[1].TITLE}}
                          </div>
                          <div class="txt">
                            {{aboutUs[1].SHORTCONTENT}}
                          </div>
                        </div>
                      </el-col>
                      <el-col class="height-half" :xs="12" :sm="12" :md="24">
                        <div class="over-hidden">
                          <a :href="aboutUs[1].URL == ''?'javascript:return false':aboutUs[1].URL" target="_blank"><img :src="aboutUs[1].IMAGE" alt=""></a>
                        </div>
                      </el-col>
                    </el-col>
                    <el-col class="about-right" :xs="24" :sm="24" :md="8" >
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <div class="over-hidden">
                          <a :href="aboutUs[2].URL == ''?'javascript:return false':aboutUs[2].URL" target="_blank"><img :src="aboutUs[2].IMAGE" alt=""></a>
                        </div>
                      </el-col>
                      <el-col class="height-half" :xs="24" :sm="24" :md="24">
                        <router-link to="/AboutUsEN">
                          <div class="card">
                            <div class="title">
                              {{aboutUs[2].TITLE}}
                            </div>
                            <div class="txt">
                              <img class="icon" src="../../assets/images/HOME/go-icon.png" alt="">
                            </div>
                          </div>
                        </router-link>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
            <el-footer>
              <vfootere class="hidden-sm-and-down"></vfootere>
              <ivfootere class="hidden-md-and-up"></ivfootere>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import countTo from 'vue-count-to'
import { cityHomea, circleE, cityHomeb, cityHomed, cityHomeg, cityHomef, cityEvent, citynap } from '../../api-en'
import { q1Activity } from '../../api'
export default {
  data () {
    return {
      eventData: [],
      popupShow: false,
      popupShow2: false,
      popupSrc: '',
      curHeight: 0,
      cirSel: 1,
      signLeft: [],
      overShow: 0,
      mapIcon: false,
      starShow: false,
      adventure: {},
      overview: {},
      network: {},
      aboutUs: {},
      bannerHeight: '',
      funCurHeight: '',
      eventCurH: '',
      accountsCurH: '',
      friendshipCurH: '',
      carouselData: {},
      recActivity: [],
      accountsList: [],
      friendshipList: []
    }
  },
  methods: {
    moreBtn (id) {
      let newId = 'new' + id
      this.$router.push({ name: `cityE5e`, params: { newId: newId } })
    },
    goEvent (link) {
      window.open(link)
    },
    popup2Close () {
      this.popupShow2 = false
    },
    btn4phone () {
      this.$message({
        type: 'info',
        message: 'Create an event on PC ',
        center: true
      })
    },
    linkBtn (top, bottom, id, src) {
      if (top == 'false' && bottom == 'false') {
        window.open(src)
      } else {
        const { href } = this.$router.resolve({
          name: 'cityCCe',
          query: {
            id: id
          }
        })
        window.open(href, '_blank')
      }
    },
    popupBtn (src) {
      this.popupShow = true
      this.popupSrc = src
    },
    closeBtn () {
      this.popupShow = false
      this.$refs.video.pause()
    },
    getTime (timeDate) {
      var date = new Date(timeDate.replace(/-/g, '/'))
      // var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var currentTime = month + '-' + day + '- ' + hour + ':' + minute
      return currentTime
    },
    activityBtn (e, uid) {
      this.$router.push({
        name: 'cityQ5e',
        query: {
          id: e,
          uid: uid
        }
      })
    },
    cirBtn (e) {
      this.cirSel = e
      if (e === 1) {
        this.signLeft = '23.2%'
      } else if (e === 2) {
        this.signLeft = '48.2%'
      } else {
        this.signLeft = '73.2%'
      }
    },
    overIcon (e) {
      this.overShow = e
      if (e === 0) {
        this.$refs.countUp11.start()
        this.$refs.countUp12.start()
        this.$refs.countUp13.start()
      } else if (e === 1) {
        this.$refs.countUp21.start()
        this.$refs.countUp22.start()
        this.$refs.countUp23.start()
      } else if (e === 2) {
        this.$refs.countUp31.start()
        this.$refs.countUp32.start()
        this.$refs.countUp33.start()
      } else if (e === 3) {
        this.$refs.countUp41.start()
        this.$refs.countUp42.start()
        this.$refs.countUp43.start()
      }
    },
    getElementTopLeft (obj) {
      var top = 0
      var left = 0
      while (obj) {
        top += obj.offsetTop
        left += obj.offsetLeft
        obj = obj.offsetParent
      }
      return {
        top: top,
        left: left
      }
    },
    pageScroll () {
      let mapObj = document.getElementsByClassName('adv-map')[0]
      let netmapObj = document.getElementsByClassName('network-map')[0]
      if (this.getElementTopLeft(mapObj).top + mapObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight > this.getElementTopLeft(mapObj).top) {
        // console.log(1)
        this.mapIcon = true
      } else {
        // console.log(2)
        this.mapIcon = false
      }
      // console.log(this.getElementTopLeft(netmapObj).top + netmapObj.clientHeight > window.pageYOffset)
      // console.log(window.pageYOffset + window.innerHeight > this.getElementTopLeft(netmapObj).top)
      if (this.getElementTopLeft(netmapObj).top + netmapObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight - 450 > this.getElementTopLeft(netmapObj).top) {
        this.starShow = true
        // console.log(11)
      }
      if (this.getElementTopLeft(netmapObj).top + netmapObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight < this.getElementTopLeft(netmapObj).top) {
        this.starShow = false
        // console.log(11)
      }
    },
    curLoad () {
      this.$nextTick(() => {
        this.curHeight = this.$refs.curImg[1].height
        // console.log(this.$refs.curImg[1].height)
      })
    },
    imgLoad () {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[1].height
        // console.log(this.$refs.bannerHeight[1])
      })
    },
    eventLoad () {
      this.$nextTick(() => {
        this.eventCurH = this.$refs.buzzCur[0].height
        // this.funCurHeight = this.$refs.funCur.clientHeight
        // console.log(this.$refs.buzzCur.height)
        // console.log(this.$refs.funCur.clientHeight)
      })
    },
    actLoad () {
      this.$nextTick(() => {
        this.funCurHeight = this.$refs.funCur[0].clientHeight
      })
    },
    accountsLoad () {
      this.$nextTick(() => {
        this.accountsCurH = this.$refs.accountsH[0].clientHeight
      })
    },
    friendshipLoad () {
      this.$nextTick(() => {
        this.friendshipCurH = this.$refs.friendshipH[0].clientHeight
      })
    }
    // eventLoad () {
    //   this.$nextTick(() => {
    //     this.eventCurH = this.$refs.buzzCur.height
    //     this.funCurHeight = this.$refs.funCur.clientHeight
    //   })
    // }
  },
  components: {
    countTo
  },
  mounted () {
    this.popupShow2 = true
    cityEvent().then(res => {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].RE == 'true') {
          this.eventData.push(res.data[i])
        }
      }
    })
    cityHomea().then(res => {
      this.carouselData = res.data
      // console.log(this.carouselData)
    })
    cityHomeb().then(res => {
      this.adventure = res.data[0]
      // console.log(this.adventure)
    })
    cityHomed().then(res => {
      this.overview = res.data
      // console.log(this.overview)
    })
    cityHomef().then(res => {
      this.network = res.data[0]
      // console.log(this.network)
    })
    cityHomeg().then(res => {
      this.aboutUs = res.data
      // console.log(this.aboutUs)
    })
    q1Activity().then((res) => {
      let result = []
      let result1 = []
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].RECOMMEND == 'true') {
          if (res.data[i].是否显示 == 'true') {
            result.push(res.data[i])
          }
        }
      }
      for (let i = 0; i < result.length; i += 3) {
        result1.push(result.slice(i, i + 3))
      }
      this.recActivity = result1
      console.log(this.recActivity)
      // this.funCurHeight = this.$refs.funCur.clientHeight
      // console.log(this.$refs.funCur)
    })
    citynap().then((res) => {
      this.accountsList = res.data
    })
    circleE().then(res => {
       this.friendshipList = res.data
    })
    // const that = this
    // this.$nextTick(() => {
    //   that.curHeight = window.innerHeight || document.documentElement.clientHeight
    // })
    // this.imgLoad()
    // this.eventLoad()
    // this.curLoad()
    window.addEventListener('scroll', this.pageScroll)
    window.addEventListener('resize', () => {
      this.bannerHeight = this.$refs.bannerHeight[1].height
      this.imgLoad()
      this.eventCurH = this.$refs.buzzCur[0].height
      this.eventLoad()
      this.curHeight = this.$refs.curImg[1].height
      this.curLoad()
      this.funCurHeight = this.$refs.funCur[0].clientHeight
      // this.actLoad()
      // this.$forceUpdate()
    }, false)
  }
}
</script>
<style lang="less" scoped src="../../assets/css/City.less">
</style>
<style>
.city-list .el-carousel__arrow{
  background-color: rgba(31,45,61,.5);
}
</style>
<style scoped lang="less">
  .home-content .adv-box .adv-map{
    .icon1-txt {
      left: 15.5%;
      bottom: 1.1%;
      line-height: 1.2;
    }
    .icon2-txt {
      left: 42.5%;
      bottom: 1.1%;
      line-height: 1.2;
    }
    .icon3-txt{
      left: 69%;
      bottom: 1.1%;
      line-height: 1.2;
    }
  }
  .home-content .network-box .network-card.en .btn button{
    font-size: 0.25rem;
    margin-top: 0.2rem;
  }
  .home-content .network-box .network-card .btn{
    position: relative;
    button{
      width: 100%;
    }
  }
  .home-content .about-box .about-content img{
    height: 100%;
  }
  .home-content .about-box .about-content .card .title{
    margin-top: .34rem;
  }
  .home-content .network-box .network-map.en .landmark a{
    &:nth-child(1) {
      width: 10.4%;
      top: 42.5%;
      left: 4.7%;
    }
    &:nth-child(2) {
      width: 11%;
      top: 35.5%;
      left: 22.2%;
    }
    &:nth-child(3) {
      width: 11%;
      top: 37.5%;
      left: 37.2%;
    }
    &:nth-child(4) {
      width: 14.5%;
      top: 30%;
      left: 46.5%;
    }
    &:nth-child(5){
      width: 12%;
      top: 36.5%;
      left: 50.5%;
    }
    &:nth-child(6){
      width: 10.5%;
      top: 33%;
      left: 36.5%;
    }
    &:nth-child(7){
      width: 12.5%;
      top: 57.5%;
      left: 51%;
    }
    &:nth-child(8) {
      width: 15.6%;
      top: 75.5%;
      left: 46.5%;
    }
    &:nth-child(9) {
      width: 12.8%;
      top: 42.5%;
      left: 80%;
    }
    &:nth-child(10){
      width: 14.5%;
      top: 50.5%;
      left: 70%;
    }
    &:nth-child(11) {
      width: 13.2%;
      top: 73.5%;
      left: 72.5%;
    }
  }
  .home-content .popup-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 555;
    background: rgba(0,0,0,0.8);
    .play-box{
      position: relative;
      img{
        width: 9rem;
      }
      video{
        width: 13rem;
      }
      .close-btn{
        display: block;
        text-align: right;
        font-size: .5rem;
        cursor: pointer;
        z-index: 666;
        color: #0fb7d1;
        margin-right: -0.5rem;
      }
    }
  }
  @media screen and (max-width: 760px) {
    .home-content{
      .popup-box .play-box video{
        width : 8.5rem;
      }
    }
    .home-content .adv-box .adv-right{
      margin-top: 0.5rem;
      img{
        width: 35%;
      }
    }
    .home-content .adv-box .adv-map .title{
      font-size: 0.3rem;
    }
    .home-content .adv-box .adv-map {
      .icon1-txt {
        font-size: 0.1rem;
        line-height: 0.9;
        left: 11.5%;
        bottom: 2.2%;
      }

      .icon2-txt {
        font-size: 0.1rem;
        line-height: 0.9;
        left: 39.5%;
        bottom: 2.2%;
      }

      .icon3-txt {
        font-size: 0.1rem;
        line-height: 0.9;
        left: 66%;
        bottom: 2.2%;
      }
    }
  }
</style>
