<template>
  <div class="header-box">
    <div class="vh-mian-nav clearfloat" >
      <div class="nav-l">
        <div class="logo">
          <img width="100%" src="../assets/images/common/nav-logo.png" alt="">
        </div>
        <div class="nav-l-list">
          <ul>
            <li><router-link style="color: #fff" to="/EN">HOME</router-link></li>
            <li @click="mainBtn(1)" @mouseenter="enter(1)" :class="mainSel===1?'isactive':''">The CITY</li>
            <li @click="mainBtn(2)" @mouseenter="enter(2)" :class="mainSel===2?'isactive':''">The ADVENTURE<span></span></li>
            <li @click="mainBtn(3)" @mouseenter="enter(3)" :class="mainSel===3?'isactive':''">The BUZZ</li>
            <li @click="mainBtn(4)" @mouseenter="enter(4)" :class="mainSel===4?'isactive':''">The LIFE</li>
            <li @click="mainBtn(5)" @mouseenter="enter(5)" :class="mainSel===5?'isactive':''">The NETWORK</li>
            <li @click="mainBtn(6)" @mouseenter="enter(6)" :class="mainSel===6?'isactive':''">The WEBSITE</li>
          </ul>
        </div>
      </div>
      <div class="nav-r">
        <ul>
<!--          <li @click="changeLang"><img src="../assets/images/common/search.png" alt=""></li>-->
          <li @click="userBtn"><img src="../assets/images/common/user.png" alt=""></li>
          <li @click="iconBtn(3)" @mouseenter="iconEnter(3)"><img :src="iconSel===3?require('../assets/images/common/wechat-h.png'):require('../assets/images/common/wechat.png')" alt=""></li>
          <li @click="iconBtn(4)" @mouseenter="iconEnter(4)"><img :src="iconSel===4?require('../assets/images/common/app-h.png'):require('../assets/images/common/app.png')" alt=""></li>
          <li @click="iconBtn(5)" @mouseenter="iconEnter(5)"><img :src="iconSel===5?require('../assets/images/common/cn-h.png'):require('../assets/images/common/cn.png')" alt=""></li>
        </ul>
      </div>
    </div>
    <div class="nav-sel-content" v-show="subShow">
      <ul class="sel-list-a" @mouseleave="leave()" v-show="mainSel===1">
        <li><router-link to="/AboutEN">About Shenzhen</router-link></li>
        <li><router-link to="/IndustriesEN">Industries</router-link></li>
        <li><router-link to="/InnovationEN">Innovation</router-link></li>
        <li><router-link to="/GreenEN">Green Development</router-link></li>
        <!-- <li><router-link to="/cityA5">城市人文</router-link></li> -->
        <li><router-link to="/MoreEN">More</router-link></li>
      </ul>
      <ul class="sel-list-b" @mouseleave="leave()" v-show="mainSel===2">
        <li><router-link to="/JustSZEN">Just shenzhen</router-link></li>
        <li><router-link to="/WholeCityEN">Whole-City Discovery Route</router-link></li>
        <li><router-link to="/CultureCreativeEN">Culture & Creative Route</router-link></li>
        <li><router-link to="/EcoDiscoveryEN">Eco-Discovery Route</router-link></li>
      </ul>
      <ul class="sel-list-c" @mouseleave="leave()" v-show="mainSel===3">
        <li><router-link to="/EventsEN">The Focus</router-link></li>
       <li><router-link to="/ActivitiesEN">Activities</router-link></li>
      </ul>
      <ul class="sel-list-d" @mouseleave="leave()" v-show="mainSel===4">
        <li><router-link to="/PoliciesEN">Start-up Policies</router-link></li>
<!--        <li><router-link to="/cityD">创业必备知识</router-link></li>-->
<!--        <li><router-link to="/cityD">税务、法务须知</router-link></li>-->
<!--        <li><router-link to="/cityD">签证选择与移民</router-link></li>-->
<!--        <li><router-link to="/cityD">公司设立指引</router-link></li>-->
      </ul>
      <ul class="sel-list-e" @mouseleave="leave()" v-show="mainSel===5">
        <li><router-link to="/AccountsEN">Cities</router-link></li>
        <li><router-link to="/FriendshipEN">Friendship Cities</router-link></li>
      </ul>
      <ul class="sel-list-f" @mouseleave="leave()" v-show="mainSel===6">
        <li><router-link to="/AboutUsEN">About Us</router-link></li>
      </ul>
    </div>
    <div class="icon-sel-content" v-show="iconShow">
      <ul class="sel-list-g" @mouseleave="leave()" v-show="iconSel===1">
        <li><img src="../assets/images/common/search.png" alt=""></li>
        <li><input type="text" placeholder="搜索... ..."></li>
        <li @click="iconShow=false"><img src="../assets/images/common/close.png" alt=""><span>关闭</span></li>
      </ul>
      <ul class="sel-list-h" @mouseleave="leave()" v-show="iconSel===2">
        <li v-show="!registerShow" class="user-l">
          <div class="ul-title clearfloat">
            <span>我已有账户</span>
            <div class="user-icon">
              <img @click="userSel=1" src="../assets/images/common/user.png" alt="">
              <img @click="userSel=2" src="../assets/images/common/wechat.png" alt="">
              <img @click="userSel=3" src="../assets/images/common/phone.png" alt="">
            </div>
          </div>
          <div class="ul-content" v-show="userSel===1">
            <div class="login">
              <span>邮箱*</span>
              <input type="text">
            </div>
            <div class="password">
              <span>密码*</span>
              <input type="password" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this)" onblur="this.v();">
            </div>
            <div class="ul-btn">
              <button>登录</button>
              <span>忘记密码？</span>
            </div>
          </div>
          <div class="ul-content" v-show="userSel===3">
            <div class="login">
              <span>手机号*</span>
              <input type="text">
            </div>
            <div class="password">
              <span>验证码*</span>
              <input type="text" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this)" onblur="this.v();">
            </div>
            <div class="ul-btn">
              <button>登录</button>
              <span>发送验证码</span>
            </div>
          </div>
          <div class="wx-code" v-show="userSel===2">
            <img src="../assets/images/common/wxCode.png" alt="">
          </div>
        </li>
        <li v-show="!registerShow" class="user-r">
          <h1>我没有账户</h1>
          <p>创建一个账号，浏览更多内容 •••</p>
          <button @click="registerShow=true">注册</button>
        </li>
        <li class="user-register" v-show="registerShow">
          <div class="register-title clearfloat">
            <span>创建一个账户</span>
            <div class="close-box" @click="registerShow=false">
              <img src="../assets/images/common/close.png" alt="">
              关闭
            </div>
          </div>
          <div class="reg-email">
            <span>邮箱*</span>
            <input type="text">
          </div>
          <div class="reg-password">
            <span>密码*</span>
            <input type="password" placeholder="输入登录密码">
          </div>
          <div class="reg-password1">
            <span>密码*</span>
            <input type="password" placeholder="再次确认密码">
          </div>
          <div>
            <button>注册</button>
          </div>
        </li>
      </ul>
      <ul class="sel-list-i" @mouseleave="leave()" v-show="iconSel===3">
        <li><img src="../assets/images/common/wxCode.png" alt=""></li>
        <li>
          <div class="sign-txt">
            <p>Pay attention to </p>
            <p>CITYPLUS website WeChat</p>
          </div>
        </li>
      </ul>
      <ul class="sel-list-j" @mouseleave="leave()" v-show="iconSel===4">
        <li><img src="../assets/images/en/A/wxCode1.png" alt=""></li>
        <li>
          <div class="sign-txt">
            <p>Please download</p>
            <p>CITYPLUS APP</p>
          </div>
        </li>
      </ul>
      <ul class="sel-list-k" @mouseleave="leave()" v-show="iconSel===5">
        <li @click="changeLang">
          <img src="../assets/images/common/cn.png" alt="">
          <span>切换至中文</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { circleD, cityEventById, citynews, citynew } from '../api'
export default {
  data () {
    return {
      mainSel: 0,
      subSel: '',
      subShow: false,
      iconSel: 0,
      iconShow: false,
      registerShow: false,
      userSel: 1,
      routeName: ''
    }
  },
  methods: {
    routeLink (lang) {
      let oppoId = ''
      let str = this.routeName.slice(0, this.routeName.length - 1)
      for (let i = 0; i < lang.length; i++) {
        if (lang[i].HIDE == 'zh') {
          oppoId = lang[i].ID
        }
      }
      if (oppoId) {
        this.$router.push({
          name: str,
          query: {
            id: oppoId
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: 'Coming Soon！',
          type: 'error',
          center: true
        })
      }
    },
    changeLang () {
      localStorage.setItem('lang', 'cn')
      this.$i18n.locale = 'cn'
      let str = this.routeName.slice(0, this.routeName.length - 1)
      if (this.routeName == 'cityE3e') {
        citynews(this.$route.query.id).then(res => {
          this.routeLink(res.data[0].language)
        })
      } else if (this.routeName == 'cityE4e') {
        circleD(this.$route.query.id).then(res => {
          this.routeLink(res.data[0].language)
        })
      } else if (this.routeName == 'cityCCe') {
        cityEventById(this.$route.query.id).then(res => {
          this.routeLink(res.data.language)
        })
      } else if (this.routeName == 'cityE5e') {
        let id = this.$route.params.newId.replace('new', '')
        let str = this.routeName.slice(0, this.routeName.length - 1)
        citynew(id).then(res => {
          let lang = res.data[0].language
          let oppoId = ''
          for (let i = 0; i < lang.length; i++) {
            if (lang[i].HIDE == 'zh') {
              oppoId = lang[i].ID
            }
          }
          if (oppoId) {
            let newId = 'new' + oppoId
            this.$router.push({
              name: str,
              params: {
                newId: newId
              }
            })
          } else {
            this.$message({
              showClose: true,
              message: 'Coming Soon！',
              type: 'error',
              center: true
            })
          }
        })
      } else {
        this.$router.push({
          name: str,
          query: this.$route.query
        })
      }
      this.reload()
    },
    enter (e) {
      if (this.iconShow) {
        this.iconShow = false
      }
      this.subShow = true
      this.mainSel = e
    },
    leave () {
      this.subShow = false
      this.iconShow = false
      this.mainSel = 0
      this.iconSel = 0
    },
    iconEnter (e) {
      if (this.subShow) {
        this.subShow = false
      }
      this.iconShow = true
      this.iconSel = e
    },
    userBtn () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
        this.$router.push('/LoginEn')
      } else {
        this.$router.push('/userEn')
      }
    },
    mainBtn (e) {
      if (this.iconShow) {
        this.iconShow = !this.iconShow
        this.iconSel = 0
      }
      if (e === this.mainSel) {
        this.subShow = false
        this.mainSel = 0
      } else {
        this.subShow = true
        this.mainSel = e
      }
    },
    iconBtn (e) {
      if (this.subShow) {
        this.subShow = !this.subShow
        this.mainSel = 0
      }
      if (e === this.iconSel) {
        this.iconShow = false
        this.iconSel = 0
      } else {
        this.iconShow = true
        this.iconSel = e
      }
    }
  },
  mounted () {
    this.routeName = this.$route.name
  }
}
</script>
<style scoped>
  .header-box{
    width: 100%;
    position: fixed;
    z-index: 999;
  }
  /*头部菜单*/
  .vh-mian-nav{
    background: rgba(0, 0, 0, 0.75);
    padding: 0 1.2rem;
  }
  .vh-mian-nav .nav-l{
    display: flex;
    float: left;
    align-items: center;
    padding: 0.1rem 0;
  }
  .vh-mian-nav .nav-r{
    float: right;
    color: #FFFFFF;
  }
  .vh-mian-nav .nav-r ul li{
    display: inline-block;
    margin: 0.5rem 0.2rem 0.3rem 0.2rem;
    cursor: pointer;
  }
  .vh-mian-nav .nav-r ul li:last-child{
    margin-right: 0;
  }
  .vh-mian-nav .nav-l-list{
    margin-top: 10px;
    margin-left: 0.85rem;
  }
  .vh-mian-nav .nav-l-list ul li{
    position: relative;
    display: inline-block;
    font-size: 0.3rem;
    padding:0.15rem 0;
    margin: 0.1rem 0.4rem;
    color: #FFF;
    cursor: pointer;
    user-select: none;
  }
  .vh-mian-nav .nav-l-list ul li span{
    position: absolute;
    top: -6px;
    right: -11px;
    height: 20px;
    width: 38px;
    background: url('../assets/images/common/hot.png') no-repeat;
    background-size: contain;
  }
  .vh-mian-nav .nav-l-list ul .isactive{
    border-bottom: 1px solid #00B6CD;
    color: #00B6CD;
  }
  .vh-mian-nav .nav-l-list ul li:hover{
    color: #00B6CD;
  }
  .nav-sel-content{
    border-top: 1px solid #145858;
    background: rgba(0, 0, 0, 0.75);
    text-align: left;
    padding: 0.05rem 0;
  }
  .nav-sel-content ul li{
    display: inline-block;
    font-size: 0.3rem;
    padding:0.1rem 0;
    margin: 0.15rem 0.4rem;
  }
  .nav-sel-content ul li:hover{
    border-bottom: 1px solid #dadada;
  }
  .nav-sel-content ul li a{
    color: #F2FBF7;
  }
  .nav-sel-content ul{
    text-align: center;
  }
  .icon-sel-content{
    border-top: 1px solid #9ec66d;
    background: rgba(0, 0, 0, 0.75);
    text-align: right;
    padding: 0.5rem 1.2rem;
  }
  .icon-sel-content .sel-list-g{
    display: inline-flex;
  }
  .icon-sel-content .sel-list-g li input{
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    font-size: 0.25rem;
  }
  .icon-sel-content ul li{
    position: relative;
    display: inline-block;
    padding: 0 0.3rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    float: right;
    cursor: pointer;
  }
  .icon-sel-content .sel-list-g li:nth-child(1):after{
    content: '';
    height: 80%;
    position: absolute;
    top: 2px;
    right: 0;
    border-right: 1px solid #737373;
  }
  .icon-sel-content .sel-list-g li:nth-child(1) img
  {
    position: absolute;
    top: 1px;
    left: -8px;
  }
  .icon-sel-content .sel-list-g li:nth-child(3) img{
    position: absolute;
    top: 4px;
  }
  .icon-sel-content .sel-list-g li:nth-child(3){
    cursor: pointer;
  }
  .icon-sel-content .sel-list-g li span{
    font-size: 0.3rem;
    padding-left: 0.55rem;
    color: #a5cd39;
  }
  .icon-sel-content .sel-list-h{
    display: inline-flex;
  }
  .icon-sel-content .sel-list-h .user-l .ul-title span{
    float: left;
    font-size: 0.3rem;
  }
  .icon-sel-content .sel-list-h .user-l .ul-title .user-icon{
    float: right;
  }
  .icon-sel-content .sel-list-h .user-l .ul-title .user-icon img{
    padding-left: 0.6rem;
    cursor: pointer;
  }
  .icon-sel-content .sel-list-h .user-l .ul-content,.ul-btn{
    margin-top: 0.5rem;
  }
  .icon-sel-content .sel-list-h .user-l{
    position: relative;
    padding: 0 1.5rem;
  }
  .icon-sel-content .sel-list-h .user-r{
    position: relative;
    padding-left: 1.5rem;
    text-align: left;
  }
  .user-l:after{
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-right: 1px solid #737373;
  }
  .user-l .ul-content input{
    border-bottom: 1px solid #737373;
    background: rgba(0, 0, 0, 0.75);
    margin-left: 0.2rem;
    width: 230px;
    color: #fff;
    font-size: 0.3rem;
  }
  .user-l .ul-content .password input{
    margin-top: 0.5rem;
  }
  .user-l .ul-content span{
    font-size: 0.3rem;
  }
  .sel-list-h button{
    font-size: 0.2rem;
    color: #adadad;
    padding: 0.1rem 0.6rem;
    background: rgba(0, 0, 0, 0.75);
    border:1px solid #737373;
    margin-right: 1rem;
    cursor: pointer;
  }
  .user-l .ul-btn span
  {
    cursor: pointer;
    color: #ADADAD;
    font-size: 0.22rem;
  }
  .sel-list-h .user-r h1{
    font-size: 0.3rem;
  }
  .sel-list-h .user-r p{
    margin-top: 0.3rem;
    color: #ADADAD;
    font-size: 0.23rem;
  }
  .sel-list-h .user-r button{
    position: absolute;
    bottom: 0;
    left: 1.5rem;
  }
  .user-register .register-title span{
    float: left;
  }
  .user-register span{
    font-size: 0.3rem;
  }
  .user-register input
  {
    border-bottom: 1px solid #737373;
    background: rgba(0, 0, 0, 0.75);
    margin-left: 0.2rem;
    width: 300px;
    color: #fff;
    font-size: 0.3rem;
  }
  .user-register .reg-email,.reg-password,.reg-password1{
    margin-top: 0.5rem;
  }
  .user-register .register-title .close-box{
    position: relative;
    float: right;
    color: #a5cd39;
    font-size: 0.3rem;
    padding-left: 0.5rem;
    cursor: pointer;
  }
  .user-register .register-title .close-box img{
    position: absolute;
    top: 5px;
    left: 0;
  }
  .user-register button{
    margin:0.5rem 0 0 0;
  }
  .wx-code img{
    margin: 0.4rem 0 0 1.6rem;
  }
  .sel-list-i{
    display: inline-flex;
  }
  .sel-list-i li:nth-child(2){
    display: flex;
    text-align: left;
    align-items: center;
  }
  .sel-list-i .sign-txt{
    padding-bottom: 10px;
    border-bottom: 1px solid #606363;
  }
  .sel-list-i .sign-txt p{
    font-size: 0.35rem;
  }
  .sel-list-j{
    display: inline-flex;
  }
  .sel-list-j li img{
    padding: 0 0.5rem;
  }
  .sel-list-j li:nth-child(3){
    display: flex;
    text-align: left;
    align-items: center;
  }
  .sel-list-j .sign-txt{
    padding-bottom: 10px;
    border-bottom: 1px solid #606363;
  }
  .sel-list-j .sign-txt p{
    font-size: 0.35rem;
    color:#fff;
  }
  .sel-list-k li{
    position: relative;
  }
  .sel-list-k li img{
    position: absolute;
    top: -10px;
    left: 10px;
  }
  .sel-list-k li span{
    font-size: 0.3rem;
    padding-left: 0.55rem;
    display: inherit;
    margin-top: -10px;
    color: #a5cd39;
  }
  .sel-list-k li:hover span{
    color: #fff;
  }
  .sel-list-k li:hover img{
    content: url("../assets/images/common/cn-h.png");
  }
  @media screen and (min-width:1510px){
    .nav-sel-content ul{
      text-align: left;
    }
    .nav-sel-content .sel-list-a li:first-child{
      margin-left: 7.3rem;
    }
    .nav-sel-content .sel-list-b li:first-child{
      margin-left: 9.2rem;
    }
    .nav-sel-content .sel-list-c li:first-child{
      margin-left: 12.8rem;
    }
    .nav-sel-content .sel-list-d li:first-child{
      margin-left: 15.3rem;
    }
    .nav-sel-content .sel-list-e li:first-child{
      margin-left: 17.3rem;
    }
    .nav-sel-content .sel-list-f li:first-child{
      margin-left: 20.3rem;
    }
  }
   @media screen and (max-width:1509px){
    .nav-sel-content ul{
      text-align: left;
    }
    .nav-sel-content .sel-list-a li:first-child{
      margin-left: 7.3rem;
    }
    .nav-sel-content .sel-list-b li:first-child{
      margin-left: 9.2rem;
    }
    .nav-sel-content .sel-list-c li:first-child{
      margin-left: 11.4rem;
    }
    .nav-sel-content .sel-list-d li:first-child{
      margin-left: 13.3rem;
    }
    .nav-sel-content .sel-list-e li:first-child{
      margin-left: 15.3rem;
    }
    .nav-sel-content .sel-list-f li:first-child{
      margin-left: 17.3rem;
    }
  }
  @media screen and (max-width:1450px){
    .vh-mian-nav .nav-r img{
      width: 80%;
    }
    .vh-mian-nav .nav-l .logo img{
      width: 80%;
    }
    .vh-mian-nav .nav-l-list{
      margin-left: 0.4rem;
    }
    .vh-mian-nav .nav-l-list ul li{
      margin: 0.1rem 0.2rem;
    }
    .vh-mian-nav .nav-r ul li{
      margin: 0.5rem 0.1rem 0.3rem 0.1rem;
    }
     .vh-mian-nav{
      padding: 0 0.3rem;
    }
    .nav-sel-content .sel-list-a li:first-child{
      margin-left: 5.7rem;
    }
    .nav-sel-content .sel-list-b li:first-child{
      margin-left: 7.4rem;
    }
    .nav-sel-content .sel-list-c li:first-child{
      margin-left: 10rem;
    }
    .nav-sel-content .sel-list-d li:first-child{
      margin-left: 11.8rem;
    }
    .nav-sel-content .sel-list-e li:first-child{
      margin-left: 13.6rem;
    }
    .nav-sel-content .sel-list-f li:first-child{
      margin-left: 16.2rem;
    }
  }
  @media screen and (max-width:1310px){
    .vh-mian-nav{
      padding: 0 0.3rem;
    }
    .nav-sel-content .sel-list-a li:first-child{
      margin-left: 5.7rem;
    }
    .nav-sel-content .sel-list-b li:first-child{
      margin-left: 7.2rem;
    }
    .nav-sel-content .sel-list-c li:first-child{
      margin-left: 9.7rem;
    }
    .nav-sel-content .sel-list-d li:first-child{
      margin-left: 11.8rem;
    }
    .nav-sel-content .sel-list-e li:first-child{
      margin-left: 13.8rem;
    }
    .nav-sel-content .sel-list-f li:first-child{
      margin-left: 16.3rem;
    }
  }
  @media screen and (max-width:1114px){
    .vh-mian-nav .nav-l-list {
      margin-left: 0;
    }
    .vh-mian-nav .nav-l-list ul li {
      margin-left: 0;
    }
    .vh-mian-nav{
      padding: 0 0.3rem;
    }
    .nav-sel-content .sel-list-a li:first-child{
      margin-left: 5rem;
    }
    .nav-sel-content .sel-list-b li:first-child{
      margin-left: 6.3rem;
    }
    .nav-sel-content .sel-list-c li:first-child{
      margin-left: 8.8rem;
    }
    .nav-sel-content .sel-list-d li:first-child{
      margin-left: 10.4rem;
    }
    .nav-sel-content .sel-list-e li:first-child{
      margin-left: 11.95rem;
    }
    .nav-sel-content .sel-list-f li:first-child{
      margin-left: 14.3rem;
    }
  }
</style>
